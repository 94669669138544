import service from '@/plugins/axios';

export default {
  /**
 * @param {String} url
 * @param {Object} params (optional)
 * @returns {Array} collection of items
 */
  getInitialEntities(url, params = null) {
    return service.get(`${url}/status`, { params });
  },

}
