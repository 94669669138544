import { mapGetters, mapMutations, mapActions } from 'vuex'
import EmptyBlockStub from "@/components/_Partials/EmptyBlockStub.vue";
import moment from 'moment';

export default {
  components: {
    EmptyBlockStub,
  },
  
  filters: {
    timeFormat: value => {
      if (!value) return '';
      return moment(value).format('YYYY Do MMMM, H:mm:ss')
    },
  },
  data() {
    return {
      store: "monitoring",
      focusedStream: null,
      isFetchingPreviousEvents: false,
      isResumingEvents: false,
      isResumingStreams: false,
      isLoadingStream: false,
      page: 1,
      entityPerPage: 10,
    }
  },
  mounted() {
    if (this.serviceType) {
      this.setServiceType(this.serviceType)
      this.getStreams();
    }
    if (this.$route.query.stream) {
      this.choseStream(this.$route.query.stream)
    }
  },

  computed: {
    ...mapGetters({
      streams: "monitoring/streams",
      events: "monitoring/events",
    }),

    isShowLogs() {
      return Boolean(this.focusedStream);
    },

    areStreamsShown() {
      return Array.isArray(this.streams) && !this.isShowLogs;
    },

    entityListPerPage() {
      return this.streams && 
        this.streams.slice((this.page - 1) * this.entityPerPage, this.page * this.entityPerPage)
    },

    isShowPagination() {
      return this.streams && (this.streams.length > this.entityPerPage);
    },

    paginationLength() {
      return Math.ceil(this.streams.length / this.entityPerPage);
    },
  },

  methods: {
    ...mapMutations('monitoring', {
      setServiceType: 'SET_STREAMS_TYPE',
      clearServiceStream: 'CLEAR_SERVICE_STREAMS',
    }),
    ...mapActions('monitoring', {
      getStreams: 'getStreams',
      getEventsByStream: 'getEventsByStream',
    }),

    refreshStreams() {
      if (this.isLoadingStream) return false;
      this.isLoadingStream = true;

      const resuming = () => {
        this.isResumingStreams = true;
        this.getStreams().then(() => {
          this.isResumingStreams = false;
          (this.isLoadingStream && this.$route.name === 'monitoring-service')  && setTimeout(() => resuming(), 3000);
        });
      };
      resuming();
    },

    backToStreams() {
      this.focusedStream = null;
      this.$router.push(this.$route.path);
    },

    choseStream(id) {
      this.isLoadingStream = false;
      this.focusedStream = id;
      this.getEventsByStream({ container_id: id })
    },

  }
}