<template>
  <v-dialog v-model="isOpen" persistent max-width="460px">
    <v-card>
      <v-tabs v-model="tabIndex" fixed-tabs>
        <v-tab class="primary--text">
          <v-icon>mdi-paperclip</v-icon>
        </v-tab>
        <v-tab class="primary--text">
          <v-icon>mdi-git</v-icon>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tabIndex">
        <v-tab-item>
          <v-card flat>
            <div>
              <v-card-text class="pb-0 px-4"
                >Deploy a file from the computer:</v-card-text
              >
            </div>
            <v-card-actions>
              <v-file-input
                v-model="currentTab.fields.file"
                placeholder="Upload your file"
                prepend-icon="mdi-paperclip"
                class="pt-0"
              >
                <template v-slot:selection="{ text }">
                  <v-chip small label color="primary">{{ text }}</v-chip>
                </template>
              </v-file-input>
            </v-card-actions>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <div class="d-flex justify-space-between align-center pt-2">
              <v-card-text class="px-2 py-3"
                >Deploy a file from the git repository:</v-card-text
              >
              <v-btn
                color="primary"
                class="mr-2 text-capitalize"
                @click="refreshGitRepositories()"
                :loading="refreshProcess"
                depressed
                outlined
                small
                >Refresh list
              </v-btn>
            </div>
            <v-card-actions>
              <v-select
                :items="repositoryesList"
                v-model="currentTab.fields.repository_name"
                @change="setGitAccount()"
                item-text="repository_name"
                label="Choose a repository"
                hide-details
                dense
                filled
              ></v-select>
            </v-card-actions>
            <v-card-actions>
              <v-select
                :items="branchesByChosenRepository"
                v-model="currentTab.fields.branch"
                label="Choose a branch"
                hide-details
                dense
                filled
              ></v-select>
            </v-card-actions>
            <v-card-actions>
              <v-text-field
                label="Relative path to the file"
                v-model="currentTab.fields.relative_path_to_file"
                hide-details
                filled
                dense
              ></v-text-field>
            </v-card-actions>
            <v-card-actions>
              <v-checkbox
                v-model="isLatesCommit"
                hide-details
                class="shrink mr-2 mt-0"
                label="Latest commit"
              ></v-checkbox>
            </v-card-actions>
            <v-card-actions>
              <v-text-field
                :disabled="isLatesCommit"
                label="Commit hash"
                v-model="currentTab.fields.commit"
              ></v-text-field>
            </v-card-actions>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          color="green darken-1"
          :loading="deployProcess"
          :disabled="isSubmitDisabled"
          @click="submit()"
          >Deploy</v-btn
        >
        <v-btn color="grey darken-4" text @click="close()">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  name: "DeploySkillDialog",

  props: ["store"],

  data() {
    return {
      isOpen: false,
      deployProcess: false,
      refreshProcess: false,
      tabIndex: 0,
      isLatesCommit: true,
      defaultBranch: 'master',
      tabs: [
        { id: "computer", fields: { file: null } },
        {
          id: "git",
          fields: {
            account_name: "",
            relative_path_to_file: "",
            repository_name: "",
            provider: "",
            project_name: "",
            branch: "",
            commit: "latest",
            ssh_link: "",
          },
        }
      ],
    };
  },

  mounted() {
    this.$root.$on("openUploadDialog", () => {
      this.isOpen = true;
      if (!this.gitRepositories) {
        this.getRepositories();
      }
    });
  },

  beforeDestroy() {
    this.$root.$off("openUploadDialog");
  },

  computed: {
    ...mapGetters({
      gitRepositories: "repositories/entityList",
    }),

    repositoryesList() {
      return this.gitRepositories ? this.gitRepositories : [];
    },

    currentTab() {
      return this.tabs[this.tabIndex];
    },

    currentTabId() {
      return this.currentTab.id;
    },

    isDeployingFromComputer() {
      return this.currentTabId === "computer";
    },

    isDeployingFromGit() {
      return this.currentTabId === "git";
    },

    isSubmitDisabled() {
      return (
        this.currentTab && !Object.values(this.currentTab.fields).every(Boolean)
      );
    },

    currentReposotory() {
      if (this.gitRepositories && this.isDeployingFromGit && this.currentTab.fields.repository_name) {
        return this.gitRepositories.find((repository) => repository.repository_name === this.currentTab.fields.repository_name)
      } 
      return null; 
    },

    branchesByChosenRepository() {
      return this.currentReposotory ? this.currentReposotory.branches : [];
    },

    accountByChosenRepository() {
      return this.currentReposotory ? this.currentReposotory.account_name : null;
    },
  },

  methods: {
    ...mapActions({
      uploadFile(dispatch, payload) {
        return dispatch(`${this.store}/uploadFile`, payload)
      },
      deploySkillFromGit(dispatch, payload) {
        return dispatch(`${this.store}/deploySkillFromGit`, payload)
      },
    }),

    ...mapActions("repositories", {
      getRepositories: "getAllEntities",
    }),

    ...mapMutations("repositories", {
      clearRepositories: "CLEAR_LIST",
    }),

    setGitAccount() {
      const { account_name, provider, project_name, ssh_link } = this.currentReposotory;
      this.tabs[this.tabIndex].fields.account_name = account_name;
      this.tabs[this.tabIndex].fields.provider = provider;
      this.tabs[this.tabIndex].fields.project_name = project_name ? project_name : 'null';
      this.tabs[this.tabIndex].fields.ssh_link = ssh_link;
      this.setDefaultBranch();
    },

    setDefaultBranch() {
      const { branchesByChosenRepository, defaultBranch } = this;
      if (branchesByChosenRepository.length === 1) {
        this.tabs[this.tabIndex].fields.branch = branchesByChosenRepository[0];
        return false;
      }

      if ((branchesByChosenRepository.length > 1) && branchesByChosenRepository.includes(defaultBranch)) {
        this.tabs[this.tabIndex].fields.branch = defaultBranch;
        return false;
      }
    },

    refreshGitRepositories() {
      this.resetGitParameters();
      this.refreshProcess = true;
      this.clearRepositories();
      this.getRepositories().then(() => {
        this.refreshProcess = false;
      });
    },

    resetGitParameters() {
      this.tabs[this.tabIndex].fields.account_name = '';
      this.tabs[this.tabIndex].fields.repository_name = '';
      this.tabs[this.tabIndex].fields.branch = '';
    },

    submit() {
      this.deployProcess = true;
      if (this.isDeployingFromComputer) {
        this.uploadFile(this.currentTab.fields.file).then((stream) => {
          this.deployProcess = false;
          this.$router.push({name: "monitoring-service", query: { service: 'deployment', stream }});
          this.close();
        });
        return false;
      }
      if (this.isDeployingFromGit) {
        this.deploySkillFromGit(this.currentTab.fields).then((stream) => {
          this.deployProcess = false;
          this.$router.push({name: "monitoring-service", query: { service: 'deployment', stream }});
          this.close();
        });
      }
    },

    close() {
      this.isOpen = false;
    },
  },

  watch: {
    isLatesCommit() {
      if (this.isLatesCommit) {
        this.tabs[this.tabIndex].fields.commit = "latest";
        return false;
      }

      this.tabs[this.tabIndex].fields.commit = "";
    },
  },
};
</script>