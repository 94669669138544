<template>
  <!-- Access Group block -->

    <div class="info-block-row">
      <div class="info-block-key d-flex align-center">Access Group</div>
      <div class="info-block-value">
        <div v-if="groupName">
          <v-progress-circular :width="2" size="25" color="primary" indeterminate v-if="isLoading"></v-progress-circular>
          <v-chip
            v-else
            close
            color="primary"
            @click:close="deattachGroup()"
          >
            <v-icon left>mdi-account-multiple</v-icon>
            {{ groupName }}
          </v-chip>
        </div>
        <div class="d-flex" v-else>
          <v-autocomplete
          v-model="candidateAccessGroup"
          :items="groups"
          :loading="isLoading"
          outlined
          hide-details
          dense
          label="Select group"
        ></v-autocomplete>
        <v-btn
          v-show="candidateAccessGroup"
          color="primary"
          class="text-capitalize ml-3"
          @click="setAccessGroup()"
          >Save
        </v-btn>
        <v-btn
          v-show="candidateAccessGroup"
          color="error"
          class="text-capitalize ml-3"
          @click="candidateAccessGroup = null"
          >Cancel
        </v-btn>
        </div>
      </div>
    </div>
</template>

<script>

import { mapGetters, mapActions } from "vuex";

export default {
  name: "SkillAccessGroup",

  props: ["id", "groupName", "base"],

  data() {
    return {
      candidateAccessGroup: null,
      isLoading: false,
      attachedGroup: null,
    };
  },

  mounted() {
    if (!this.groupList) {
      this.isLoading = true;
      this.getGroups().then(() => { this.isLoading = false });
    }
  },

  computed: {
    ...mapGetters({
      groupList: "groups/groupList",
    }),

    groups() {
      return this.groupList ? this.groupList : [];
    },
  },

  methods: {
    ...mapActions("groups", { 
        getGroups: "getAllEntities",
        attachToSkill: 'attachUserGroupToSkill',
        attachToBundle:  'attachUserGroupToBundle', 
        detachFromSkill: 'detachUserGroupFromSkill',
        detachFromBundle: 'detachhUserGroupFromBundle',
      }),
    ...mapActions("skillBundles", { getBundle: "getSkillById"}),

    setAccessGroup() {
      this.isLoading = true;
      this[`attachTo${this.base}`]({
        id: this.id,
        group_name: this.candidateAccessGroup,
      }).then(() => {
        this.candidateAccessGroup = null;
        this[`get${this.base}`](this.id).then(() => {this.isLoading = false});
      })
    },

    deattachGroup() {
      this.isLoading = true;
      this[`detachFrom${this.base}`]({
        id: this.id,
      }).then(() => {
        this[`get${this.base}`](this.id).then(() => {this.isLoading = false});
      })
    }
  },
}
</script>

