<template>
  <div>
    <div v-if="!isGrouped" class="fulfilment-block">
      Fulfillment id: <span>{{ row.fulfilment_id }}</span>
    </div>
    <div class="chain">
      <RequestRow 
        v-for="request in filteredFulfillmentChain" 
        in-groupe
        is-disciple
        :is-lead="false" 
        :is-grouped="isGrouped"
        :key="request.request_id" 
        :row="request" 
        :operators="operators"
      />
    </div>
    <RequestRow 
      in-groupe
      is-lead
      :is-disciple="false" 
      :row="row" 
      :operators="operators"
      :is-grouped="isGrouped"
      @toggleGroup="isGrouped = !isGrouped" 
    />
  </div>
</template>

<script>
import RequestRow from "@/components/ProductAnalytics/UsageLogs/RequestRow.vue";
export default {
  name: "GroupRequests",

  components: {
    RequestRow
  },

  props: {
    row: {
      type: Object,
      required: true
    },
    search: {
      type: String,
    },
    filter: {
      type: String,
    },
    operators: {
      default: null
    }
  },

  data() {
    return {
      isGrouped: true,
    };
  },

  computed: {
    filteredFulfillmentChain() {
      if (!this.search) return this.row.fulfillmentChain;
      return this.row.fulfillmentChain.filter(request => {
        if (!request[this.filter]) return false;
        return request[this.filter].includes(this.search);
      });
    }
  },

  watch: {
    search() {
      if (this.search) {
        this.isGrouped = false;
      }
    }
  }

};
</script>