<template>
  <div>
    <v-card class="mt-3">
      <h3 class="px-4 pt-3">New whitelist item</h3>
      <v-card-actions class="px-4">
        <v-select
          v-model="identityType"
          :items="identityTypes"
          label="Choose identity type"
          hide-details
          dense
          outlined
        ></v-select>
      </v-card-actions>

      <v-card-text class="pb-0">Fill the following fields</v-card-text>
      <v-card-actions class="px-4">
        <v-text-field
          ref="identity"
          :label="`${identityType} identity`"
          class="mr-1"
          v-model="identity"
          :rules="[rules.required, identityTypeRule]"
          @input="checkValidation()"
          dense
          outlined
        ></v-text-field>
        <v-text-field
          label="comment"
          class="ml-1"
          v-model="comment"
          dense
          outlined
        ></v-text-field>
      </v-card-actions>
      <v-card-actions class="px-4 pb-3">
        <v-btn
          color="green darken-1"
          class="text-capitalize white--text"
          :disabled="!isFromValid"
          :loading="savingProcess"
          @click="addWhiteListItem()"
          depressed
          >Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "CreationForm",

  props: {
    installedIntegrations: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      savingProcess: false,
      identity: "",
      comment: "",
      identityType: "whatsapp",
      isFromValid: false,
      rules: { 
        required: v => !!v || 'Identity is required',
        email: v => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(v) || 'Invalid email'
        },
        phone: v => {
          const pattern = /^[+]?[0-9]{3}?[.]?[0-9]{3}[.]?[0-9]{4,8}$/im;
          return pattern.test(v) || 'Invalid phone number'
        },
        domain: v => {
          const pattern = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/;
          return pattern.test(v) || 'Invalid domain'
        }
      }
    };
  },

  computed: {
    identityTypes() {
      const { installedIntegrations } = this;
      if (installedIntegrations.includes("email")) {
        installedIntegrations.push("domain");
      }
      return installedIntegrations;
    },

    identityTypeRule() {
      if (['whatsapp', 'telegram'].includes(this.identityType)) {
        return this.rules.phone;
      }
      if (this.identityType === 'email') {
        return this.rules.email;
      }
      if (this.identityType === 'domain') {
        return this.rules.domain;
      }
      return false;
    },

  },

  methods: {
    ...mapActions("whitelist", ["add"]),

    checkValidation() {
      const isValid = this.$refs.identity.validate();
      this.isFromValid = isValid;
    },

    addWhiteListItem() {
      if (!this.isFromValid) return false;
      this.savingProcess = true;
      this.add({
        identity: this.identity, 
        comment: this.comment,
        identity_type: this.identityType,
      }).then(()=> {
        this.savingProcess = false;
        this.clearForm();
      });
    },


    clearForm() {
      this.identity ="";
      this.comment = "";
      this.$refs.identity.reset();
    },
  },
};
</script>