<template>
  <div class="info-block-row" v-if="isPayloadError">
    <div class="info-block-key text-capitalize">{{ entityName }} error</div>
    <div class="info-block-value">
      <template>
        <span class="red--text" v-html="entity.error"></span>
      </template>
    </div>
  </div>

  <div class="info-block-row" v-else>
    <div class="info-block-key text-capitalize">{{ entityName }}</div>
    <div class="info-block-value">

      <div class="tags" v-if="tags">
        <span class="tags-element" v-for="(tag, index) in tags" :key="index">{{ tag }}</span>
      </div>
      <AnswerRouter :payload="entity" :type="entityName" />
    </div>
  </div>
</template>

<script>
import AnswerRouter from '@/components/Skills/Answers/Router.vue';

export default {
  name: "RequestEntity",

  components: {
    AnswerRouter,
  },

  provide: {
   is_materialized: false,
  },

  props: ["entity", "entityName"],

  computed: {
    isPayloadError() {
      return Boolean(this.entity.error);
    },

    tags() {
      const { tags } = this.entity;
      if (!tags || !tags.length) return null;
      return tags;
    },
  },
};
</script>