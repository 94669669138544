<template>
  <div class="page-container aiola-container" v-if="signedIn">
    <h2>Aiola</h2>
    <div v-if="environments">
      <v-card class="mt-3" v-for="item of environments" :key="item.title">
        <v-card-title class="text-capitalize">{{ item.title }}</v-card-title>
        <div>
          <div class="card-block pb-2">
            <div class="card-row" v-if="item.environment.status">
              <span class="row-title">Status:</span>
              <span class="row-value pl-3">{{ item.environment.status }}</span>
            </div>
            <div class="card-row" v-if="item.environment.create_time">
              <span class="row-title">Created:</span>
              <span class="row-value pl-3">{{
                item.environment.create_time | timeFormat
              }}</span>
            </div>
            <div class="card-row" v-if="item.environment.last_updated_time">
              <span class="row-title">Last update:</span>
              <span class="row-value pl-3">{{
                item.environment.last_updated_time | timeFormat
              }}</span>
            </div>
          </div>
          <v-expansion-panels v-if="item.environment.outputs">
            <v-expansion-panel>
              <v-expansion-panel-header>Environment Configurations</v-expansion-panel-header>
              <v-expansion-panel-content>
                <ConfigItem v-for="(value, title) in item.environment.outputs" 
                  :key="title" 
                  :config="{title, value}" 
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </v-card>
    </div>
    <div v-else class="mt-3 loader-block">
      <v-progress-circular
        :width="3"
        size="60"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import ConfigItem from "@/components/Configurations/ConfigItem.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import moment from "moment";

export default {
  name: "Environments",

  components: {
    ConfigItem,
  },

  filters: {
    timeFormat: (value) => {
      return moment(value).format("YYYY Do MMMM, H:mm:ss");
    },
  },

  mounted() {
    setTimeout(() => this.getEnviroments(), 0);
  },

  computed: {
    ...mapState({
      signedIn: (state) => state.auth.signedIn,
    }),
    ...mapGetters({
      environments: "environments/environments",
    }),
  },

  methods: {
    ...mapActions("environments", {
      getEnviroments: "getEnviroments",
    }),
  },
};
</script>