import Vue from 'vue';

import API from '@/services/api/base';
import baseStore from '../_base'

const state = {
  ...baseStore.state,
  url: 'intent',
  slots: [],
};

const getters = {
  ...baseStore.getters,
  slotsToEvaluate: state => state.slots,
};

const mutations = {
  ...baseStore.mutations,

  ADD_INTENT_INFO: (state, data) => {
    state.entity.map(intent => {
      if (intent.id === data.name) {
        Vue.set(intent, 'info', data)
      }
    })
  },

  PREPARE_SLOT_LIST: (state, slotsArr) => {
    state.slots = [];
    slotsArr.map(slot => {
      state.slots.push({ name: slot.name, title: slot.title, examples: null, value: null })
    })
  },

  SET_SLOT_EXAMPLE_AND_DIMENSION: (state, data) => {
    state.slots.map(slot => {
      if (slot.name === data.slot.name) {
        slot.examples = data.slot.examples
        slot.is_scalar = data.slot.is_scalar
      }
    })
  },
};

const actions = {
  ...baseStore.actions,

  /**
 * @param {Object} Vuex
 * @param {Number} id entityId;
 */
  getIntentById: ({ commit, state }, id) => {
    return new Promise((resolve) => {
      API
        .getById(state.url, id)
        .then(res => {
          if (res.data.error_message) {
            commit('notifications/SHOW_FAIL', res.data.error_message, { root: true })
            return false
          }
          commit('ADD_INTENT_INFO', res.data.data)
        })
        .catch(err => {
          console.log(err)
          commit('notifications/SHOW_FAIL', null, { root: true })
        })
        .finally(() => resolve());
    });
  },

/**
 * @param {Object} Vuex
 * @param {Array} slotsArr
 */
  getIntentSlotsByIds: ({ commit }, slotsArr) => {
    commit('PREPARE_SLOT_LIST', slotsArr);
    slotsArr.map(slot => {
      API
        .getById('slot', slot.name)
        .then(res => {
          if (res.data.error_message) {
            commit('notifications/SHOW_FAIL', res.data.error_message, { root: true })
            return false
          }
          const slotInfo = res.data.data;
          slotInfo.slot.is_scalar = slot.is_scalar;
          commit('SET_SLOT_EXAMPLE_AND_DIMENSION', slotInfo);
        })
        .catch(err => {
          console.log(err)
          commit('notifications/SHOW_FAIL', null, { root: true })
        })
    })
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}