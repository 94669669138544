<template>
  <div class="page-container groups-container">
    <v-row>
      <v-col cols="12" sm="5" class="d-flex align-center">
        <h2 class="hovered-el" @click="refreshList">
          Groups
          <v-icon color="primary" class="hidden-icon pb-1">mdi-reload</v-icon>
        </h2>
      </v-col>
    </v-row>
    <v-card class="mt-4">
      <div v-if="filteredList">
        <v-tabs vertical>
          <div class="ma-4">
            <v-btn 
              color="primary" 
              class="text-capitalize" 
              @click="newGroupModalIsOpen = true"
            >Add New Group</v-btn>
          </div>
          <v-tab v-for="group in filteredList" :key="group.name" class="justify-start">
            {{ group.name }}
          </v-tab>
          <v-tab-item v-for="item in filteredList" :key="item.name">
            <Group :group="item" />
          </v-tab-item>
        </v-tabs>
      </div>
      <div v-else class="mt-3 loader-block">
        <v-progress-circular :width="3" size="60" color="primary" indeterminate></v-progress-circular>
      </div>
    </v-card>

    <v-dialog v-model="newGroupModalIsOpen" persistent max-width="460px">
      <v-card>
        <v-card-title>
          <h4 class="font-weight-regular text-center">
            New Group
          </h4>
        </v-card-title>
        <v-card-actions>
          <v-text-field
            ref="groupName"
            label="Enter a new group name"
            v-model="newGroupName"
            :counter="20"
            :rules="groupNameRules"
            dense
            outlined
            required
          ></v-text-field>
        </v-card-actions>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn 
            color="primary" 
            :loading="savingProcess" 
            @click="addNewGroup()"
          >Save</v-btn>
          <v-btn color="grey darken-4" text @click="newGroupModalIsOpen = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import baseEntity from "@/mixins/_baseEntity";
import { mapActions } from "vuex";

import Group from "@/components/UserManagement/Groups/Group.vue";
export default {
  name: "Groups",

  components: {
    Group,
  },
  mixins: [baseEntity],
  data() {
    return {
      store: "groups",
      newGroupModalIsOpen: false,
      savingProcess: false,
      newGroupName: '',
    }
  },

  computed: {
    groupNameRules() {
      return [
         v => !!v || 'Group name is required',
         v => v.length <= 20 || 'Group name must be less than 20 characters',
         v => (this.entityList && !this.entityList.some(group => 
          group.name.toLowerCase() === v.toLowerCase().trim()
        )) || 'A group with this name already exists - please choose another.',
      ];
    }
  },

  methods: {
    ...mapActions("groups", ["createGroup"]),

    addNewGroup() {
      const isValid = this.$refs.groupName.validate();
      if (!isValid) return false;
      this.savingProcess = true;
      this.createGroup(this.newGroupName).then(() => {
        this.savingProcess = false;
        this.newGroupModalIsOpen = false;
        this.refreshList();
        this.newGroupName = '';
      });
    }
  }
};
</script>
