import API from '@/services/api/skills';
import baseStore from '@/store/modules/_base';
import Vue from 'vue';

const state = {
  ...baseStore.state,
  url: 'bundle',
  skillQuestions: null,
  skillParameters: null,
  params: null,
  evaluationResult: null,
  evaluationNotebook: null,
};

const getters = {
  ...baseStore.getters,
  skillQuestionList: state => state.skillQuestions,
  skillParameterList: state => state.skillParameters,
  evaluationParams: state => {
    const params = {};
    for (const intent in state.skillParameters) {
      const intentParameters = state.skillParameters[intent];
      for (const param in intentParameters) {
        if (intentParameters[param].active) {
          params[intentParameters[param].title] = state.params[intent][param];
        }
      }
    }
    return JSON.stringify({ 'params': params })
  },
  evaluationResult: state => state.evaluationResult,
  evaluationNotebook: state => state.evaluationNotebook,
};

const mutations = {
  ...baseStore.mutations,

  UPDATE_SKILL: (state, data) => {
    const index = state.entity.findIndex(skill => 
      skill.id === data.id
    );
    Vue.set(state.entity, index, data);
  },

  SET_BUNDLE_INTENTS: (state, intents) => {
    const skillQuestions = [];
    intents.map(intent => {
      skillQuestions.push({ title: intent.name, id: intent.name });
    });
    state.skillQuestions = skillQuestions;
  },

  SET_BUNDLE_PARAMS: (state, parameters) => {
    const skillParameters = {};
    for (const intent_name in parameters) {
      for (const parameter in parameters[intent_name]) {
        if (!(intent_name in skillParameters)) {
          skillParameters[intent_name] = {}
        }
        skillParameters[intent_name][parameter] = {
          title: parameter,
          slot_type: parameters[intent_name][parameter].slot_type,
          is_scalar: parameters[intent_name][parameter].is_scalar,
          active: false,
          examples: null,
          slots: null,
          exampleValue: '',
          slotValue: ''
        };
      }
    }
    state.skillParameters = skillParameters;

    state.params = parameters
  },

  SET_SLOT: (state, {intent, slot}) => {
    const intentParameters = state.skillParameters[intent] || [];
    for (const p in intentParameters) {

      if (intentParameters[p].slot_type === slot.name) {
        intentParameters[p].slots = slot.mapping;
        intentParameters[p].examples = slot.examples;
        return false;
      }
      if (intentParameters[p].slots && intentParameters[p].slots.length > 1 && intentParameters[p].slots.includes(slot.name)) {
        intentParameters[p].examples = slot.examples;
      }
    }
  },

  SET_PARAM_VALUE: (state, { value, parameter, intent }) => {
    const intentParameters = state.skillParameters[intent] || {};
    const slotValue = Object.values(intentParameters).find(param => param.title === parameter).slotValue;
    value = Array.isArray(value) ? value : [value]
    state.params[intent][parameter].value = slotValue ? {[slotValue]: value} : value;
  },

  SET_EVALUATION_RESULT: (state, result) => {
    const resultArr = [];
    for (const item in result) {
      if (Array.isArray(result[item]) && result[item].length) {
        resultArr.push({
          title: item,
          value: result[item],
        })
      }
    }
    state.evaluationResult = resultArr;
    state.evaluationNotebook = result['notebook'];
  },

  CLEAR_EVALUATION: (state) => {
    for (const parameter in state.params) {
      state.params[parameter].value = null;
    }
    state.skillQuestions = null;
    state.skillParameters = null;
    state.evaluationResult = null;
    state.evaluationNotebook = null;
  },

};

const actions = {
  ...baseStore.actions,

  /**
   * @param {Object} Vuex
   * @param {Number} id entityId;
   */
  getSkillById: ({ commit, state }, id) => {
    return new Promise((resolve) => {
      API
        .getById(state.url, id)
        .then(res => {
          if (res.data.error_message) {
            commit('notifications/SHOW_FAIL', res.data.error_message, { root: true })
            return false
          }
          commit('UPDATE_SKILL', res.data.data);
          commit('notifications/SHOW_SUCCESS', 'Skill bundle updated', { root: true })
        })
        .catch(err => {
          console.log(err)
          commit('notifications/SHOW_FAIL', null, { root: true })
        })
        .finally(() => resolve())
    });
  },

  getSkillDetails: ({ commit }, { bundleId, intents }) => {
    intents && commit('SET_BUNDLE_INTENTS', intents);
    return new Promise((resolve) => {
      API
      .getById(`${state.url}/parameters`, bundleId)
      .then(res => {
        if (res.data.error_message) {
          commit('notifications/SHOW_FAIL', res.data.error_message, { root: true })
          return false
        }
        commit('SET_BUNDLE_PARAMS', res.data.data)
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => resolve())
    });
  },

    /**
   * @param {Object} Vuex
   * @param {Number} id entityId;
   * @param {String} entry payload;
   */
  deploySkillFromGit: ({ commit }, entry) => {
    return new Promise((resolve) => {
      API
        .postWithEntry(`skill/git/deploy`, entry)
        .then(res => {
          if (res.data && res.data.data) {
            resolve(res.data.data)
            return false;
          }
          commit('notifications/SHOW_FAIL', 'Steam id not found! Deployment not started', { root: true })
        })
        .catch(err => {
          console.log(err)
          commit('notifications/SHOW_FAIL', null, { root: true })
        })
    });
  },

  /**
   * @param {Object} Vuex
   * @param {Object}
   */
  getSlotById: ({ commit }, {id, intent}) => {
    API
      .getById(`/slot`, id)
      .then(res => {
        if (res.data.error_message) {
          commit('notifications/SHOW_FAIL', res.data.error_message, { root: true })
          return false
        }
        commit('SET_SLOT', {intent, slot: res.data.data.slot})
      })
      .catch(err => {
        console.log(err)
        commit('notifications/SHOW_FAIL', null, { root: true })
      })
  },

  /**
   * @param {Object} Vuex
   * @param {Number} id entityId;
   * @param {String} entry payload;
   */
  evaluateSkillById: ({ commit, state, dispatch }, { id, entry }) => {
    return new Promise((resolve) => {
      API
        .evaluateById(state.url, id, entry)
        .then(res => {
          dispatch('getAsyncQueueStatus', res.data.data).then((result) => {
            if (result.error_message) {
              commit('notifications/SHOW_FAIL', result.error_message, { root: true })
              return false
            }
            commit('SET_EVALUATION_RESULT', result.data);
          })
            .catch(err => {
              console.log(err)
            })
            .finally(() => resolve())
        })
        .catch(err => {
          console.log(err)
          commit('notifications/SHOW_FAIL', null, { root: true })
        })
    })
  },

  deactivate: ({ commit, state, dispatch }, id) => {
    return new Promise((resolve) => {
      API
      .postWithEntry(`${state.url}/deactivate?id=${id}`)
        .then(res => {
          dispatch('getAsyncQueueStatus', res.data.data).then((result) => {
            if (result.error_message) {
              commit('notifications/SHOW_FAIL', result.error_message, { root: true })
              return false
            }
            commit('notifications/SHOW_SUCCESS', 'Completed!', { root: true })

            dispatch('getSkillById', id).then(() => {
              commit('notifications/SHOW_SUCCESS', "Skill updated!", { root: true })
            })
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => resolve())
        })
        .catch((err) => {
          console.log(err)
          commit('notifications/SHOW_FAIL', null, { root: true })
          resolve()
        })
    })
  },

  activate: ({ commit, state, dispatch }, id) => {
    return new Promise((resolve) => {
      API
        .postWithEntry(`${state.url}/activate?id=${id}`)
        .then(res => {
          dispatch('getAsyncQueueStatus', res.data.data).then((result) => {
            if (result.error_message) {
              commit('notifications/SHOW_FAIL', result.error_message, { root: true })
              return false
            }
            commit('notifications/SHOW_SUCCESS', 'Completed!', { root: true })

            dispatch('getSkillById', id).then(() => {
              commit('notifications/SHOW_SUCCESS', "Skill updated!", { root: true })
            })
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => resolve());
        })
        .catch((err) => {
          console.log(err)
          commit('notifications/SHOW_FAIL', null, { root: true })
        })
    })
  },

    /**
  * @param {Object} Vuex
  * @param {string} payload image path 
  */
 getImage: ({ state, commit }, payload) => {
  return new Promise((resolve) => {
    API
      .getImage(state.url, payload)
      .then(res => {
        if (res.data.error_message) {
          commit('notifications/SHOW_FAIL', res.data.error_message, { root: true })
          return false
        }
        resolve(res.data)
      })
      .catch(err => {
        console.log(err.message)
        commit('notifications/SHOW_FAIL', null, { root: true })
      })
  });
},

};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
