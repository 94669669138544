<template>
  <div class="expansion-panel">
    <div class="expansion-panel--header">
      <v-row>
        <v-col cols="12" sm="12" md="8">
          <div class="row-body">
            <span 
              v-show="!filteredType"
              :class="['panel-label', `identity-type--${ whitelistItem.identity_type }`]">
              {{ whitelistItem.identity_type }}
            </span>
            <div class="d-flex">
              <span class="row-body--name">Identity:</span> {{ whitelistItem.identity }}
            </div>
            <div class="d-flex align-center">
              <span class="row-body--name">Comment:</span> 
              <v-text-field
                v-if="editingMode"
                label="Comment"
                v-model="newComment"
                :value="whitelistItem.comment"
                hide-details
                outlined
                dense
              ></v-text-field>
              <template v-else>
                <span v-if="whitelistItem.comment">{{ whitelistItem.comment }}</span>
                <span v-else class="red--text">There isn't comment yet!</span>
              </template>
            </div>
          </div>
        </v-col>
        
        <v-col cols="12" sm="12" md="4" class="d-flex justify-end align-center">
          <div class="row-manage">
            <v-btn
              v-if="editingMode"
              color="primary"
              class="text-capitalize mr-3"
              @click="saveItem()"
              depressed
              small
            >Save
            </v-btn>
            <v-btn
              color="primary"
              class="text-capitalize mr-3"
              @click="toggleEditing()"
              depressed
              outlined
              small
            >{{ editingMode ? 'cancel' : 'edit' }}
            </v-btn>
            <v-btn
              color="error"
              class="theme--remove-btn"
              @click="openDeleteSkillDialog()"
              depressed
              small
            ><v-icon>mdi-delete</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "WhitelistItem",

  props: {
    whitelistItem: {
      type: Object,
      required: true
    },

    filteredType: {
      type: String,
      default: ''
    },
  },

  data() {
    return {
      editingMode: false,
      newComment: this.whitelistItem.comment,
      savingProcess: false,
    }
  },

  methods: {
    ...mapActions("whitelist", ['add']),

    saveItem() {
      this.savingProcess = true;
      this.add({
        identity: this.whitelistItem.identity,
        identity_type: this.whitelistItem.identity_type,
        comment: this.newComment
      }).then(()=> { 
        this.savingProcess = false; 
        this.toggleEditing();
      });
    },

    toggleEditing() {
      this.editingMode = !this.editingMode;
    },

    openDeleteSkillDialog() {
      const { identity, identity_type } = this.whitelistItem;
      this.$root.$emit("openDeleteDialog", { identity, identity_type });
    },

  },
};
</script>
