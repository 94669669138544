<template>
  <div class="expansion-panel" :class="{ open: isWrapped }" :id="intent.id">
    <div class="expansion-panel--header">
      <div class="name-block">{{ intent.name }}</div>
      <div class="manage-block">
        <v-btn 
          color="primary" 
          class="text-capitalize"
          :loading="fetchingProcess" 
          @click="showInfo()"
          outlined 
          small
        >{{ isWrapped ? 'close' : 'info' }}
        </v-btn>
        <v-btn
          color="error"
          class="theme--remove-btn ml-3"
          @click="deleteIntent()"
          depressed
          small
        ><v-icon>mdi-delete</v-icon>
        </v-btn>
      </div>
    </div>
    <transition name="slide">
      <div class="expansion-panel--content" v-show="isWrapped">
        <div class="mt-5 mb-0" v-show="intent.info">
          <div class="info-wrapped-panel" v-if="intent.info">
            <div class="info-block">
              <div class="info-block-row">
                <div class="info-block-key">Name</div>
                <div class="info-block-value">{{ intent.info.name }}</div>
              </div>
              <div class="info-block-row">
                <div class="info-block-key">Complex params</div>
                <div class="info-block-value">
                  {{ intent.info.complex_param }}
                </div>
              </div>
              <div class="info-block-row">
                <div class="info-block-key">Estimated time</div>
                <div class="info-block-value">
                  {{ intent.info.estimated_time }}
                </div>
              </div>
              <div class="info-block-row" v-show="intent.info.namespace">
                <div class="info-block-key">Namespace</div>
                <div class="info-block-value">
                  {{ intent.info.namespace }}
                </div>
              </div>
              <div class="info-block-row" v-show="intent.info.skill_name">
                <div class="info-block-key">Skill_name</div>
                <div class="info-block-value">
                  {{ intent.info.skill_name }}
                </div>
              </div>
              <div class="info-block-row" v-show="intent.info.intent_name">
                <div class="info-block-key">Intent_name</div>
                <div class="info-block-value">
                  {{ intent.info.intent_name }}
                </div>
              </div>
            </div>
            <div class="info-block">
              <h3 class="info-block-title">Skill</h3>
              <div class="info-block-row">
                <div class="info-block-key">Name</div>
                <div class="info-block-value" v-if="intent.info.skill.id">
                  <router-link 
                      :to="{
                        name: 'skills',
                        query: { id: intent.info.skill.id },
                      }"
                      >{{ intent.info.skill.name }}</router-link
                    >
                </div>
                <div class="info-block-value" v-else>{{ intent.info.skill.name }}</div>
              </div>
              <div class="info-block-row">
                <div class="info-block-key">Answer id</div>
                <div class="info-block-value">
                  {{ intent.info.skill.answer_id }}
                </div>
              </div>
            <v-btn
              color="primary"
              class="text-capitalize"
              @click="openEvaluateSkillDialog"
              depressed
              small
            >Evaluate</v-btn>
            </div>
            <div class="info-block">
              <h3 class="info-block-title">Phrases</h3>
              <div class="info-block-list phrases-block">
                <p
                  class="info-block-list-item"
                  v-for="(phrase, index) in intent.info.phrases"
                  @mouseover="evaluationPhrase = phrase"
                  @mouseleave="evaluationPhrase = ''"
                  @click="prepareToEvaluation"
                  :key="index"
                >
                  {{ phrase }}
                  <transition name="fade">
                    <span
                      class="primary--text"
                      v-show="phrase === evaluationPhrase"
                      >Evaluate
                    </span>
                  </transition>
                  <transition name="fade">
                    <span
                        class="primary--text"
                        v-show="phrase === evaluationPhrase"
                        @mouseover="evaluationMode = 'copy'"
                        @mouseleave="evaluationMode = 'evaluate'"
                    >Copy
                    </span>
                  </transition>
                </p>
              </div>
            </div>
            <div class="info-block" v-if="isSlotsShown">
              <h3 class="info-block-title">Slots</h3>
              <div v-for="(value, key) in intent.info.slots" :key="key" class="pb-2">
                <div class="pb-0"><b>{{ key }}</b></div>
                <div class="info-block-row pb-1">
                  <div class="info-block-key">Type</div>
                  <div class="info-block-value">
                    <router-link :to="{ name: 'chatbot-slots', query: { id: value.slot_type }}">
                      {{ value.slot_type }}
                    </router-link>
                  </div>
                </div>
                <div class="info-block-row">
                  <div class="info-block-key">Default value</div>
                  <div class="info-block-value">
                    {{ !value.default_value ? "null" : value.default_value[0] }}
                  </div>
                </div>
                <div class="info-block-row" v-if="value.system_type">
                  <div class="info-block-key">System type</div>
                  <div class="info-block-value">
                    {{ value.system_type }}
                  </div>
                </div>
                <div class="info-block-row" v-if="value.hasOwnProperty('is_scalar')">
                  <div class="info-block-key">Dimensionality</div>
                  <div class="info-block-value">
                    {{ value.is_scalar ? 'scalar' : 'multivalue' }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { has } from '@/utils'
export default {
  name: "Intent",

  props: ["intent"],

  data() {
    return {
      evaluationPhrase: "",
      evaluationMode: "evaluate",
      fetchingProcess: false,
      isWrapped: false,
    };
  },

  mounted() {
    this.$route.query.id === this.intentId && this.reveal()
  },

  computed: {
    intentId() {
      return this.intent.id;
    },

    isSlotsShown() {
      return this.intent.info && (Object.keys(this.intent.info.slots).length > 0);
    },
  },

  methods: {
    ...mapActions("intents", ['getIntentById', 'getIntentSlotsByIds']),

    reveal() {
      this.$vuetify.goTo(`#${this.intentId}`);
      this.showInfo();
    },

    toggle() {
      this.isWrapped = !this.isWrapped;
    },

    showInfo() {
      if (!has.call(this.intent, "info")) {
        this.fetchingProcess = true;
        this.getIntentById(this.intentId).then(() => {
          this.fetchingProcess = false;
        });
      }
      this.toggle();
    },

    deleteIntent() {
      this.$root.$emit("openDeleteDialog", this.intent.id);
    },

    openEvaluateSkillDialog() {
      this.intent.info.skill.question =  this.intent.name;
      this.$root.$emit("openSkillEvaluationDialog", this.intent.info.skill);
    },

    prepareToEvaluation() {
      let phrase = this.evaluationPhrase.replace(/\s*\[.*?\]\s*/g, " ");
      let slots = phrase.match(/\{(.*?)\}|\((.*?)\)/g); 
      if (!slots) {
        if (this.evaluationMode === "copy") {
          navigator.clipboard.writeText(phrase);
          return false;
        }
        this.$router.push({
          name: "chatbot-base",
          query: { utterance: phrase },
        });
        return false;
      }

      if (this.evaluationMode === "copy") {
        navigator.clipboard.writeText(phrase);
      } else {
        slots = slots.map((slot) => {
          const title = slot.replace(/[()]|[{}]/g, "");
          let slot_type = null;
          let is_scalar = true;
          if (title in this.intent.info.slots) {
            slot_type = this.intent.info.slots[title].slot_type;
            is_scalar = this.intent.info.slots[title].is_scalar
          } else {
            for (const s_name in this.intent.info.slots) {
              if (this.intent.info.slots[s_name].mapping.includes(title)) {
                slot_type = title;
                is_scalar = this.intent.info.slots[s_name].is_scalar;
                break;
              }
            }
          }
          return { title, name: slot_type, is_scalar };
        });
        phrase = phrase.replace(/[()]|[{}]/g, "").replace(/\s{2,}/g, " ");
        this.getIntentSlotsByIds(slots);
        this.$root.$emit("openEvaluationDialog", phrase);
      }
    },
  },
};
</script>