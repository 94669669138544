<template>
  <v-list-item>
    <v-list-item-content>
      <v-list-item-title class="text-capitalize">{{ config.title }}</v-list-item-title>
        <v-list-item-subtitle class="wrap-text" v-if="isPrimitiveValue">
          {{ config.value }}
        </v-list-item-subtitle>
        <v-list-item-subtitle class="wrap-text" v-else>
          <json-tree
            :json-data="handledItem" 
          ></json-tree>
        </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import JsonTree from "@/components/_Partials/JsonTree/JsonTree.vue";
export default {
  name: "ConfigItem",
  components: {
    JsonTree,
  },

  props: ["config"],

  computed: {
   isPrimitiveValue() {
     const { value } = this.config;
     if (
        typeof value === 'boolean' || 
        typeof value === 'number' ||
        value == null
      ) {
        return true;
     }
     if (typeof value === 'string') {
       try {
          const parsedStr = JSON.parse(value);
          return (typeof parsedStr === 'object' && parsedStr != null) ? false : true;
        }
        catch (err) {
          return err && true;
        }
     }
     return false;
   },
   
   handledItem() {
     const { value } = this.config;
     if (!this.isPrimitiveValue && typeof value === 'string') {
       return JSON.parse(value);
     }
     return value;
   }
  }, 
};
</script>