<template>
  <div>
    <div class="group-item-header d-flex justify-space-between mx-2">
      <div class="d-flex align-center group-search-block">
        <v-text-field
          hide-details
          v-model="searchParam"
          placeholder="Search"
          prepend-inner-icon="mdi-magnify"
          outlined
          dense
        ></v-text-field>
      </div>
      <div class="d-flex align-center">
        <v-btn 
          color="primary" 
          class="text-capitalize mr-3"
          @click="isOpenAddOwnerDialog = true"
          outlined
        >Add owner</v-btn>
        <v-btn 
          color="primary" 
          class="text-capitalize mr-3"
          @click="isOpenAddUserDialog = true"
          outlined
        >Add user</v-btn>
        <v-btn @click="isOpenDeleteGroupDialog = true" icon>
          <img src="@/assets/images/bin_icon.svg" alt="bin icon">
        </v-btn>
      </div>
    </div>

    <div class="group-item-table" v-if="group">
      <v-data-table
        :headers="headers"
        :items="filteredList"
        :items-per-page="10"
        class="user-group-table mt-2"
      >
        <template v-slot:item.channel="{ item }">
          <span class="text-capitalize">{{ item.channel }}</span>
        </template>
        <template v-slot:item.is_owner="{ item }">
          <v-simple-checkbox
            :value="item.is_owner"
            disabled
          ></v-simple-checkbox>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon v-if="item.is_owner"
            @click="openRemoveOwnerDialog(item)"
            color="red" 
            class="mr-1"
          >mdi-minus-box</v-icon>
          <v-btn icon @click="openRemoveUserDialog(item)" small>
            <img width="20" src="@/assets/images/bin_icon.svg" alt="bin icon">
          </v-btn>
        </template>
      </v-data-table>
    </div>

    <AddUserDialog 
      :name="group.name" 
      :is-open="isOpenAddUserDialog" 
      @close="() => { isOpenAddUserDialog = false }" 
    />

    <AddOwnerDialog 
      :name="group.name" 
      :is-open="isOpenAddOwnerDialog" 
      @close="() => { isOpenAddOwnerDialog = false }" 
    />

    <RemoveUserDialog 
      :user="focusedUser"
      :name="group.name" 
      :is-open="isOpenRemoveUserDialog" 
      @close="() => { isOpenRemoveUserDialog = false; focusedUser = null }" 
    />

    <RemoveOwnerDialog 
      :user="focusedUser"
      :name="group.name" 
      :is-open="isOpenRemoveOwnerDialog" 
      @close="() => { isOpenRemoveOwnerDialog = false; focusedUser = null }" 
    />
    <DeleteGroupDialog 
      :group="group" 
      :is-open="isOpenDeleteGroupDialog" 
      @close="isOpenDeleteGroupDialog = false" 
    />

  </div>
</template>

<script>
import { mapActions } from "vuex";
import AddUserDialog from "./Dialogs/AddUserDialog.vue";
import AddOwnerDialog from "./Dialogs/AddOwnerDialog.vue";
import RemoveUserDialog from "./Dialogs/RemoveUserDialog.vue";
import RemoveOwnerDialog from "./Dialogs/RemoveOwnerDialog.vue";
import DeleteGroupDialog from "./Dialogs/DeleteGroupDialog.vue";
export default {

  name: "Group",

  components: {
    AddUserDialog,
    AddOwnerDialog,
    RemoveUserDialog,
    RemoveOwnerDialog,
    DeleteGroupDialog,
  },

  props: ["group"],

  data() {
    return {
      searchParam: "",
      isOpenAddUserDialog: false,
      isOpenAddOwnerDialog: false,
      isOpenRemoveUserDialog: false,
      isOpenRemoveOwnerDialog: false,
      isOpenDeleteGroupDialog: false,
      focusedUser: null,
      headers: [
        { text: "User id", value: "user_id" },
        { text: "Identity", value: "identity" },
        { text: "Mesenger", value: "channel"},
        { text: "Is Owner", value: "is_owner" },
        { text: "Actions", value: "actions", sortable: false, align: "end" }
      ],
    };
  },

  computed: {
    filteredList() {
      const search = this.searchParam.toLowerCase().trim();
      if (!search) return this.group.allowed_users;
      return this.group.allowed_users.filter(
        entity =>
          (entity.user_id && entity.user_id.toLowerCase().includes(search)) ||
          (entity.channel && entity.channel.toLowerCase().includes(search)) ||
          (entity.identity && entity.identity.toLowerCase().includes(search)) 
      );
    }
  },

  methods: {
    ...mapActions("groups", ["getAllEntities"]),

    openRemoveUserDialog(item) {
      this.isOpenRemoveUserDialog = true;
      this.focusedUser = item;
    },
    openRemoveOwnerDialog(item) {
      this.isOpenRemoveOwnerDialog = true;
      this.focusedUser = item;
    },
  }
};
</script>