<template>
  <div class="d-flex align-top">
    <span 
      class="json-tree-key" 
      v-html="$options.filters.highlight(keyEl, searchQuery)"
    >{{ keyEl }}</span> :
    <template v-if="isEditMode">
      <div class="d-flex"> 
        <v-text-field
          hide-details
          v-model="newValue"
          class="json-editing-field ml-2"
          solo
        ></v-text-field>
        <v-btn
          color="primary"
          class="ml-2 text-capitalize"
          :disabled="isSaveBtnDisabled"
          @click="isOpenWarningModal = true"
          depressed
          small
          >Save
        </v-btn>
        <v-btn
          color="primary"
          class="ml-2 text-capitalize"
          @click="isEditMode = false"
          outlined
          depressed
          small
          >Cancel
        </v-btn>
      </div>
    </template>
    <template v-else>
      <span class="json-tree-value">{{ curentValue }}
      <span v-if="editableKeys && editableKeys.includes(keyEl)" @click="activateEditableMod()" class="link-style">edit</span>
    </span>
    </template>

    <div v-if="isEditMode">
      <v-dialog v-model="isOpenWarningModal" persistent max-width="360px">
        <v-card>
          <v-card-title>
            <h4 class="font-weight-regular">
              <v-icon color="amber" class="pr-1">mdi-alert</v-icon> Warning!
            </h4>
          </v-card-title>
          <v-card-text>After the current changes, the <b>{{keyEl}}</b> property will match the value <b>{{newValue}}!</b>
            <br><b>Would you like to continue?</b>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn 
              color="red" 
              text 
              :loading="savingProcess" 
              @click="savaNewValue()"
            >Yes</v-btn>
            <v-btn color="grey darken-4" text @click="closeWarningModal()">No</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "vJsonNone",

  filters: {
    highlight(word, query) {
      if (!query) {
        return word;
      }
      const check = new RegExp(query, "ig");
      return word.toString().replace(check, (matchedText) => 
        (`<span class="highlighted">${matchedText}</span>`)
      );
    }
  },

  props: {
    keyEl: {
      type: String,
      default: ""
    },
    value: {
      type: [String, Number, Boolean, Object],
      default: ""
    },
    searchQuery: {
      type: String,
      default: ""
    },
    editableKeys: {
      type: Array,
      default: null
    },
    fieldId: {
      type: String,
      default: ""
    },
  },
  data() {
    return {
      isEditMode: false,
      newValue: this.curentValue,
      isOpenWarningModal: false,
      savingProcess: false,
    };
  },
  computed: {
    curentValue() {
      if (this.value !== null) {
        return this.value;
      }
      return 'null';
    },
    isSaveBtnDisabled() {
      return this.newValue === '' || this.newValue === this.value;
    }
  },

  methods: {
    ...mapActions("profiles", ["setNewValue"]),
    activateEditableMod() {
      this.isEditMode = true;
    },
    closeWarningModal() {
      this.isOpenWarningModal = false;
    },
    savaNewValue() {
      const { newValue, keyEl } = this;
      if (!newValue) return false;
      this.savingProcess = true;
      this.setNewValue({entry: {'id': this.fieldId, 'fieldName': keyEl, 'fieldValue': newValue}, name: this.fieldId}).then(() => {
        this.savingProcess = false;
        this.closeWarningModal();
        this.isEditMode = false;
      })
    },
  }
};
</script>

