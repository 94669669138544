<template>
  <div>
    <template v-if="user">
      <div class="info-block-row" v-for="(value, key) in user" :key="key">
        <div class="info-block-key">User Name</div>
        <div class="info-block-value">{{ value }}</div>
      </div>
    </template>
    <v-progress-circular :width="2" size="16" color="primary" indeterminate v-else></v-progress-circular>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  name: "RequestUserInfo",

  props: {
    userId: {
      type: String,
      required: true
    },
    user: {
      required: true,
    },
    requestId: {
      type: String,
      required: true
    },
  },

  data() {
    return {
      gettingProcess: false,
    };
  },

  mounted() {
    if (!this.user) {
      this.getInfo();
    }
  },

  computed: {
    ...mapGetters("usageLogs", ["listOfCachedUsers"]),
  },

  methods: {
    ...mapActions("usageLogs", ["getUserInfo"]),
    ...mapMutations("usageLogs", {
      extendRequestRow: "EXTEND_REPORT_REQUEST",
    }),

    getInfo() {
      const { requestId, listOfCachedUsers, userId } = this;
      if (listOfCachedUsers.length && listOfCachedUsers.some(user => user.id === userId)) {
        const userName = listOfCachedUsers.find(user => user.id === userId).name;
        this.extendRequestRow({ requestId, data: { name: userName } })
        return false;
      }
      this.gettingProcess = true;
      this.getUserInfo(userId).then(({ data }) => {
        this.gettingProcess = false;
        this.extendRequestRow({ requestId, data })
      })
    }
  },
};
</script>