<template>
  <div class="info-block">
    <h3 class="info-block-title d-flex align-center">
      Examples ({{filteredList.length}})
      <v-text-field
        v-show="isShowVirtualScroll"
        class="ml-4 width-300"
        hide-details
        v-model="searchParam"
        placeholder="Search"
        prepend-inner-icon="mdi-magnify"
        outlined
        dense
      ></v-text-field>
    </h3>
    <v-virtual-scroll
      v-if="isShowVirtualScroll"
      :items="filteredList"
      :item-height="25"
      height="200"
    >
      <template v-slot="{ item }">
        <ExampleItem 
          :example="item" 
          :synonyms="(synonyms && synonyms[item]) ? synonyms[item] : null" 
          :translations="(translations && translations[item]) ? translations[item] : null" 
        />
      </template>
    </v-virtual-scroll>
    <div v-else class="d-inline-block">
      <div v-for="example in examples" :key="example">
        <ExampleItem 
          :example="example" 
          :synonyms="(synonyms && synonyms[example]) ? synonyms[example] : null" 
          :translations="(translations && translations[example]) ? translations[example] : null" 
        />
      </div>
    </div>
  </div>
</template>

<script>
import ExampleItem from "./ExampleItem.vue";

export default {
  name: "SlotExamples",
  components: {
    ExampleItem,
  },

  props: ["examples", "synonyms", "translations"],

  data() {
    return {
      searchParam: "",
    };
  },
  computed: {
    filteredList() {
      const search = this.searchParam.toLowerCase().trim();
      if (!search) return this.examples;
      return this.examples.filter((example) => example.toLowerCase().includes(search));
    },

    isShowVirtualScroll() {
      return this.examples.length > 10;
    },
  },
};
</script>