<template>
  <div class="py-4 px-6">
    
    <div v-if="isShowLogs">
      <h3 class="pb-4">
        <v-btn class="mr-2" fab small color="primary" @click="backToStreams()">
          <v-icon dark>mdi-chevron-left</v-icon>
        </v-btn>
        Stream {{ focusedStream }}
      </h3>
      <Logs :stream="focusedStream" />
    </div>

    <div v-show="!isShowLogs">
      <div v-if="areStreamsShown" class="stream-conteiner">
        <div class="stream-conteiner--list">
          <v-list-item v-for="stream in entityListPerPage" :key="stream.id">
            <v-list-item-content>
              <v-list-item-title>
                <span class="stream-name" @click="choseStream(stream.id)"
                  >{{ serviceType }}/{{ stream.id }}</span
                >
              </v-list-item-title>
              <v-list-item-subtitle v-if="stream.timestamp">
                {{ stream.timestamp | timeFormat }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-pagination
            v-show="isShowPagination"
            v-model="page"
            total-visible="7"
            :length="paginationLength"
          ></v-pagination>
          <EmptyBlockStub :list="streams" initial-empty-msg="There are no parsers yet!" />
        </div>
        <div class="stream-conteiner--manage">
          <v-btn color="primary" :loading="isResumingStreams" class="mr-3 text-capitalize" @click="refreshStreams()" depressed outlined small>Refresh</v-btn>
          <v-btn v-if="isLoadingStream" color="primary" class="text-capitalize" @click="isLoadingStream = false" text small>Stop</v-btn>
        </div>
      </div>
      <div v-else class="mt-3 loader-block">
        <v-progress-circular :width="3" size="60" color="primary" indeterminate></v-progress-circular>
      </div>
    </div>
  </div>
</template>

<script>
import Logs from "@/components/Monitoring/Partials/Logs.vue";
import monitoring from "@/mixins/_monitoring";

export default {
  name: "Parser",
  mixins: [monitoring],

  components: {
    Logs,
  },

  data() {
    return {
      serviceType: "parser",
    };
  },
};
</script>

