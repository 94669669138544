<template>
  <div class="auth-page">
    <h3 class="auth-page--header">Authenticator</h3>
    <div v-if="isAWS">
      <amplify-authenticator></amplify-authenticator>
    </div>
    <div v-else>
      <a
        @click="SignInPopup"
        target="_blank"
        rel="noopener noreferrer"
      >
        Login via Microsoft
      </a>
    </div>
  </div>
</template>

<script>
import { isAWS } from '@/api-configs';
import { mapState, mapMutations } from 'vuex'
export default {
  name: 'Auth',

  data() {
    return {
      isAWS: null,
    }
  },

  async created() {
    this.isAWS = await isAWS();
  },

  computed: {
    ...mapState({
      msalInstance: state => state.auth.msalInstance,
    }),

  },

  methods: {
    ...mapMutations('auth', {
      signIn: 'SIGN_IN',
    }),
    async SignInPopup() {
      await this.msalInstance
        .loginPopup({})
        .then(() => {
          const accounts = this.msalInstance.getAllAccounts();
          if (accounts.length == 0) {
            return false;
          }
          this.signIn(accounts[0]);
          this.$router.push({
            name: "environments",
          });
        })
        .catch(error => {
          console.error(`error during authentication: ${error}`);
        });
    },
  }
}
</script>