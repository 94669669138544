import { Doughnut } from 'vue-chartjs'

export default {
  extends: Doughnut,

  props: ['data'],
  
  data: () => ({
    chartdata: {
      labels: ['Intent detection', 'Skill execution', 'Skill starting', 'User input processing'],
      datasets: [{
        data: [],
        backgroundColor: ["#5e72e4", "#ffe60a", "#ff5252", "#43A047"],
      }],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false
    }
  }),

  mounted() {
    if (this.data.SkillFromCache) {
      this.chartdata.labels[1] = 'Skill execution from cache';
    }
    const skillExecutionTimestamp = this.data.SkillFromCache || this.data.SkillCompleted;

    this.chartdata.datasets[0].data.push(
      this.data.IntentDetected,
      skillExecutionTimestamp,
      this.data.SkillStarted,
      this.data.UserInputProcessed,
    )
    this.renderChart(this.chartdata, this.options)
  }
}
