<template>
  <div>
    <div class="mt-3">
      <v-btn
        color="green darken-1"
        class="white--text"
        @click="toggleCreationForm()"
        depressed
      >
        <span v-if="isCreationFromOpen">Close</span>
        <template v-else><v-icon left>mdi-plus</v-icon>Create new</template>
      </v-btn>
    </div>
    <v-card class="mt-3" v-show="isCreationFromOpen">
      <h3 class="px-4 pt-3">New Integration</h3>
      <v-card-text class="pb-0">Pick integration type</v-card-text>
      <v-card-actions class="flex-column align-start px-4">
        <v-radio-group v-model="integrationType" hide-details>
          <v-radio label="Teams" value="teams"></v-radio>
          <v-radio label="Whatsapp" value="whatsapp"></v-radio>
        </v-radio-group>
      </v-card-actions>
      <v-card-actions class="px-4">
        <template v-if="isTeamsIntegration">
          <v-text-field
            label="Microsoft App Id"
            class="mr-1"
            v-model="teamsId"
            hide-details
            dense
            outlined
          ></v-text-field>
          <v-text-field
            label="Microsoft App Password"
            class="ml-1"
            v-model="teamsPass"
            hide-details
            dense
            outlined
          ></v-text-field>
        </template>
        <template v-if="isWhatsappIntegration">
          <v-text-field
            label="Twilio Auth Token"
            class="mr-1"
            v-model="whatsappToken"
            hide-details
            dense
            outlined
          ></v-text-field>
          <v-text-field
            label="Twilio Sid"
            class="ml-1"
            v-model="whatsappSid"
            hide-details
            dense
            outlined
          ></v-text-field>
        </template>
      </v-card-actions>
      <v-card-actions class="px-4 pb-3">
        <v-btn
          color="green darken-1"
          class="text-capitalize white--text"
          :disabled="!isReadyToSend"
          :loading="savingProcess"
          @click="addIntegration()"
          depressed
          >Save Integration
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "CreationForm",

  data() {
    return {
      savingProcess: false,
      isCreationFromOpen: false,
      integrationType: "",
      teamsId: "",
      teamsPass: "",
      whatsappToken: "",
      whatsappSid: "",
    };
  },

  computed: {
    isTeamsIntegration() {
      return this.integrationType === "teams";
    },
    isWhatsappIntegration() {
      return this.integrationType === "whatsapp";
    },
    isReadyToSend() {
      return (
        (this.isTeamsIntegration && !!this.teamsId && !!this.teamsPass) ||
        (this.isWhatsappIntegration && !!this.whatsappToken && !!this.whatsappSid)
      );
    },
  },

  methods: {
    ...mapActions("integrations", {
      save: "save",
    }),

    toggleCreationForm() {
      this.isCreationFromOpen = !this.isCreationFromOpen
    },

    addIntegration() {
      this.savingProcess = true;
      const entry = {};
      entry.integration_type = this.integrationType;
      if (this.isTeamsIntegration) {
        entry.secret_keys = {
          MicrosoftAppId: this.teamsId,
          MicrosoftAppPassword: this.teamsPass,
        };
      }
      if (this.isWhatsappIntegration) {
        entry.secret_keys = {
          TwilioAuthToken: this.whatsappToken,
          TwilioSid: this.whatsappSid,
        };
      }
      this.save(JSON.stringify(entry));
    },


    clearForm() {
      this.teamsId ="";
      this.teamsPass = "";
      this.whatsappToken = "";
      this.whatsappSid = "";
    },


  },
};
</script>