import { Auth } from 'aws-amplify'
import { isAzure } from '@/api-configs';
import { AmplifyEventBus } from 'aws-amplify-vue'
import { mapState, mapMutations, mapActions } from 'vuex'

export default {

  async created() {
    if (await isAzure()) {
      await this.azureSignIn();
      return false;
    }

    this.findUserAWS();
    AmplifyEventBus.$on('authState', info => {
      if (info === 'signedIn') {
        this.findUserAWS()
      } else {
        this.signOut();
        this.$router.push("/");
      }
    })
  },
  

  computed: {
    ...mapState({
      user: state => state.auth.user,
      signedIn: state => state.auth.signedIn,
    }),
  },

  methods: {
    ...mapMutations('auth', {
      signIn: 'SIGN_IN',
      signOut: 'SIGN_OUT'
    }),
    ...mapActions('auth', ['azureSignIn']),

    async findUserAWS() {
      try {
        const user = await Auth.currentAuthenticatedUser();
        this.signIn(user);
      } catch(err) {
        this.signOut();
      }
    },
  },

  watch: {
    signedIn() {
      if (this.signedIn && this.$route.name === 'login') {
        this.$router.push({
          name: "environments",
        });
      }
    }
  }
}