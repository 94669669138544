<template>
  <div class="json-tree">
    <div v-if="isCopied" class="pb-2">
      <input v-if="isHiddenInputExist" ref="jsonData" type="hidden" :value="JSON.stringify(theData)">
      <span class="link-style" @click="copyText()">copy</span>
    </div>

    <div v-if="theData.constructor === Array">
      <span v-if="root"> [ </span>
      <div class="root_elem" v-for="(value, index) in theData" :key="index">
        <div v-if="typeof value === 'object' && value !== null">
          <span v-if="value.constructor === Array"> [ </span>
          <span v-else> { </span>

          <span
            v-if="showObj[index]"
            @click="toggle(index)"
            style="cursor:pointer"
          >
            <slot name="hide">-</slot>
          </span>
          <span v-else @click="toggle(index)" style="cursor:pointer">
            <slot name="expand">+</slot>
          </span>

          <v-json-tree
            v-if="showObj[index]"
            :editable-keys="editableKeys" 
            :jsonData="value"
            :root="false"
          >
            <template v-slot:expand>
              <slot name="expand" />
            </template>
            <template v-slot:hide>
              <slot name="hide" />
            </template>
            <template v-slot:more>
              <slot name="more" />
            </template>
          </v-json-tree>
          <slot name="more" v-else>...</slot>
        </div>
        <JsonNode v-else
          :field-id="fieldId" 
          :editable-keys="editableKeys" 
          :keyEl="key" 
          :value="value"
          :search-query="filterKey"
        />
      </div>
      ]
    </div>
    <div v-else>
      <span v-if="root"> { </span>
      <div class="root_elem" v-for="(value, key, index) in theData" :key="key">
        <div v-if="typeof value === 'object' && value !== null">
          <span class="json-tree-key" v-html="$options.filters.highlight(key, filterKey)" />
          :
          <span v-if="value.constructor === Array">Array [ </span>
          <span v-else>Object { </span>
          <template v-if="!isEmpty(value)">
            <span
              v-if="showObj[index]"
              @click="toggle(index)"
              style="cursor:pointer"
            >
              <slot name="hide">-</slot>
            </span>
            <span v-else @click="toggle(index)" style="cursor:pointer">
              <slot name="expand">+</slot>
            </span>
            <v-json-tree
              v-if="showObj[index]"
              :editable-keys="editableKeys"
              :filter-key="filterKey"
              :jsonData="value"
              :root="false"
            >
              <template v-slot:expand>
                <slot name="expand" />
              </template>
              <template v-slot:hide>
                <slot name="hide" />
              </template>
              <template v-slot:more>
                <slot name="more" />
              </template>
            </v-json-tree>
            <slot name="more" v-else>...</slot>
          </template>
          <template v-else>
            <span v-if="value.constructor === Array">]</span>
            <span v-if="value.constructor === Object">}</span>
          </template>
        </div>
        <JsonNode v-else
          :field-id="fieldId" 
          :editable-keys="editableKeys" 
          :keyEl="key" 
          :value="value"
          :search-query="filterKey"
        />
      </div>
      }
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import JsonNode from "./JsonNode.vue";

export default {
  name: "vJsonTree",
  components: {
    JsonNode,
  },

  filters: {
    highlight(word, query) {
      if (!query) {
        return word;
      }
      const check = new RegExp(query, "ig");
      return word.toString().replace(check, (matchedText) => 
        (`<span class="highlighted">${matchedText}</span>`)
      );
    }
  },

  props: {
    jsonData: [Object, Array],
    root: {
      type: Boolean,
      default: true
    },
    filterKey: {
      type: String,
      default: ""
    },
    fieldId: {
      type: String,
      default: ""
    },
    editableKeys: {
      type: Array,
      default: null
    }
  },
  computed: {
    theData() {
      const filterKey = this.filterKey && this.filterKey.toLowerCase();
      if (filterKey) {
        let filteredData = {};
        const keys = Object.keys(this.jsonData);
        keys.forEach(key => {
          if (
            key.toLowerCase().includes(filterKey) || (
              this.jsonData[key] && (this.jsonData[key].constructor === Object ||  this.jsonData[key].constructor === Array) &&
              JSON.stringify(this.jsonData[key]).includes(filterKey) 
            )
          ) {
            filteredData[key] = this.jsonData[key];
          }
        });
        return filteredData;
      } else {
        return this.jsonData;
      }
    },
    last() {
      return Object.keys(this.theData).length - 1;
    },
    isCopied() {
      return this.root && 
        !this.isEmpty(this.theData) && 
        !(this.theData.constructor === Array && !this.theData.length) &&
        (Object.keys(this.theData).length > 1)

    }
  },
  data() {
    const showObj = [];
    const len = Object.keys(this.jsonData).length;
    for (let i = 0, l = len; i < l; i++) {
      showObj.push(true);
    }
    return {
      showObj: showObj,
      isHiddenInputExist: false,
    };
  },

  methods: {
    ...mapMutations('notifications', {
      notify: 'SHOW_SUCCESS',
    }),
    toggle(index) {
      this.showObj[index] = !this.showObj[index];
      this.showObj.splice(index, 1, this.showObj[index]);
    },
    isEmpty(obj) {
      return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
    },
    copyText() {
      this.isHiddenInputExist = true;
      this.$nextTick(() => {
        const json = this.$refs.jsonData;
        json.type = 'text';
        json.select();
        document.execCommand("copy");
        json.type = 'hidden';
        this.notify('Copied');
        this.isHiddenInputExist = false;
      })
    },

  }
};
</script>