<template>
  <div class="service-block">
    <h2 class="mb-5">Service Monitoring</h2>
    <v-tabs fixed-tabs v-model="tabIndex">
        <v-tab v-for="item in tabs" :key="item.tabName">
          {{ item.tabName }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tabIndex">
        <v-tab-item v-for="item in tabs" :key="item.tabName">  
          <component v-bind:is="item.content"></component>
        </v-tab-item>
      </v-tabs-items>
  </div>
</template>

<script>

import Deployments from "@/components/Monitoring/Services/Deployments.vue";
import Dialog from "@/components/Monitoring/Services/Dialog.vue";
import Parser from "@/components/Monitoring/Services/Parser.vue";
import CfnComponent from "@/components/Monitoring/Services/Сfn.vue";

import { mapMutations } from 'vuex'

export default {
  name: "ServiceMonitoring",
  components: {
    Deployments,
    Dialog,
    Parser,
    CfnComponent,
  },
  data() {
    return {
      tabIndex: null,
      tabs: [
        { tabName: 'Deployments', content: 'Deployments', id: 'deployment'},
        { tabName: 'Dialog', content: 'Dialog', id: 'dialog'},
        { tabName: 'Parser', content: 'Parser', id: 'parser' },
        { tabName: 'Cfn', content: 'CfnComponent', id: 'cfn-service' },
      ]
    };
  },

  computed: {
    currentTabId() {
      return this.tabs[this.tabIndex].id;
    }
  },

  mounted() {
    if (this.$route.query.service) {
      this.tabIndex = this.tabs.findIndex(tab => tab.id === this.$route.query.service);
    }
  },

  methods: {
    ...mapMutations('monitoring', { setServiceType: 'SET_STREAMS_TYPE' }),
  },

  watch: {
    tabIndex() {
      this.setServiceType(this.currentTabId);
    }
  }
};
</script>