import API from '@/services/api/base';
import service from '@/plugins/axios';
import baseStore from '@/store/modules/_base';

const state = {
  ...baseStore.state,
  url: 'chatbot',
  isReloadingMetastore: false,
  chat: {
    messages: []
  }
};

const getters = {
  ...baseStore.getters,
  isReloadingMetastore: (state) => state.isReloadingMetastore,
  messages: (state) => state.chat.messages,
};

const mutations = {
  ...baseStore.mutations,

  START_RELOADING_METASTORE: (state) => {
    state.isReloadingMetastore = true;
  },

  FINISH_RELOADING_METASTORE: (state) => {
    state.isReloadingMetastore = false;
  },

  ADD_USER_MESSAGE: (state, msg) => {
    state.chat.messages.push({
      text: msg,
      from: 'user',
      timestamp: Date.now(),
    })
  },

  SET_EVALUATION_RESULT: (state, result) => {
    if (result.data && result.data.length) {
      const { payload: data, timestamp } = result.data[result.data.length - 1];
      if (data && (data[0].answer || data[0].local)) {
        data[0].answer && data[0].answer.map(payload => {
          state.chat.messages.push({ payload, from: 'bot', timestamp });
        });

        data[0].local && data[0].local.map(payload => {
          state.chat.messages.push({ payload, from: 'bot', timestamp });
        });
      }
    }
  },
};


const actions = {
  ...baseStore.actions,

  /**
  * @param {Object} Vuex
  * @param {Object} data utterance and session_id
  */
  evaluateChatbot: ({ commit, dispatch }, data) => {
    const { utterance } = data;
    commit('ADD_USER_MESSAGE', utterance);
    return new Promise((resolve) => {
      API
        .postWithEntry(`${state.url}/evaluate`, JSON.stringify(data))
        .then(res => {
          dispatch('getAsyncQueueStatus', res.data.data).then(result => {
            commit('SET_EVALUATION_RESULT', result);
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => resolve());
        })
        .catch(err => {
          console.log(err.message)
          commit('notifications/SHOW_FAIL', null, { root: true })
        })
    })
  },


  reloadMetastore: ({ commit, state }) => {
    commit('START_RELOADING_METASTORE')
    service.post(`${state.url}/reload`)
      .then((res) => {
        if (res.data.error_message) {
          commit('notifications/SHOW_FAIL', res.data.error_message, { root: true })
          return false
        }
        commit('notifications/SHOW_SUCCESS', 'Reloaded', { root: true })
      })
      .catch(err => {
        console.log(err.message)
        commit('notifications/SHOW_FAIL', null, { root: true })
      })
      .finally(() => commit('FINISH_RELOADING_METASTORE'))
  },

   /**
  * @param {Object} Vuex
  */
 retrainModel: ({ commit, dispatch }) => {
  return new Promise((resolve) => {
    API
      .postWithEntry(`${state.url}/retrain`)
      .then(res => {
        dispatch('getAsyncQueueStatus', res.data.data).then(() => {
          commit('notifications/SHOW_SUCCESS', 'Retrained', { root: true })
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => resolve());
      })
      .catch(err => {
        console.log(err.message)
        commit('notifications/SHOW_FAIL', null, { root: true })
      })
  })
},
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}