import { decorateLogMessage } from '@/utils'

const state = {
  maxLogsLength: 2000,
  token: null,
  asyncLogsData: {
    messages: [],
    status: '',
    process: ''
  },
  isOpen: false,
};

const getters = {
  asyncLogsData: state => state.asyncLogsData,
  isOpen: state => state.isOpen,
};

const mutations = {
  SET_LOGS_DATA: (state, data) => {
    if (!Array.isArray(data)) {
      return false;
    }

    if (state.asyncLogsData.messages.length > state.maxLogsLength) {
      state.asyncLogsData.messages.slice(
        Math.max(state.asyncLogsData.messages.length - state.maxLogsLength, 0)
      );
    }
    
    data.forEach(logItem => {
      if (Array.isArray(logItem.events)) {
        state.asyncLogsData.messages.push(...decorateLogMessage(logItem.events))
      } else {
        state.asyncLogsData.messages.push(decorateLogMessage(logItem.events))
      }
      state.asyncLogsData.status = logItem.status;
      if (logItem.message_id) {
        state.asyncLogsData.process = logItem.message_id;
      }
    });
  },

  SET_AZURE_ASYNC_TOKEN: (state, token) => {
    state.token = token;
  },

  SET_LOGS_DATA_FROM_LOCAL_STORAGE: (state) => {
    const consoleMessages = localStorage.getItem('consoleMessages');
    if (consoleMessages && consoleMessages.length) {
      state.asyncLogsData.messages = [...JSON.parse(consoleMessages)];
    }
  },

  CLEAR_CONSOLE: (state) => {
    state.asyncLogsData.messages = [];
  },

  RESET_LOGS_DATA: (state) => {
    state.asyncLogsData = null;
  },

  CLOSE: (state) => {
    state.isOpen = false;
  },
  
  OPEN: (state) => {
    state.isOpen = true;
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}