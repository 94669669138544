import API from '@/services/api/base';

const baseStore = {
  state: {
    entity: null,
  },

  getters: {
    entityList: state => state.entity,
  },

  mutations: {
    SET_ENTITY: (state, entity) => {
      state.entity = entity;
    },

    DELETE_ENTITY: (state, id) => {
      const index = state.entity.findIndex(skill => skill.id === id);
      state.entity.splice(index, 1);
    },

    CLEAR_LIST: (state) => {
      state.entity = null;
    }
  },

  actions: {
    /**
    * @param {Object} Vuex
    */
    getAllEntities: ({ commit, state }) => {
      return new Promise((resolve) => {
        API
          .getInitialEntities(state.url)
          .then(res => {
            resolve();
            if (res.data.error_message) {
              commit('notifications/SHOW_FAIL', res.data.error_message, { root: true })
              return false
            }
            commit('SET_ENTITY', res.data.data);
          })
          .catch(err => {
            console.log(err)
            commit('notifications/SHOW_FAIL', null, { root: true })
          });
      });
    },

    /**
    * @param {Object} Vuex
    * @param {Number} id entityId;
    */
    delete: ({ commit, state, dispatch }, id) => {

      /* auto open async console */
      commit('logs/OPEN', null, { root: true })

      return new Promise((resolve) => {
        API
          .deleteById(state.url, id)
          .then((res) => {
            dispatch('getAsyncQueueStatus', res.data.data).then((result) => {
              if (result.error_message) {
                commit('notifications/SHOW_FAIL', result.error_message, { root: true })
                return false
              }
              commit('DELETE_ENTITY', id);
              commit('notifications/SHOW_SUCCESS', 'Entity has been deleted', { root: true })
            })
              .catch(err => {
                console.log(err)
              })
              .finally(() => resolve())
          })
          .catch(err => {
            console.log(err)
            commit('notifications/SHOW_FAIL', 'Entity has not been deleted', { root: true })
          })
      });
    },

    /**
    * @param {Object} Vuex
    * @param {File} file file;
    */
    uploadFile: ({ commit }, file) => {
      return new Promise((resolve) => {
        API
          .uploadFile(file)
          .then((res) => {
            if (res.data && res.data.data) {
              resolve(res.data.data)
              return false
            }
            commit('notifications/SHOW_FAIL', 'Steam id not found! Deployment not started', { root: true })
          })
          .catch(() => {
            commit('notifications/SHOW_FAIL', 'The file has not uploaded', { root: true })
          })
          .finally(() => resolve())
      });
    },

    download: ({ commit }, data) => {
      return new Promise((resolve) => {
        API
          .getFile(data)
          .then(res => {
            if (res.data.error_message) {
              commit('notifications/SHOW_FAIL', res.data.error_message, { root: true })
              return false
            }
            resolve(res.data.data.url)
          })
          .catch(err => {
            console.log(err.message)
            commit('notifications/SHOW_FAIL', null, { root: true })
          })
      });
    },

    /**
    * @param {Object} Vuex
    * @param {String} requestId id of async query;
    */
    getAsyncQueueStatus: ({ commit, rootState }, requestId) => {
      commit('logs/SET_AZURE_ASYNC_TOKEN', null, { root: true })
      const statusResolver = (resolve, reject) => {
        API.getAsyncReaderStatus(requestId, rootState.logs.token)
          .then(res => {
            if (res.data.error_message) {
              commit('notifications/SHOW_FAIL', res.data.error_message, { root: true })
              commit('logs/SET_LOGS_DATA', { status: 'Error', message: res.data.error_message }, { root: true })
              reject(res.data.error_message);
              return false;
            }

            const result = res.data.data;
            const lastResult = result[result.length - 1];
            if (result && result.length && lastResult.tokens && lastResult.tokens.nextForwardToken) {
              const newToken = result[result.length - 1].tokens.nextForwardToken;
              commit('logs/SET_AZURE_ASYNC_TOKEN', newToken, { root: true });
            }
            const isFinished = (result && result.some(log => log.status === "FINISHED")) ? true : false;
            if (isFinished) {
              const responce = result.find(item => item.status === 'FINISHED').events;
              commit('logs/SET_LOGS_DATA', result, { root: true })
              resolve(JSON.parse(responce))
              return false;
            }
            commit('logs/SET_LOGS_DATA', result, { root: true })
            setTimeout(() => statusResolver(resolve, reject), 3000);
          })
          .catch(err => reject(err))
      }
      return new Promise(statusResolver);
    },

  }
}

export default baseStore;