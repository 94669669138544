<template>
  <div v-if="entry">
    <v-dialog v-model="isOpen" persistent max-width="360px">
      <v-card>
        <v-card-title>
          <h4 class="font-weight-regular">
            Do you want to delete this item?
          </h4>
        </v-card-title>
        <v-card-text>This action is irreversible!</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn 
            color="red" 
            text 
            :loading="deletingProcess" 
            @click="deleteEntity()"
          >Delete</v-btn>
          <v-btn color="grey darken-4" text @click="isOpen = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "DeleteDialog",

  props: ["store"],

  data() {
    return {
      deletingProcess: false,
      isOpen: false,
      entry: null,
    };
  },

  mounted() {
    this.$root.$on("openDeleteDialog", (entry) => {
      this.isOpen = true;
      this.entry = entry;
    });
  },

  beforeDestroy() {
    this.$root.$off("openDeleteDialog");
  },

  methods: {
    ...mapActions({
      delete(dispatch, payload) {
        return dispatch(`${this.store}/delete`, payload)
      },
    }),

    deleteEntity() {
      this.deletingProcess = true;
      this.delete(this.entry).then(() => {
        this.deletingProcess = false;
        this.isOpen = false;
      })
    },
  },
};
</script>