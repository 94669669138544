<template>
  <div>
    <div class="select-header">{{ selectorPayload.header }}</div>
    <div v-for="(item, index) in selectorItems" :key="index" class="select-item">
      {{ index + 1 }}. {{ item | label }}
    </div>
  </div>
</template>
<script>
export default {
  name: "AnswerSelector",

  props: ["entity"],

  filters: {
    label(value) {
      return typeof value == 'string' ? value : value.label;
    },
  },

  computed: {
    selectorPayload() {
      const { payload } = this.entity;
      return (typeof payload === 'string') ? JSON.parse(payload) : payload;
    },

    /* #todo temporary solution  */
    selectorItems() {
      return this.selectorPayload.items && this.selectorPayload.items.filter(item => !item.submenu);
    },
  }
}
</script>