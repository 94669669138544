import Vue from 'vue';

import API from '@/services/api/base';
import baseStore from '../_base'

const state = {
  ...baseStore.state,
  url: 'slot',
};

const getters = {
  ...baseStore.getters,
};

const mutations = {
  ...baseStore.mutations,

  SET_SLOT_INFO: (state, { slot, synonyms, translations }) => {
    state.entity.map(slotItem => {
      if (slotItem.id === slot.name) {
        Vue.set(slotItem, 'info', slot);
        Vue.set(slotItem, 'synonyms', synonyms);
        Vue.set(slotItem, 'translations', translations);
      }
    });
  },

};

const actions = {
  ...baseStore.actions,

    /**
   * @param {Object} Vuex
   * @param {Number} id entityId;
   */
  getSlotById: ({ commit, state }, id) => {
    return new Promise((resolve) => {
      API
        .getById(state.url, id)
        .then(res => {
          if (res.data.error_message) {
            commit('notifications/SHOW_FAIL', res.data.error_message, { root: true })
            return false
          }
          commit('SET_SLOT_INFO', res.data.data);

        })
        .catch(err => {
          console.log(err)
          commit('notifications/SHOW_FAIL', null, { root: true })
        })
        .finally(() => resolve());
    })
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}