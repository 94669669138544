<template>
  <div class="buld-info">
    <p v-if="buldVersion">B.V: {{ buldVersion }}</p>
    <p>P.R.D: {{ pageRenderDate }}</p>
    <p v-if="buldDate">B.D: {{ buldDate }}</p>
  </div>
</template>
<script>
  import moment from 'moment';
  export default {
    computed: {
      pageRenderDate() {
        return moment().format('MMM Do H:mm:ss');
      },

      buldDate() {
        return process.env.VUE_APP_BUILD_DATE && process.env.VUE_APP_BUILD_DATE;
      },

      buldVersion() {
        return process.env.VUE_APP_BUILD_VERSION && process.env.VUE_APP_BUILD_VERSION;
      }
    }, 
  }
</script>

