<template>
  <div v-if="ancillaryData" class="d-inline-block">
      <v-menu
        class="slot-example-popover"
        v-model="popover"
        :close-on-content-click="false"
        :nudge-width="200"
        :nudge-right="10"
        :nudge-top="10"
        :z-index="4"
        offset-x
      >
        <template v-slot:activator="{ on, attrs }">
          <div class="info-block-list-item primary--text" v-bind="attrs" v-on="on">{{ example }}</div>
        </template>
        <v-card>
          <v-card-title class="py-2">{{ example }}</v-card-title>
          <v-divider></v-divider>
          <template v-if="areSynonymsExist">
            <v-card-subtitle class="pt-2 pb-0 primary--text">Synonyms:</v-card-subtitle>
            <v-card-text>
              <span v-for="(synonym, index) in synonyms" :key="index">{{ synonymWithComma(index) }}</span>
            </v-card-text>
          </template>
          <template v-if="areTranslationsExist">
            <v-card-subtitle class="pt-2 pb-0 primary--text">Translations:</v-card-subtitle>
            <v-card-text>
              <div v-for="(value, key, index) in translations" :key="index">
                <span class="language black--text pr-2">{{ key }}:</span>
                <span class="translations">{{ value }}</span>
              </div>
            </v-card-text>
          </template>
        </v-card>
      </v-menu>
</div>
<div class="info-block-list-item" v-else>{{ example }}</div>
</template>

<script>
export default {
  name: "ExampleItem",

  props: {
    example: {
      type: String,
      required: true
    },

    translations: {
      default: null
    },

    synonyms: {
      default: null
    }
  },

  data() {
    return {
      popover: false,
    };
  },

  computed: {
    ancillaryData() {
      return !!this.synonyms || !!this.translations;
    },

    areSynonymsExist() {
      const { synonyms } = this;
      return !!synonyms && synonyms.length;
    },

    areTranslationsExist() {
      const { translations } = this;
      return translations && Object.keys(translations).length > 0 && translations.constructor === Object;
    },
  },

  methods: {
    togglePopover() {
      this.popover = !this.popover;
    },

    synonymWithComma(index) {
      const { synonyms } = this;
      return (index !== synonyms.length - 1) ? `${synonyms[index]}, ` : synonyms[index];
    }
  }
};
</script>