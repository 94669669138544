import Vue from 'vue';
import 'vuetify/dist/vuetify.css';
import Vuetify from 'vuetify'

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: "mdiSvg"
    },
    theme: {
        themes: {
          light: {
            primary: '#5E72e4', // #E53935
          },
        },
      },
});