<template>
  <v-img
    :src="imageSource"
    :lazy-src="imageSource"
    width="100%"
    height="300"
    class="block-image"
    contain
  >
    <template v-slot:placeholder>
      <v-row
        class="fill-height ma-0"
        align="center"
        justify="center"
      >
        <v-progress-circular
        indeterminate
        color="primary"
        ></v-progress-circular>
      </v-row>
    </template>
  </v-img>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "PayloadImage",

  props: {
    payload: {
      type: String,
      required: true
    },

    imgType: {
      type: String,
      default: 'png',
    },
  },

  data() {
    return {
      imageSource: "",
    };
  },

  mounted() {
    this.getImage(this.payload).then((url) => {
      this.imageSource = url;
    })
  },

  methods: {
    ...mapActions("usageLogs", ["getImage"]),
  },
};
</script>