<template>
  <div>
    <v-row>
      <v-col cols="12" sm="5" class="d-flex align-center">
        <h2>Skills Configuration</h2>
      </v-col>
      <v-col cols="12" sm="7">
        <v-text-field
          hide-details
          v-model="searchParam"
          placeholder="Search"
          prepend-inner-icon="mdi-magnify"
          solo
        ></v-text-field>
      </v-col>
    </v-row>
    
    <v-card class="mt-3 config-card-block">
      <div v-if="filteredList">
        <ConfigItem v-for="(value, title) in filteredList" 
          :key="title" 
          :config="{title, value}" 
        />
        <EmptyBlockStub :list="Object.keys(filteredList)" :search="searchParam" @reset="resetSearch()" />
      </div>
      <div v-else class="mt-3 loader-block">
        <v-progress-circular :width="3" size="60" color="primary" indeterminate></v-progress-circular>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EmptyBlockStub from "@/components/_Partials/EmptyBlockStub.vue";
import configurations from "@/mixins/_configurations";
import ConfigItem from "@/components/Configurations/ConfigItem.vue";

export default {
  name: "SkillsbotConfiguration",
  mixins: [configurations],

  components: {
    ConfigItem,
    EmptyBlockStub,
  },
  data() {
    return {
      configType: "skills",
    };
  },
  computed: {
    ...mapGetters({
      configurationList: "configurations/skillsConfigurations",
    }),
  },
};
</script>