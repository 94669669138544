<template>
  <div>
    <div v-if="entity.payload.response_class" class="pb-1">
      Responce class: {{entity.payload.response_class}}
    </div>
    <link-download 
      :isButton="true" 
      :label="entity.payload.label" 
      :path="entity.payload.path" 
      bg-color="primary"
    />
  </div>
</template>
<script>
import LinkDownload from '@/components/_Partials/linkDownload.vue'
export default {
  name: "AnswerHtml",

  props: ["entity"],

  components: {
    LinkDownload,
  },


}
</script>