
import Vue from 'vue';

import API from '@/services/api/configurations';
import baseStore from './_base'

const state = {
  ...baseStore.state,
  url: 'configuration',
  configurations: {
    base: null,
    chatbot: null,
    skills: null,
  },
  configurationType: 'base'
};

const getters = {
  ...baseStore.getters,
  baseConfigurations: store => {
    return store.configurations.base;
  },
  chatbotConfigurations: store => {
    return store.configurations.chatbot;
  },
  skillsConfigurations: store => {
    return store.configurations.skills;
  },
};

const mutations = {
  ...baseStore.mutations,
  SET_CONFIGURATION_TYPE: (state, type) => {
    if (state.configurationType !== type)
      state.configurationType = type;
  },

  SET_CONFIGURATION: (state, configurations) => {
    state.configurations[state.configurationType] = configurations
  },

  START_EDITIONG: (state, key) => {
    state.configurations[state.configurationType].map(config => {
      if (config.key === key) {
        Vue.set(config, 'editing', true)
        Vue.set(config, 'editedValue', config.value)
      }
    })
  },

  STOP_EDITIONG: (state, key) => {
    state.configurations[state.configurationType].map(config => {
      if (config.key === key) {
        Vue.delete(config, 'editing')
        Vue.delete(config, 'editedValue')
      }
    })
  },

  EDIT_CONFIG: (state, { key, value }) => {
    state.configurations[state.configurationType].map(config => {
      if (config.key === key && config.editing) {
        config.editedValue = value;
      }
    })
  },

  CLEAR_LIST: (state) => {
    state.configurations[state.configurationType] = null;
  }
};

const actions = {
  ...baseStore.actions,
  getConfigurations: ({ commit, state }) => {
    API
      .getInitialEntities(`${state.url}/${state.configurationType}`)
      .then(res => {
        if (res.data.error_message) {
          commit('notifications/SHOW_FAIL', res.data.error_message, { root: true })
          return false
        }
        commit('SET_CONFIGURATION', res.data.data)
      })
      .catch(err => {
        console.log(err)
        commit('notifications/SHOW_FAIL', null, { root: true })
      })
  },

  save: ({ commit, state, dispatch }, entry) => {
    return new Promise((resolve) => {
      API
        .updateConfiguration(`${state.url}/${state.configurationType}`, entry)
        .then((res) => {
          dispatch('getAsyncQueueStatus', res.data.data).then(() => {
            commit('notifications/SHOW_SUCCESS', 'Saved', { root: true })
            commit('CLEAR_LIST');
            dispatch('getConfigurations');
          })
            .catch(err => {
              console.log(err)
              commit('notifications/SHOW_FAIL', null, { root: true })
            })
            .finally(() => resolve());
        })
        .catch(err => {
          console.log(err)
        })
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}