<template>
  <v-dialog v-model="isOpen" persistent max-width="550px">
    <v-card>
      <v-card-title>
        <h4 class="font-weight-regular">Cloning a new repository</h4>
      </v-card-title>
      <v-text-field
        prepend-icon="mdi-git"
        v-model="repositoryLink"
        :rules="[rules.required, rules.repositoryLinkMatch]"
        label="Git repository ssh URL"
        class="px-6"
      ></v-text-field>
      <v-checkbox
        class="px-6"
        v-model="isPublicKeyAdded"
        label="I confirm I added the public key to the repository settings"
      />
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="green darken-1"
          class="text-capitalize"
          :loading="cloningProcess"
          :disabled="isSubmitDisabled"
          @click="cloneRepository()"
          text
          >Clone repository
        </v-btn>
        <v-btn
          color="grey darken-4"
          class="text-capitalize"
          @click="close"
          text
          >Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { validateRegex } from "@/utils";

export default {
  name: "AddRepositoryDialog",

  data() {
    return {
      isOpen: false,
      cloningProcess: false,
      isPublicKeyAdded: false,
      repositoryLink: "",
      rules: {
        required: value => !!value || 'Git repository ssh URL must be provided.',
        repositoryLinkMatch: value => this.validateLink(value) || 'Please make sure that repository URL is SSH Git URL',
      },
      allowedPatterns: [
        {type: "github", title: "GitHub", pattern: /^git@github.com:([\w.-]+)\/([\w.-]+).git$/},
        {type: "aws", title: "AWS CodeCommit" ,pattern: /^ssh:\/\/git-codecommit.([\w.-]+).amazonaws.com\/v1\/repos\/([\w.-]+)$/},
        {type: "azure", title: "Azure DevOps", pattern: /^git@ssh.dev.azure.com:v3\/([\w.-]+)\/([\w.-]+)\/([\w.-]+)$/},
      ],
      isSnackbarShown: false,
      snackbarText: null,
    };
  },

  mounted() {
    this.$root.$on("AddRepositoryDialog", () => {
      this.isOpen = true;
    });
  },

  beforeDestroy() {
    this.$root.$off("AddRepositoryDialog");
  },

  computed: {
    ...mapGetters({
      publicKey: "repositories/publicKey",
    }),

    isSubmitDisabled() {
      return !this.validateLink(this.repositoryLink) || !this.isPublicKeyAdded;
    },
  },

  methods: {
    ...mapActions("repositories", ["clone"]),

    cloneRepository() {
      this.cloningProcess = true;
      this.$root.$emit("openAsyncLogsConsole");
      this.clone(this.repositoryLink).then(() => {
        this.cloningProcess = false;
        this.close();
      });
    },

    validateLink(repoLink = null) {
      if (!repoLink) return false;
      if (repoLink.startsWith('http')) {
        return false;
      }
      const matchPatterns = this.allowedPatterns.some(({ pattern }) => validateRegex(pattern, repoLink));
      if (matchPatterns) {
        return true;
      }

      return repoLink.startsWith('git') || repoLink.startsWith('ssh')
    },


    close() {
      this.isOpen = false;
    },
  },
};
</script>