<template>
  <div class="page-container reports-container">
    <v-row>
      <v-col cols="12" sm="5" class="d-flex align-center">
        <h2 class="hovered-el" @click="refreshList">
          Usage Logs
          <v-icon color="primary" class="hidden-icon pb-1">mdi-reload</v-icon>
        </h2>
      </v-col>
    </v-row>
    <div class="reports-block mt-3">
      <v-row>
        <v-col cols="12" sm="12" md="3">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="dates"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                class="datepicker-value"
                label="From/To dates"
                prepend-icon="mdi-calendar"
                :append-icon="dateRangeText && 'mdi-close-circle'"
                readonly
                hide-details
                @click:append="clearDatePicker()"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dates"
              no-title
              range
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
              <v-btn text color="primary" @click="$refs.menu.save(dates)">OK</v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="12" md="3">
          <v-select
            v-model="filterFiled"
            :items="reportFilters"
            item-text="title"
            item-value="id"
            label="Choose a field"
            hide-details
            dense
            solo
          ></v-select>
        </v-col>
        <v-col cols="12" sm="12" md="3">
          <v-select
            v-if="isStatusFiltering"
            v-model="searchString"
            :items="statusList"
            item-text="title"
            item-value="id"
            label="Choose a status"
            hide-details
            dense
            solo
          ></v-select>
          <v-text-field
            v-else
            hide-details
            v-model="searchString"
            placeholder="Search"
            prepend-inner-icon="mdi-magnify"
            @keydown="handleInput"
            dense
            solo
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="3">
          <v-btn
            color="primary"
            class="text-capitalize mr-2"
            @click="find()"
            :disabled="!searchString || !filterFiled"
            depressed
          >
            Find
          </v-btn>
          <v-btn
            color="primary"
            class="text-capitalize px-2 mr-2"
            @click="reset()"
            depressed
            outlined
          >
            Reset
          </v-btn>
          <v-btn
            color="primary"
            class="text-capitalize px-2"
            @click="toggleExtendedMenu()"
            text
          >
            {{ isShownExtendedMenu ? 'Close' : 'More' }}
          </v-btn>
        </v-col>
      </v-row>
      <div class="extended-menu mt-4" v-show="isShownExtendedMenu">
        <v-btn
          color="primary"
          class="text-capitalize mr-2"
          :loading="generatingProcess"
          @click="generateReports()"
          depressed
        >
          <v-icon left>mdi-creation</v-icon>
          Generate latest report
        </v-btn>
      </div>  
      <EmptyBlockStub :list="filteredRequests" :search="search" @reset="reset()" 
        initial-empty-msg="Reports are being loaded!"
        search-empty-msg="Searching for requests by the specified parameters!"
      />
      <div class="request-list mt-5">
        <div v-for="request in filteredRequests" :key="request.request_id">
          <GroupRequests
            :row="request"
            :search="search"
            :filter="filterFiled"
            :operators="operators"
            v-if="request.fulfillmentChain"
          />
          <RequestRow :row="request" :operators="operators" v-else />
        </div>
        <template v-if="isManualLoading">
          <div class="manual-loading-block">
            <div class="pb-2">Already loaded {{ amountReportRequests }} requests.</div>
             <v-btn
                color="primary"
                class="text-capitalize"
                @click="getNewRequests()"
                depressed
                outlined
              >
                Load New
              </v-btn>
          </div>
        </template>
        <template v-else>
          <infinite-loading 
            v-if="!isStoppedLoading" 
            @infinite="infiniteHandler" 
            ref="infiniteLoading">
          </infinite-loading>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapMutations, mapGetters, mapActions } from "vuex";
import { isObjEmpty } from "@/utils";
import InfiniteLoading from 'vue-infinite-loading';
import GroupRequests from "@/components/ProductAnalytics/UsageLogs/GroupRequests.vue";
import RequestRow from "@/components/ProductAnalytics/UsageLogs/RequestRow.vue";
import EmptyBlockStub from "@/components/_Partials/EmptyBlockStub.vue";

export default {
  name: "UsagLogs",
  components: {
    GroupRequests,
    RequestRow,
    EmptyBlockStub,
    InfiniteLoading,
  },

  data() {
    return {
      store: "usageLogs",
      reportFilters: [
        { title: 'Session id', id: 'session_id' },
        { title: 'Utterance', id: 'utterance' },
        { title: 'Intent', id: 'intent_name' },
        { title: 'Request id', id: 'request_id' },
        { title: 'User id', id: 'user_id' },
        { title: 'Status', id: 'status' },
      ],
      statusList: [
        { title: 'Success', id: 'SUCCESS' },
        { title: 'Failure', id: 'FAILURE' },
        { title: 'Not Detected', id: 'NOT_DETECTED' },
        { title: 'System Error', id: 'SYSTEM_ERROR' },
        { title: 'Unauthorized', id: 'UNAUTHORIZED' },
        { title: 'Elicitation', id: 'ELICITATION' },
        { title: 'Elicitation Failed', id: 'ELICITATION_FAILED' },
      ],
      menu: false,
      filterFiled: null,
      searchString: "",
      search: "",
      gettingProcess: false,
      generatingProcess: false,
      dates: [],
      isShownExtendedMenu: false,
      operators: null,
      manualLoading: true,
      tempAmountRequests: 0,
    };
  },

  created() {
    const { query } = this.$route;
    this.updateOperators();
    if (!isObjEmpty(query)) {
      query.period && this.preFilterDates(query.period);
      if (query.userId) {
        this.filterFiled = 'user_id';
        this.searchString = query.userId;
        this.search = this.searchString;
      }
      this.$router.push(this.$route.path);
    }
  },

  computed: {
    ...mapGetters("usageLogs", ["reportRequests", "latestGetedReportDate"]),

    dateRange() {
      const { dates } = this;
      if (dates.length > 1) {
        return moment(dates[0]).isAfter(dates[1])
          ? dates.slice().reverse()
          : dates;
      }
      return dates;
    },

    dateRangeText() {
      return this.dateRange.join(" ~ ");
    },

    amountReportRequests() {
      return this.reportRequests && this.reportRequests.length;
    },

    isManualLoading() {
      return (this.amountReportRequests > 200 && this.manualLoading)
    },

    isStatusFiltering() {
      return this.filterFiled === 'status';
    },

    filteredRequests() {
      let { reportRequests, search, filterFiled, dateRange } = this;
      
      if (!reportRequests) return [];

      if (dateRange.length) {
        reportRequests = reportRequests.filter((request) => {
          return (dateRange.length > 1) ? moment(request.request_timestamp).isBetween(dateRange[0], moment(dateRange[1]).add(1, 'day')) : 
            moment(request.request_timestamp).format("YYYY-MM-DD") === dateRange[0];
        })
      }

      if (search) {
        reportRequests = reportRequests.filter((request) => {
          if (!request[filterFiled]) return false;
          console.log(request[filterFiled], search);
          return request[filterFiled].includes(search) || (
            request.fulfillmentChain && request.fulfillmentChain.some(childrenReq => 
              childrenReq[filterFiled] && childrenReq[filterFiled].includes(search)
            )
          );
        });
      }

      return reportRequests;
    },

    isStoppedLoading() {
      let isOutOfRange = false
      if (this.dateRange.length) {
        const fromFilteredDate = this.dateRange[0];
        isOutOfRange = moment(this.latestGetedReportDate).isBefore(fromFilteredDate)
      }
      return isOutOfRange;
    }
  },

  methods: {
    ...mapActions("usageLogs", ["get", "generate"]),
    ...mapActions("groups", ["getGroupByName"]),
    ...mapMutations("usageLogs", {
      clearList: "CLEAR_REQUESTS_LIST",
    }),

    ...mapMutations("notifications", {
      notifySuccess: "SHOW_SUCCESS",
    }),

    clearDatePicker() {
      this.dates = [];
    },

    find() {
      this.search = this.searchString.trim();
      this.notifySuccess(`Found ${this.filteredRequests.length} request chains!`);
    },

    reset() {
      this.search = "";
      this.searchString = "";
      this.dates = [];
    },

    infiniteHandler($state) {
      this.get(this.latestGetedReportDate).then(() => {
        $state.loaded();
        if (this.tempAmountRequests !== this.amountReportRequests) {
          this.manualLoading = true;
        }
      });
    },

    handleInput(keyboardEvent) {
      if (keyboardEvent.code === 'Enter') this.find();
    },

    preFilterDates(period) {
      const periodLength = (period === 'month') ? 30 : 7; 
      const startDate = moment().subtract(1, 'day').format("YYYY-MM-DD");
      const endDate = moment(startDate).subtract(periodLength, 'day').format("YYYY-MM-DD");
      this.dates = [startDate, endDate];
    },
    
    generateReports() {
      this.generatingProcess = true;
      this.$root.$emit("openAsyncLogsConsole");
      this.generate(moment().format("YYYY-MM-DD")).then(() => {
        this.generatingProcess = false;
        this.refreshList();
      });
    },

    toggleExtendedMenu() {
      this.isShownExtendedMenu = (this.isShownExtendedMenu) ? false : true;
    },

    refreshList() {
      this.reset();
      this.clearList();
      this.$nextTick(() => {
        this.$refs.infiniteLoading.attemptLoad();
      });
      this.updateOperators();
    },

    updateOperators() {
      this.getGroupByName('Operators').then(data => {
        if (data) {
          this.operators = data.allowed_users;
        }
      })
    },

    getNewRequests() {
      this.tempAmountRequests = this.amountReportRequests;
      this.manualLoading = false;
    },

  },
};
</script>
