<template>
  <div class="evaluation-chat-block mt-4">
    <div class="evaluation-chat-header mb-4">
      <h3>
        Chatbot live evaluation
        <v-progress-circular
          v-if="evaluationChatbotProcess"
          :width="2"
          size="25"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </h3>
    </div>
    <v-card class="mx-auto" height="450" >
      <div class="chat-content" ref="chat">
        <div
          class="message-block"
          :class="[`message-block--${msg.from}`]"
          v-for="(msg, index) in messages"
          :key="index"
        >
          <div class="message-row">
            <div class="message-title">
              <div class="message-info">
                <span class="message-author pr-2">{{ msg.from }}</span>
                <span class="message-time">{{ msg.timestamp | timeFormat }}</span>
              </div>
              <div class="message-content" v-if="msg.from == 'user'">{{ msg.text }}</div>
              <div class="message-content" v-else>
                <AnswerRouter :payload="msg.payload" type="answer" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <v-textarea
        filled
        class="chat-input"
        label="Your utterance"
        auto-grow
        rows="2"
        @keydown="handleInput"
        v-model="utterance"
        :disabled="evaluationChatbotProcess"
      ></v-textarea>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from "vuex";
import AnswerRouter from '@/components/Skills/Answers/Router.vue';

export default {
  name: "Chat",

  provide: {
   is_materialized: true,
  },

  components: {
    AnswerRouter,
  },

  filters: {
    timeFormat: value => {
      if (!value) return '';
      return moment(value).format('H:mm')
    },
  },

  data() {
    return {
      utterance: "",
      evaluationChatbotProcess: false,
    };
  },

  mounted() {
    this.$route.query.utterance && this.setUtteranceFromRoutQuery();
  },

  computed: {
    ...mapGetters({
      messages: "chatbot/messages",
    }),
  },

  methods: {
    ...mapActions("chatbot", ["evaluateChatbot"]),

    clearInput() {
      setTimeout(() => (this.utterance = ""), 0);
    },

    sendUtterance(e) {
      const { utterance } = this;
      if (!utterance) {
        e.preventDefault()
        return false;
      }

      this.evaluationChatbotProcess = true;
      this.evaluateChatbot({ utterance, formatted: false }).then(() => { this.evaluationChatbotProcess = false });
      this.clearInput();
    },

    setPreviousMessage() {
      if (!this.messages.length)
        return false;
      const messageList = [...this.messages];
      const lastUserMessage = messageList.reverse().find(msg => msg.from === 'user');
      this.utterance = lastUserMessage.text;
    },

    setUtteranceFromRoutQuery() {
      this.utterance = this.$route.query.utterance;
      this.sendUtterance();
    },

    handleInput(keyboardEvent) {
      switch(keyboardEvent.code) {
        case 'Enter': this.sendUtterance(keyboardEvent);
          break;
        case 'ArrowUp': this.setPreviousMessage();
          break;
      }
    },
  },

  watch: {
    messages() {
      this.$nextTick(() => {
        const container = this.$refs.chat;
        container.scrollTop = container.scrollHeight;
      })
    },
  }
};
</script>
