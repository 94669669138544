<template>
  <div class="page-container whitelist-container">
    <v-row>
      <v-col cols="12" sm="5" class="d-flex align-center">
        <h2 class="hovered-el" @click="refreshList">
          Whitelists
          <v-icon color="primary" class="hidden-icon pb-1">mdi-reload</v-icon>
        </h2>
      </v-col>
      <v-col cols="12" sm="7">
        <v-text-field
          hide-details
          v-model="searchParam"
          placeholder="Search"
          prepend-inner-icon="mdi-magnify"
          solo
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="12" md="7">
        <v-btn
          color="green darken-1"
          class="white--text"
          @click="toggleCreationForm()"
          :disabled="isAddNewDisabled"
          depressed
        >
          <span v-if="isCreationFromOpen">Close</span>
          <template v-else><v-icon left>mdi-plus</v-icon>Add new</template>
        </v-btn>
      </v-col>
      <v-col cols="12" sm="12" md="5">
        <v-select
          v-model="identityType"
          :items="whiteListFilters"
          item-text="title"
          item-value="id"
          label="Filter by identity type"
          @change="setInitialPage()"
          hide-details
          dense
          solo
        ></v-select>
      </v-col>
    </v-row>

    <CreationForm :installed-integrations="installedIntegrations" v-if="isCreationFromOpen" />

    <div class="expansion-panels-group mt-3" v-if="filteredListPerPage">
      <WhitelistItem v-for="whitelistItem in filteredListPerPage" 
        :key="`${whitelistItem.identity_type}_${whitelistItem.identity}`" 
        :whitelist-item="whitelistItem"
        :filtered-type="identityType"
      />
      <v-pagination
        v-show="isShowPagination"
        v-model="page"
        total-visible="7"
        :length="paginationLength"
      ></v-pagination>
      <EmptyBlockStub :list="filteredList" :search="searchParam" @reset="resetSearch()" />
    </div>
    <div v-else class="mt-3 loader-block">
      <v-progress-circular :width="3" size="60" color="primary" indeterminate></v-progress-circular>
    </div>
    <DeleteDialog :store="store" />
  </div>
</template>

<script>
import baseEntity from "@/mixins/_baseEntity";
import EmptyBlockStub from "@/components/_Partials/EmptyBlockStub.vue";
import CreationForm from "@/components/Chatbot/Whitelist/CreationForm.vue";
import WhitelistItem from "@/components/Chatbot/Whitelist/WhitelistItem.vue";
import DeleteDialog from "@/components/Dialogs/DeleteDialog.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Whitelist",
  components: {
    EmptyBlockStub,
    WhitelistItem,
    CreationForm,
    DeleteDialog,
  },
  mixins: [baseEntity],

  data() {
    return {
      store: "whitelist",
      whiteListFilters: [
        { title: 'All types', id: '' },
        { title: 'Whatsapp', id: 'whatsapp' },
        { title: 'Telegram', id: 'telegram' },
        { title: 'Email', id: 'email' },
        { title: 'Domain', id: 'domain' },
      ],
      identityType: '',
      isCreationFromOpen: false,
    };
  },
  mounted() {
    this.initEntity();
    this.getAllIntegrations();
  },

  computed: {
    ...mapGetters({
      integrations: "integrations/entityList",
    }),
    filteredList() {
      let { entityList, identityType } = this;
      const search = this.searchParam.toLowerCase().trim();
      if (!entityList) return null;
      if (identityType) {
        entityList = entityList.filter((entity) => entity.identity_type.includes(identityType))
      }
      if (search) {
        entityList = entityList.filter((entity) => entity.identity.toLowerCase().includes(search))
      }
      return entityList;
    },
    installedIntegrations() {
      const { integrations } = this;
      const installedIntegrations = [];
      // except teams
      integrations && integrations.map(item => 
        (item.integration_type !== 'teams') && installedIntegrations.push(item.integration_type)
      );
      return installedIntegrations;
    },
    isAddNewDisabled() {
      const { installedIntegrations } = this;
      return !installedIntegrations.length;
    },
  },

  methods: {
    ...mapActions({
      getAllIntegrations: "integrations/getAllEntities",
    }),
    toggleCreationForm() {
      this.isCreationFromOpen = !this.isCreationFromOpen
    },
    refreshList() {
      this.clearList();
      this.getAllEntities();
      this.getAllIntegrations();
    },
  }
};
</script>