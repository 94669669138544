<template>
  <v-dialog v-model="isOpen" persistent max-width="500px">
    <v-card>
      <v-card-title>
        <h4>Replace slot types with examples</h4>
      </v-card-title>
      <div v-if="slotsToEvaluate.length" class="px-6">
        <p class="sub-title" v-html="$options.filters.distinguishSlots(readyToEvaluationPhrase, slotsToEvaluate)"></p>
        <div
          v-for="slot in slotsToEvaluate"
          :key="slot.title"
          class="d-flex align-center"
        >
          <span class="sub-title pb-5 pr-3">{{ slot.title }}</span>
          <v-select
            v-if="slot.examples && slot.examples.length >= 1"
            :items="slot.examples"
            v-model="slot.value"
            label="Choose an example"
            :multiple="!slot.is_scalar"
            dense
            filled
          ></v-select>
          <v-progress-circular
            :width="3"
            size="25"
            color="primary"
            class="mb-5"
            indeterminate
            v-else
          ></v-progress-circular>
        </div>
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="green darken-1"
          :disabled="!isRedyToEvaluation"
          @click="evaluate"
           text
          >Evaluate</v-btn
        >
        <v-btn color="grey darken-4" text @click="isOpen = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "EvaluationChatbotDialog",
  filters: {
    distinguishSlots: (string, slots) => {
      let newString = string;
      slots.forEach(slot => {
        newString = newString.replace(slot.title, `<b>${slot.title}</b>`)
      });
      return newString;
    }
  },
  data() {
    return {
      isOpen: false,
      evaluationPhrase: "",
    };
  },

  mounted() {
    this.$root.$on("openEvaluationDialog", (data) => {
      this.isOpen = true;
      this.evaluationPhrase = data;
    });
  },

  computed: {
    ...mapGetters({
      slotsToEvaluate: "intents/slotsToEvaluate",
    }),

    isRedyToEvaluation() {
      return this.slotsToEvaluate.every((slot) => slot.value);
    },

    readyToEvaluationPhrase() {
      let phrase = this.evaluationPhrase;
      this.slotsToEvaluate.length &&
        this.slotsToEvaluate.map((slot) => {
          if (slot.value) {
            const value = Array.isArray(slot.value) ? slot.value.join(', ') : slot.value;
            phrase = phrase.replace(slot.title, value);
          }
        });
      return phrase;
    },
  },

  methods: {
    evaluate() {
      if (this.readyToEvaluationPhrase)
        this.$router.push({
          name: "chatbot-base",
          query: { utterance: this.readyToEvaluationPhrase },
        });
    },
  },
};
</script>