import service from '@/plugins/axios';

export default {
  /**
 * @param {String} url
 * @param {Object} params (optional)
 * @returns {Array} collection of items
 */
  getStreams(url, params = null) {
    return service.get(`${url}/streams`, { params });
  },

  /**
 * @param {String} url
 * @param {Object} params (optional)
 * @returns {Array} collection of items
 */
  getEvents(url, params = null) {
    return service.get(`${url}/events`, { params });
  },

}
