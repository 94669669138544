import Vue from 'vue';

import API from '@/services/api/base';
import baseStore from '@/store/modules/_base';

const state = {
  ...baseStore.state,
  url: 'repository',
  publicKey: null,
};

const getters = {
  ...baseStore.getters,
  publicKey: state => state.publicKey,
};

const mutations = {
  ...baseStore.mutations,

  UPDATE_REPOSITORY: (state, newRepository) => {
    const index = state.entity.findIndex(repository => 
      repository.repository_name === newRepository.repository_name
    );
    if (index === -1) {
      state.entity.push(newRepository)
      return false;
    }
    Vue.set(state.entity, index, newRepository);
  },

  SET_PUBLIC_KEY: (state, publicKey) => {
    state.publicKey = publicKey
  },

  DELETE_REPOSITORY: (state, repositoryName) => {
    const index = state.entity.findIndex(repository => 
      repository.repository_name === repositoryName
    );
    state.entity.splice(index, 1);
  },


};

const actions = {
  ...baseStore.actions,

  /**
  * @param {Object} Vuex
  */
  getAllEntities: ({ commit, state }) => {
    return new Promise((resolve) => {
      API
        .getInitialEntities(state.url)
        .then(res => {
          if (res.data.error_message) {
            commit('notifications/SHOW_FAIL', res.data.error_message, { root: true })
            return false
          }
          commit('SET_ENTITY', res.data.data.repositories);
          commit('SET_PUBLIC_KEY', res.data.data.public_key);
          resolve();
        })
        .catch(err => {
          console.log(err.message)
          commit('notifications/SHOW_FAIL', null, { root: true })
        })
    });
  },
  
  /**
  * @param {Object} Vuex
  * @param {String} 
  */
  update: ({ commit, state, dispatch }, { repository, account, provider }) => {
    return new Promise((resolve) => {
      API
        .postWithEntry(`${state.url}/update`, { repository, account, provider })
        .then(res => {
          dispatch('getAsyncQueueStatus', res.data.data).then((result) => {
            if (result.error_message) {
              commit('notifications/SHOW_FAIL', result.error_message, { root: true })
              return false
            }
            if (result.data.status === 'SUCCESS') {
              commit('UPDATE_REPOSITORY', result.data.repository);
              commit('notifications/SHOW_SUCCESS', 'The repository has been updated', { root: true })
            } else commit('notifications/SHOW_FAIL', "The repository hasn't been updated", { root: true })
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => resolve())
        })
        .catch(err => {
          console.log(err.message)
          commit('notifications/SHOW_FAIL', null, { root: true })
        })
    });
  },

    /**
  * @param {Object} Vuex
  * @param {String} 
  */
 delete: ({ commit, state, dispatch }, { repository, provider }) => {
  return new Promise((resolve) => {
    API
      .delete(`${state.url}?provider=${provider}&repository_name=${repository}`)
      .then(res => {
        dispatch('getAsyncQueueStatus', res.data.data).then((result) => {
          if (result.error_message) {
            commit('notifications/SHOW_FAIL', result.error_message, { root: true })
            return false
          }
          commit('DELETE_REPOSITORY', repository);
          commit('notifications/SHOW_SUCCESS', `The repository ${repository} has been deleted`, { root: true })
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => resolve())
      })
      .catch(err => {
        console.log(err.message)
        commit('notifications/SHOW_FAIL', null, { root: true })
      })
  });
},

  /**
  * @param {Object} Vuex
  * @param {String} 
  */
  clone: ({ commit, state, dispatch }, path) => {
    return new Promise((resolve) => {
      API
        .postWithEntry(`${state.url}/clone`, { "path": path })
        .then(res => {
          dispatch('getAsyncQueueStatus', res.data.data).then((result) => {
            if (result.error_message) {
              commit('notifications/SHOW_FAIL', result.error_message, { root: true })
              return false
            }
            if (result.data.status === 'SUCCESS') {
              commit('UPDATE_REPOSITORY', result.data.repository);
              commit('notifications/SHOW_SUCCESS', 'The repository has been cloned', { root: true })
            } else commit('notifications/SHOW_FAIL', "The repository hasn't been cloned", { root: true })
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => resolve())
        })
        .catch(err => {
          console.log(err.message)
          commit('notifications/SHOW_FAIL', null, { root: true })
        })
    })
  },



};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}