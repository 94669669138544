<template>
  <div class="page-container profiles-container">
    <v-row>
      <v-col cols="12" sm="5" class="d-flex align-center">
        <h2 class="hovered-el" @click="refreshList">
          Profile list
          <v-icon color="primary" class="hidden-icon pb-1">mdi-reload</v-icon>
        </h2>
      </v-col>
      <v-col cols="12" sm="7">
        <v-text-field
          hide-details
          v-model="searchParam"
          placeholder="Search"
          prepend-inner-icon="mdi-magnify"
          solo
        ></v-text-field>
      </v-col>
    </v-row>

    <div class="expansion-panels-group mt-5" v-if="filteredListPerPage">
      <UserProfile
        v-for="userProfile in filteredListPerPage"
        :key="userProfile.id"
        :user="userProfile"
      />

      <v-pagination
        v-show="isShowPagination"
        v-model="page"
        total-visible="7"
        :length="paginationLength"
      ></v-pagination>

      <EmptyBlockStub :list="filteredList" :search="searchParam" @reset="resetSearch()" />
    </div>
    <div v-else class="mt-3 loader-block">
      <v-progress-circular :width="3" size="60" color="primary" indeterminate></v-progress-circular>
    </div>
  </div>
</template>

<script>
import UserProfile from "@/components/UserManagement/Profiles/UserProfile.vue";
import baseEntity from "@/mixins/_baseEntity";
import EmptyBlockStub from "@/components/_Partials/EmptyBlockStub.vue";

export default {
  name: "Profiles",

  components: {
    UserProfile,
    EmptyBlockStub,
  },
  mixins: [baseEntity],
  data() {
    return {
      store: "profiles",
    }
  },
  computed: {
    filteredList() {
      const search = this.searchParam.toLowerCase().trim();
      if (!search) return this.entityList;
      if (!this.entityList) return null;
      return this.entityList.filter((entity) => {
        if (this.page > 1) this.setInitialPage();
        if (entity.contact_info && entity.contact_info.name) {
          return entity.contact_info.name.toLowerCase().includes(search);
        }
        if (entity.contact_info && entity.contact_info.first_name) {
          return entity.contact_info.first_name.toLowerCase().includes(search);
        }
        if (entity.contact_info && entity.contact_info.email) {
          return entity.contact_info.email.toLowerCase().includes(search);
        }
        if (entity.name) {
          return entity.name.toLowerCase().includes(search);
        }
      })
    },
  },
};
</script>
