<template>
  <div class="page-container intents-container">
    <v-row>
      <v-col cols="12" sm="5" class="d-flex align-center">
        <h2 class="hovered-el" @click="refreshList">
          Chatbot Intents
          <v-icon color="primary" class="hidden-icon pb-1">mdi-reload</v-icon>
        </h2>
      </v-col>
      <v-col cols="12" sm="7">
        <v-text-field
          hide-details
          v-model="searchParam"
          placeholder="Search"
          prepend-inner-icon="mdi-magnify"
          solo
        ></v-text-field>
      </v-col>
    </v-row>
    <div class="expansion-panels-group mt-3" v-if="filteredListPerPage">
      <Intent
        v-for="intent in filteredListPerPage"
        :key="intent.id"
        :intent="intent"
      />
      <v-pagination
        v-show="isShowPagination"
        v-model="page"
        total-visible="7"
        :length="paginationLength"
      ></v-pagination>
      <EmptyBlockStub :list="filteredList" :search="searchParam" @reset="resetSearch()" />
    </div>
    <div v-else class="mt-3 loader-block">
      <v-progress-circular
        :width="3"
        size="60"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <EvaluationChatbotDialog />
    <EvaluationSkillDialog store="skillBundles" />
    <DeleteDialog :store="store" />
  </div>
</template>

<script>
import Intent from "@/components/Chatbot/Intents/Intent.vue";
import EvaluationChatbotDialog from "@/components/Dialogs/EvaluationChatbotDialog.vue";
import DeleteDialog from "@/components/Dialogs/DeleteDialog.vue";
import EvaluationSkillDialog from "@/components/Dialogs/EvaluationSkillDialog.vue";
import EmptyBlockStub from "@/components/_Partials/EmptyBlockStub.vue";
import baseEntity from "@/mixins/_baseEntity";

export default {
  name: "Intents",
  mixins: [baseEntity],
  components: {
    DeleteDialog,
    EvaluationChatbotDialog,
    EvaluationSkillDialog,
    EmptyBlockStub,
    Intent,
  },

  data() {
    return {
      store: "intents",
    };
  },
};
</script>