<template>
  <div class="container chatbot-container">
    <h2>Chatbot Base</h2>
    <div class="mt-4">
      <v-btn color="yellow darken-3" @click="reloadMetastore()" dark>
        <v-icon :class="{ 'rotation-active': isReloadingMetastore }" left
          >mdi-reload</v-icon
        >
        {{ isReloadingMetastore ? "Reloading" : "Reload" }} Metastore
      </v-btn>
      <v-btn 
        color="primary" 
        class="ml-4" 
        @click="isOpenWarningModal = true"
        :loading="retrainingProcess"
      >
        Retrain model
      </v-btn>
    </div>
    <v-dialog v-model="isOpenWarningModal" persistent max-width="360px">
      <v-card>
        <v-card-title>
          <h4 class="font-weight-regular">
            <v-icon color="amber" class="pr-1">mdi-alert</v-icon> Warning!
          </h4>
        </v-card-title>
        <v-card-text>This will fully retrain the models from metastore. This operation will take time, depending on size of train data in metastore and the number of linguistic models configured for this system.
          <br><b>Are you sure you want to run the retrain operation?</b>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn 
            color="red" 
            text 
            :loading="retrainingProcess" 
            @click="retrain()"
          >Yes</v-btn>
          <v-btn color="grey darken-4" text @click="isOpenWarningModal = false">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <Chat />

  </div>
</template>

<script>
import Chat from "@/components/Chatbot/Base/Chat.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Chatbot",

  components: {
    Chat,
  },

  data() {
    return {
      retrainingProcess: false,
      isOpenWarningModal: false,
    };
  },

  computed: {
    ...mapGetters({
      isReloadingMetastore: "chatbot/isReloadingMetastore",
    }),
  },

  methods: {
    ...mapActions("chatbot", ["reloadMetastore", "retrainModel"]),

    retrain() {
      this.retrainingProcess = true;
      this.$root.$emit("openAsyncLogsConsole");
      this.retrainModel().then(() => {
        this.retrainingProcess = false;
      });
    }
  },
};
</script>