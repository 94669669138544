import { PublicClientApplication } from '@azure/msal-browser';
import { getCloudConfigs } from '@/api-configs';
import router from '@/router'

const state = {
  user: null,
  signedIn: false,
  msalInstance: null
};

const getters = {
  user: state => state.user,
  signedIn: state => state.signedIn,
  msalInstance: state => state.msalInstance,
};

const mutations = {
  SIGN_IN: (state, user) => {
    state.user = user;
    state.signedIn = true;
  },

  SIGN_OUT: (state) => {
    state.user = null;
    state.signedIn = false;
    router.push({path:"/"});
  }
};

const actions = {
  /**
    * @param {Object} Vuex
    */
  azureSignIn: async ({ commit, state }) => {
    if (!state.msalInstance) {
      state.msalInstance = new PublicClientApplication(await getCloudConfigs());
    }
    try {
      const accounts = state.msalInstance.getAllAccounts();
      if (accounts.length == 0) {
        return false;
      }
      commit("SIGN_IN", accounts[0])

    } catch(err) {
      commit("SIGN_OUT");
      commit('notifications/SHOW_FAIL', null, { root: true })
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}