<template>
  <div v-if="events">
    <v-btn
      color="primary"
      class="mr-3 text-capitalize"
      :loading="isFetchingPreviousEvents"
      @click="showPreviousEvents()"
      depressed
      outlined
      small
      >Load Previous
    </v-btn>
    <div class="my-4">
      <h4>Fetching events have been started!</h4>
      <div>Stay on this page while the fetching is executed to see progress...</div>
    </div>
    <div v-for="(event, index) in events" :key="index" class="event-item my-3">
      <span class="event-time pr-4">{{ event.timestamp | timeFormat }}</span>
      <span class="event-message pr-4">
        <v-expansion-panels v-if="event.message.length > 200">
          <v-expansion-panel>
            <v-expansion-panel-header>{{
              event.message | textWrapper
            }}</v-expansion-panel-header>
            <v-expansion-panel-content>
              {{ event.message }}
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <template v-else>
          <div v-html="$options.filters.distinguishKeys(event.message)"></div>
        </template>
      </span>
    </div>
    <v-btn
      color="primary"
      class="mr-3 text-capitalize"
      :loading="isResumingEvents"
      @click="showLastEvents()"
      depressed
      outlined
      small
      >{{ isContinuousResuming ? 'Resuming' : 'Resume'}}
    </v-btn>
    <v-btn v-if="isContinuousResuming"
      color="primary"
      class="text-capitalize"
      @click="isContinuousResuming = false"
      text
      small
      >Stop
    </v-btn>
  </div>
  <div v-else class="mt-3 loader-block">
    <v-progress-circular
      :width="3"
      size="60"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Logs",
  props: ['stream'],

  filters: {
    timeFormat: value => {
      if (!value) return '';
      return moment(value).format('YYYY-DD-MM, H:mm:ss')
    },

    textWrapper: text => {
      return text.length > 200 ? `${text.slice(0, 200)}...` : text;
    },

    distinguishKeys: (string) => {
      const consolePaintingRools = {
        error: ['DEPLOYMENT COMPLETED FAILED'],
        success: ['DEPLOYMENT COMPLETED SUCCESSFULLY'],
      }
      for (const [colorClass, keyWords] of Object.entries(consolePaintingRools)) {
        keyWords.map(keyWord => {
          if (string.includes(keyWord)) {
            string = string.replace(
              new RegExp(keyWord, "gi"), 
              match => `<span class="${colorClass}--text">${match}</span>`
            )
          }
        })
      }
      return string;
    }
  },

  data() {
    return {
      isFetchingPreviousEvents: false,
      isResumingEvents: false,
      isContinuousResuming: false,
    };
  },

  mounted() {
    this.showLastEvents();
  },

  computed: {
    ...mapGetters({
      events: "monitoring/events",
    }),
    
    isResumingProcess() {
      return this.isContinuousResuming && 
        (this.$route.name === 'monitoring-service' || this.$route.name === 'monitoring-skills');
    }
  },

  beforeDestroy() {
    this.isContinuousResuming = false;
  },

  methods: {
    ...mapActions('monitoring', {
      getEventsByStream: 'getEventsByStream',
    }),

    showPreviousEvents() {
      this.isFetchingPreviousEvents = true;
      this.getEventsByStream({
        container_id: this.stream,
        before: true,
      }).then(() => {
        this.isFetchingPreviousEvents = false;
      });
    },


    showLastEvents() {
      this.isContinuousResuming = true;
      const resuming = () => {
        this.isResumingEvents = true;
        const beforeFetchEventsLength = this.events ? this.events.length : 0;
        this.getEventsByStream({ container_id: this.stream, after: true }).then(() => {
          this.isResumingEvents = false;
          if (this.events.length > beforeFetchEventsLength && this.isContinuousResuming) {
            setTimeout(() => window.scrollTo(0, document.body.scrollHeight), 0);
          }
          (this.isResumingProcess) && setTimeout(() => resuming(), 3000);
        });
      };
      resuming();
      
    },
    
  },
};
</script>

