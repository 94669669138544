let _backendConfigs = null;

const initialization = async () => {
  const response = await fetch(`${window.location.origin}/config.json`);
  const result = await response.json();

  /* caching configurations */
  _backendConfigs = result;
  return _backendConfigs;
}

const getConfigs = async () => {
  if (!_backendConfigs) {
    await initialization();
  }
  return _backendConfigs;
}

export const getCloudConfigs = async () => {

  const configs = await getConfigs();

  if (!configs.vendor) {
    configs.vendor = 'aws';
    console.error('Configuration vendor not found!')
  }

  if (configs.vendor === 'azure') {
    const { clientId, tenantId, redirectUri } = configs;

    return {
      auth: {
        clientId,
        authority:`https://login.microsoftonline.com/${tenantId}`,
        redirectUri,
      },
      cache: {
        cacheLocation: 'localStorage',
      },
    }
  }
  const { region, userPoolId, userPoolWebClientId } = configs;

  return {
    Auth: {
      mandatorySignIn: true,
      region,
      userPoolId,
      userPoolWebClientId,
    }
  };
}

export const getBaseUrl = async () => {
  const { apiUrl } = await getConfigs();

  return apiUrl;
}

export const getEnv = async () => {
  const { envName } = await getConfigs();

  return envName;
}

export const getTz = async () => {
  const { timezone } = await getConfigs();

  return timezone;
}

export const isAzure = async () => {
  const { vendor } = await getConfigs();

  return vendor === 'azure';
}

export const isAWS = async () => {
  const { vendor } = await getConfigs();

  return vendor === 'aws';
}
