const state = {
  text: '',
  isShown: false,
  color: '',
  timeout: -1,
};

const getters = {
  text: state => state.text,
  isShown: state => state.isShown,
  color: state => state.color,
  timeout: state => state.timeout
};

const mutations = {

  SHOW_SUCCESS: (state, text) => {
    state.text = text;
    state.color = 'teal';
    state.timeout = 4000;
    state.isShown = true;
  },

  SHOW_FAIL: (state, text) => { 
    state.text = text ? text : 'Something went wrong!';
      state.color = 'red';
      state.timeout = 0;
      state.isShown = true;
  },

  SHOW_INFO: (state, text) => {
    state.text = text;
    state.color = 'primary';
    state.timeout = 10000;
    state.isShown = true;
  }
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}