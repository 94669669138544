<template>
  <v-btn 
    small
    :text="!isButton"
    :outlined="isButton"
    class="text-capitalize"
    :class="{'force-link': !isButton}" 
    :color="bgColor" 
    @click="getFile()"
    :loading="downloading"
  >
    {{ linkLabel }}
  </v-btn>
</template>
<script>

import { mapActions } from "vuex";

export default {
  name: "LinkDownload",

  props: {
    path: {
      type: String,
      required: true
    },
    label: {
      type: String,
    },
    isButton: {
      type: Boolean,
      default: false,
    },
    bgColor: {
      type: String,
      default: 'primary',
    }
  },

  data() {
    return {
      downloading: false,
    };
  },

  computed: {
    linkLabel() {
      const { label, path } = this; 
      if (!label) {
        const splittedPath = path.split('/');
        return splittedPath[splittedPath.length - 1];
      }
      return label;
    }
  },

  methods: {
    ...mapActions("usageLogs", ["download"]),
      
    getFile() {
      this.downloading = true;
      this.download(this.path).then((url) => {
        const link = document.createElement('a');
        link.href = url;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.downloading = false;
      })
    }
  },
};
</script>