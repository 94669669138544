<template>
  <span v-if="isError" class="red--text">{{ errorText }}</span>
  <component v-else 
    v-bind:is="currentAnswerTypeComponent" 
    :entity-name="type" 
    :entity="payload"
  >
  </component>
</template>

<script>
import AnswerText from '@/components/Skills/Answers/AnswerText.vue';
import AnswerImage from '@/components/Skills/Answers/AnswerImage.vue';
import AnswerUrl from '@/components/Skills/Answers/AnswerUrl.vue';
import AnswerSelector from '@/components/Skills/Answers/AnswerSelector.vue';
import AnswerCsv from '@/components/Skills/Answers/AnswerCsv.vue';
import AnswerHtml from '@/components/Skills/Answers/AnswerHtml.vue';
import AnswerFile from '@/components/Skills/Answers/AnswerFile.vue';

export default {
  name: "AnswerRouter",

  components: {
    AnswerText,
    AnswerImage,
    AnswerUrl,
    AnswerSelector,
    AnswerCsv,
    AnswerHtml,
    AnswerFile,
  },

  props: ["payload", "type"],

  computed: {
    currentAnswerTypeComponent() {
      return `answer-${this.payload.payload_type}`;
    },
    isError() {
      return !!this.payload.error;
    },
    errorText() {
      return this.payload.error;
    }
  }
}
</script>