<template>
  <div>
    <a target="_blank" :href="url">{{ label }}</a>
  </div>
</template>
<script>

export default {
  name: "AnswerUrl",

  props: ["entity"],

  computed: {
    payloadVersion() {
      const { payload_version } = this.entity;
      return payload_version ? +payload_version : 0;
    },

    url() {
      const { url } = this.entity.payload;
      return url;
    },

    label() {
      const { label } = this.entity.payload;
      return (label) ? label : this.url;
    }
  }
}
</script>