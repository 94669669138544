<template>
  <div class="page-container intents-container">
    <v-row>
      <v-col cols="12" sm="5" class="d-flex align-center">
        <h2 class="hovered-el" @click="refreshList">
          Chatbot Integrations
          <v-icon color="primary" class="hidden-icon pb-1">mdi-reload</v-icon>
        </h2>
      </v-col>
    </v-row>
    <CreationForm />
    <div v-if="entityList">
      <v-card class="mt-3" v-for="itiem of entityList" :key="itiem.title">
        <v-card-title>{{ itiem.integration_type }}</v-card-title>
        <div>
          <div class="card-block pb-4">
            <div class="card-row" v-if="itiem.name">
              <span class="row-title">name:</span>
              <span class="row-value pl-3">{{ itiem.name }}</span>
            </div>
            <div class="card-row" v-if="itiem.id">
              <span class="row-title">id:</span>
              <span class="row-value pl-3">{{ itiem.id }}</span>
            </div>
            <div class="card-row" v-if="itiem.last_updated_time">
              <span class="row-title">Updated:</span>
              <span class="row-value pl-3">{{ itiem.last_updated_time }}</span>
            </div>
            <div class="card-row" v-if="itiem.rest_api_id">
              <span class="row-title">rest_api_id:</span>
              <span class="row-value pl-3">{{ itiem.rest_api_id }}</span>
            </div>
            <div class="card-row" v-if="itiem.secret_key_arn">
              <span class="row-title">secret_key_arn:</span>
              <span class="row-value pl-3">{{ itiem.secret_key_arn }}</span>
            </div>
            <div class="card-row" v-if="itiem.secret_key_id">
              <span class="row-title">secret_key_id:</span>
              <span class="row-value pl-3">{{ itiem.secret_key_id }}</span>
            </div>
            <div class="card-row" v-if="itiem.stack_name">
              <span class="row-title">stack_name:</span>
              <span class="row-value pl-3">{{ itiem.stack_name }}</span>
            </div>
            <div class="card-row" v-if="itiem.email_inbox_endpoint">
              <span class="row-title">email_inbox_endpoint:</span>
              <span class="row-value pl-3">{{ itiem.email_inbox_endpoint }}</span>
            </div>
          </div>
        </div>
      </v-card>
      <EmptyBlockStub :list="entityList"  
        @reset="resetSearch()" 
        initial-empty-msg="There are no integrations yet!"
      />
    </div>
    <div v-else class="mt-3 loader-block">
      <v-progress-circular
        :width="3"
        size="60"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import baseEntity from "@/mixins/_baseEntity";
import CreationForm from "@/components/Chatbot/Integrations/creationForm.vue";
import EmptyBlockStub from "@/components/_Partials/EmptyBlockStub.vue";

export default {
  name: "Integrations",
  components: {
    CreationForm,
    EmptyBlockStub,
  },
  mixins: [baseEntity],

  data() {
    return {
      store: "integrations",
      isCreationFromOpen: false,
    };
  },
};
</script>