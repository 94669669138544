<template>
  <div class="mt-2">
    <h4 class="text-capitalize">{{ ruleName | pluralize }}</h4>
    <div>
      <span class="red--text" v-if="!ruleList.length">List of rules is empty</span>
      <v-chip
        v-for="item in ruleList" 
        :key="item"
        class="mr-2"
        close
        small
        color="primary"
        text-color="white"
        close-icon="mdi-delete"
        @click:close="remove(item)"
      >
        {{ item }}
      </v-chip>
      <div class="mt-2">
        <div v-if="isShownCreationRuleForm" class="pb-2">
          <v-row>
            <v-col cols="12" sm="6" md="10">
              <v-text-field
                :label="`New ${ruleName}`"
                v-model="newRule"
                hide-details
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="2" class="d-flex align-center">
              <v-btn
                color="green"
                class="white--text text-capitalize"
                @click="saveSubmit()"
                :loading="savingProcess"
                depressed
              >
                Save
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <v-btn
          color="primary"
          class="white--text text-capitalize"
          small
          @click="toggleCreationRuleForm()"
          depressed
          outlined
        >
          {{ isShownCreationRuleForm ? 'Close' : `Add new ${ruleName}` }}
        </v-btn>
      </div>
    </div>

    <div v-if="isCurrentRuleRegExp">
      <v-dialog v-model="isOpenWarningModal" persistent max-width="360px">
        <v-card>
          <v-card-title>
            <h4 class="font-weight-regular">
              <v-icon color="amber" class="pr-1">mdi-alert</v-icon> Warning!
            </h4>
          </v-card-title>
          <v-card-text>Please make sure that the regex provided is as strict as possible so that unintended/malicious users don't gain access!
            <br><b>Would you like to continue?</b>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn 
              color="red" 
              text 
              :loading="savingProcess" 
              @click="savaNewRule()"
            >Yes</v-btn>
            <v-btn color="grey darken-4" text @click="closeWarningModal()">No</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "RuleRow",

  filters: {
    pluralize: (str) => {
      const esSingEnds = ['s', 'x', 'z'];
      const esDubEnds = ['ss', 'sh', 'ch'];
      return (
        esSingEnds.some(end => end === str.slice(-1)) || 
        esDubEnds.some(end => end === str.slice(-2)) ? 
        `${str}es` : `${str}s`
      );
    }
  },

  props: {
    ruleName: {
      type: String,
      required: true
    },

    ruleList: {
      type: Array,
      required: true
    },
  },

  data() {
    return {
      isShownCreationRuleForm: false,
      isOpenWarningModal: false,
      newRule: '',
      savingProcess: false,
    }
  },

  computed: {
    isCurrentRuleRegExp() {
      return this.ruleName === 'regex';
    }
  },

  methods: {
    ...mapActions("accessControl", ['setRule', 'removeRule', 'getAllowList']),

    toggleCreationRuleForm() {
      if (!this.isShownCreationRuleForm) {
        this.isShownCreationRuleForm = true;
        return false;
      }
      this.isShownCreationRuleForm = false;
      this.newRule = '';
    },

    saveSubmit() {
      if (this.isCurrentRuleRegExp) {
        this.isOpenWarningModal = true;
        return false;
      }
      this.savaNewRule();
    },

    savaNewRule() {
      if (!this.newRule) return false;
      this.savingProcess = true;
      this.setRule({rule_type: this.ruleName, value: this.newRule}).then(() => {
        this.isCurrentRuleRegExp && this.closeWarningModal();
        this.getAllowList();
        this.savingProcess = false;
        this.toggleCreationRuleForm();
      })
    },

    closeWarningModal() {
      this.isOpenWarningModal = false;
    },

    remove(value) {
      if (!value) return false;
      this.removeRule({rule_type: this.ruleName, value}).then(() => {
        this.getAllowList();
      });
    }
  },
};
</script>