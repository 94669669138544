<template>
  <div class="page-container skill-bundles-container">
    <v-row>
      <v-col cols="12" sm="5" class="d-flex align-center">
        <h2 class="hovered-el" @click="refreshList">
          Skill Bundles
          <v-icon color="primary" class="hidden-icon pb-1">mdi-reload</v-icon>
        </h2>
      </v-col>
      <v-col cols="12" sm="7">
        <v-text-field
          hide-details
          v-model="searchParam"
          placeholder="Search"
          prepend-inner-icon="mdi-magnify"
          solo
        ></v-text-field>
      </v-col>
    </v-row>
    <div class="mt-3">
      <v-btn
        color="green darken-1"
        class="white--text"
        @click="openUploadDialog()"
        depressed
      >
        <v-icon left>mdi-cloud-upload</v-icon>Upload skill bundle
      </v-btn>
    </div>
    <div class="expansion-panels-group mt-5" v-if="filteredListPerPage">
      <SkillBundle
        v-for="bundle in filteredListPerPage"
        :key="bundle.id"
        :skill-bundle="bundle"
      />
      <v-pagination
        v-show="isShowPagination"
        v-model="page"
        total-visible="7"
        :length="paginationLength"
      />
      <EmptyBlockStub :list="filteredList" :search="searchParam" @reset="resetSearch()" />
    </div>
    <div v-else class="mt-3 loader-block">
      <v-progress-circular
        :width="3"
        size="60"
        color="primary"
        indeterminate
      />
    </div>

    <DeploySkillDialog :store="store" />
    <DeleteDialog :store="store" />
    <EvaluationSkillDialog :store="store" />
  </div>
</template>

<script>
import SkillBundle from "@/components/Skills/SkillBundle.vue";
import EvaluationSkillDialog from "@/components/Dialogs/EvaluationSkillDialog.vue";
import DeploySkillDialog from "@/components/Dialogs/DeploySkillDialog.vue";
import DeleteDialog from "@/components/Dialogs/DeleteDialog.vue";
import EmptyBlockStub from "@/components/_Partials/EmptyBlockStub.vue";
import baseEntity from "@/mixins/_baseEntity";

export default {
  name: "SkillBundles",
  mixins: [baseEntity],
  components: {
    SkillBundle,
    EvaluationSkillDialog,
    DeploySkillDialog,
    DeleteDialog,
    EmptyBlockStub,
  },

  data() {
    return {
      store: "skillBundles",
      baseFilterField: 'skill_name'
    };
  },

  methods: {
    openUploadDialog() {
      this.$root.$emit("openUploadDialog");
    },

    initEntity() {
      setTimeout(() => this.getAllEntities(), 0);
      this.setPegaBySelectedEntity();
    },
  },
};
</script>
