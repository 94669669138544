import API from '@/services/api/base';
import baseStore from '@/store/modules/_base';

const state = {
  ...baseStore.state,
  url: 'bot/allow',
};

const getters = {
  ...baseStore.getters,
};

const mutations = {
  ...baseStore.mutations,
};

const actions = {
  ...baseStore.actions,

  add: ({ commit, state, dispatch }, entry) => {
    return new Promise((resolve) => {
      API
        .postWithEntry(state.url, entry)
        .then((res) => {
          resolve();
          if (res.data.error_message) {
            commit('notifications/SHOW_FAIL', res.data.error_message, { root: true })
            return false
          }
          commit('notifications/SHOW_SUCCESS', "Saved", { root: true })
          dispatch('getAllEntities').then(() => {
            commit('notifications/SHOW_SUCCESS', "Whitelist updated", { root: true })
          })
        })
        .catch(err => {
          console.log(err)
          commit('notifications/SHOW_FAIL', null, { root: true })
        })
    });
  },

  delete: ({ commit, state, dispatch }, { identity, identity_type }) => {
    return new Promise((resolve) => {
      API
        .delete(`${state.url}?identity=${encodeURIComponent(identity)}&identity_type=${identity_type}`)
        .then((res) => {
          resolve();
          if (res.data.error_message) {
            commit('notifications/SHOW_FAIL', res.data.error_message, { root: true })
            return false
          }
          commit('notifications/SHOW_SUCCESS', "Deleted", { root: true })
          dispatch('getAllEntities').then(() => {
            commit('notifications/SHOW_SUCCESS', "Whitelist updated", { root: true })
          })
        })
        .catch(err => {
          console.log(err)
          commit('notifications/SHOW_FAIL', null, { root: true })
        })
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}