import Vue from 'vue';
import moment from "moment";
import API from '@/services/api/product-analytics';
import baseStore from '@/store/modules/_base';
import { chainBy } from '@/utils'

const state = {
  ...baseStore.state,
  url: 'reports',
  reportRequests: [],
  latestGetedReportDate: moment().format("YYYY-MM-DD"),
  cachedUsers: [],
};

const getters = {
  ...baseStore.getters,

  latestGetedReportDate: state => state.latestGetedReportDate,

  reportRequests: state => {
    if (state.reportRequests.length) {
      return state.reportRequests;
    }
    return null;
  },

  listOfCachedUsers: state => state.cachedUsers
};

const mutations = {
  ...baseStore.mutations,

  SET_REPORT: (state, { requestBunch }) => {
    if (!Array.isArray(requestBunch)) return false
    requestBunch = chainBy(requestBunch, 'fulfilment_id', 'fulfillmentChain');
    requestBunch = requestBunch.sort((a, b) => new Date(b.request_timestamp) - new Date(a.request_timestamp));
    state.reportRequests.push(...requestBunch)
  },

  EXTEND_REPORT_REQUEST: (state, { requestId, data }) => {
    state.reportRequests.map(request => {
      if (request.request_id === requestId) {
        Vue.set(request, 'user', { ...data });
        return false;
      }
      if (request.fulfillmentChain) {
        request.fulfillmentChain.map(chainRequest => {
          if (chainRequest.request_id === requestId) {
            Vue.set(chainRequest, 'user', { ...data });
            return false;
          } 
        })
      }
    })
  },

  UPDATE_LATEST_GENERATED_REPORT_DATE: (state, period) => {
    state.latestGetedReportDate = moment(state.latestGetedReportDate).subtract(1, period).format("YYYY-MM-DD");
  },

  CLEAR_REQUESTS_LIST: (state) => {
    state.reportRequests = [];
    state.latestGetedReportDate = moment().format("YYYY-MM-DD");
  },

  ADD_USER_INFO: (state, { userId, userName }) => {
    state.cachedUsers.push({ id: userId, name: userName });
  }
};


const actions = {
  ...baseStore.actions,
  /**
    * @param {Object} Vuex
    * @param {string} date - needed report date
    */
  get: ({ state, commit }, date) => {
    return new Promise((resolve) => {
      API
        .getReport(state.url, date)
        .then(res => {
          if (!res.data.data) {
            commit('notifications/SHOW_FAIL', `${res.data.error_message} for ${date}`, { root: true });
            commit('SET_REPORT', { requestBunch: [] })
            commit('UPDATE_LATEST_GENERATED_REPORT_DATE', 'day');
            resolve(state.reportRequests.length)
            return false;
          }
          fetch(res.data.data.url).then(res => res.json())
          .then(data => {
            commit('SET_REPORT', { requestBunch: data })
            commit('UPDATE_LATEST_GENERATED_REPORT_DATE', 'day');
          })
          .finally(() => {
            resolve(state.reportRequests.length)
          })
        })
        .catch(err => {
          console.log(err.message)
          commit('notifications/SHOW_FAIL', null, { root: true })
        })
    });
  },

  getForPeriod: ({ state, commit }, { date, period }) => {
    return new Promise((resolve) => {
      API
        .getReportForPeriod(state.url, date, period)
        .then(res => {
          if (!res.data.data.url) {
            commit('notifications/SHOW_FAIL', `Presigned URL hasn't been gotten for ${date}`, { root: true })
            return false;
          }
          fetch(res.data.data.url).then(res => res.json())
            .then(data => {
              if (!Array.isArray(data)) {
                commit('notifications/SHOW_FAIL', `There is no report for ${date} by ${period}`, { root: true })
                return false
              }
              if (data.length) {
                data.reduceRight((_, dayRequests) => commit('SET_REPORT', { requestBunch: dayRequests }), null);
              }
              commit('UPDATE_LATEST_GENERATED_REPORT_DATE', period);
            })
            .finally(() => {
              resolve(state.reportRequests.length)
            })
        })
        .catch(err => {
          console.log(err.message)
          commit('notifications/SHOW_FAIL', null, { root: true })
        })
    });
  },

  /**
  * @param {Object} Vuex
  * @param {string} payload image path 
  */
  getImage: ({ commit }, payload) => {
    return new Promise((resolve) => {
      API
        .getImage(payload)
        .then(res => {
          if (res.data.error_message) {
            commit('notifications/SHOW_FAIL', res.data.error_message, { root: true })
            return false
          }
          fetch(res.data.data.url).then(res => resolve(res.url));
        })
        .catch(err => {
          console.log(err.message)
          commit('notifications/SHOW_FAIL', null, { root: true })
        })
    });
  },

  /**
  * @param {Object} Vuex
  * @param {string} payload image path 
  */
 getUserInfo: ({ commit }, requestId) => {
  return new Promise((resolve) => {
    API
      .getUser(encodeURIComponent(requestId))
      .then(res => {
        if (res.data.error_message) {
          commit('notifications/SHOW_FAIL', res.data.error_message, { root: true })
          return false
        }
        commit('ADD_USER_INFO', { userId: requestId, userName: res.data.data.name })
        resolve(res.data)
      })
      .catch(err => {
        console.log(err.message)
        commit('notifications/SHOW_FAIL', null, { root: true })
      })
  });
},

  /**
  * @param {Object} Vuex
  * @param {String} dates: list of dates
  */
  generate: ({ commit, dispatch }, dates) => {
    return new Promise((resolve) => {
      API
        .generateReports(JSON.stringify({ dates: [dates] }))
        .then(res => {
          dispatch('getAsyncQueueStatus', res.data.data).then((result) => {
            if (result.error_message) {
              commit('notifications/SHOW_FAIL', result.error_message, { root: true })
              return false
            }
            commit('notifications/SHOW_SUCCESS', 'Reports have been generated!', { root: true })
          })
            .catch(err => {
              console.log(err)
            })
            .finally(() => resolve())
        })
        .catch(err => {
          console.log(err)
          commit('notifications/SHOW_FAIL', null, { root: true })
        })
    })
  },
};




export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}