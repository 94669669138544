

import API from '@/services/api/environments';
import baseStore from './_base';

const state = {
  ...baseStore.state,
  url: 'environment',
  environments: null,
};

const getters = {
  ...baseStore.getters,

  environments: state => {
    if (!state.environments) return null;
    const environments = [];
    for (const [key, value] of Object.entries(state.environments)) {
      environments.push({ title: key, environment: value })
    }
    return environments;
  },
};

const mutations = {
  ...baseStore.mutations,

  SET_ENVIRONMENTS: (state, data) => {
    state.environments = data
  },

};

const actions = {
  ...baseStore.actions,
  /**
    * @param {Object} Vuex
    */
  getEnviroments: ({ commit, state }) => {
    API
      .getInitialEntities(state.url)
      .then(res => {
        if (res.data.error_message) {
          commit('notifications/SHOW_FAIL', res.data.error_message, { root: true })
          return false
        }
        commit('SET_ENVIRONMENTS', res.data.data)
      })
      .catch(err => {
        console.log(err.message)
        commit('notifications/SHOW_FAIL', null, { root: true })
      })
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}