import { mapState, mapMutations, mapActions } from "vuex";

export default {
  data() {
    return {
      searchParam: "",
      page: 1,
      entityPerPage: 10,
      baseFilterField: 'name'
    }
  },

  mounted() {
    this.initEntity()
  },

  computed: {
    ...mapState({
      entityList (state, getters) {
        return getters[`${this.store}/entityList`]
      }
    }),
    filteredListPerPage() {
      return this.filteredList && 
        this.filteredList.slice((this.page - 1) * this.entityPerPage, this.page * this.entityPerPage)
    },

    filteredList() {
      const search = this.searchParam.toLowerCase().trim();
      if (!search) return this.entityList;
      if (!this.entityList) return null;
      return this.entityList.filter((entity) => {
        if (this.page > 1) this.setInitialPage();
        return entity[this.baseFilterField].toLowerCase().includes(search)
      })
    },

    isEmptyList() {
      return Array.isArray(this.entityList) && !this.entityList.length;
    },

    paginationLength() {
      return Math.ceil(this.filteredList.length / this.entityPerPage);
    },

    isShowPagination() {
      return this.filteredList && (this.filteredList.length > this.entityPerPage);
    },

    showedEntityIndexFromStart() {
      const { id } = this.$route.query;
      if (!id || !this.filteredList) return false;
      const index = this.filteredList.findIndex(entity => entity.id === id);
      return (index > -1) && index;
    }
  },

  methods: {
    ...mapActions({
      getAllEntities (dispatch, payload) {
        return dispatch(`${this.store}/getAllEntities`, payload)
      }
    }),

    ...mapMutations({
      clearList (commit, payload) {
        return commit(`${this.store}/CLEAR_LIST`, payload)
      },
    }),

    initEntity() {
      setTimeout(() => !this.entityList && this.getAllEntities(), 0);
      this.setPegaBySelectedEntity();
    },

    refreshList() {
      this.clearList();
      this.getAllEntities();
    },

    setInitialPage() {
      this.page = 1;
    }, 

    setPegaBySelectedEntity() {
      if (!this.showedEntityIndexFromStart) return false;
      this.page = Math.ceil((this.showedEntityIndexFromStart + 1) / this.entityPerPage);
    },
    
    resetSearch() {
      this.searchParam = '';
    }
  },

  watch: {
    showedEntityIndexFromStart() {
      this.setPegaBySelectedEntity()
    },
  }

}