import { mapMutations, mapActions } from 'vuex'

export default {
  data() {
    return {
      searchParam: '',
      store: "configurations",
    }
  },
  mounted() {
    this.setConfigType(this.configType)
    !this.filteredList && this.getConfigurations()
  },

  computed: {
    filteredList() {
      const search = this.searchParam.toLowerCase().trim();
      if (!search) return this.configurationList;

      return this.configurationList && 
        Object.keys(this.configurationList)
        .filter(config => config.toLowerCase().includes(search))
        .reduce((obj, key) => {
          obj[key] = this.configurationList[key];
          return obj;
      }, {});
    },

    keyList() {
      const search = this.searchParam.toLowerCase().trim();
      if (!search) return Object.keys(this.configurationList);
      return Object.keys(this.configurationList);
    }
  },

  methods: {
    ...mapMutations('configurations', {
      setConfigType: 'SET_CONFIGURATION_TYPE',
    }),
    ...mapActions('configurations', {
      getConfigurations: 'getConfigurations',
    }),

    resetSearch() {
      this.searchParam = '';
    }
  }
}