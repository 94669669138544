<template>
  <div v-if="skill">
    <v-dialog v-model="isOpen" persistent max-width="600">
      <v-card class="evaluation-modal">
        <v-card-title>
          <h4 class="font-weight-regular">
            Evaluation
            <span class="font-weight-bold">{{ skill.name }}</span>
          </h4>
        </v-card-title>
        <div class="evaluation-answer-block">
          <v-card-actions>
            <h3 class="evaluation-title">Skill answered:</h3>
          </v-card-actions>
          <v-card-actions v-if="!evaluationResult" class="evaluation-empty-result">
            Send a question to the skill to get an answer
          </v-card-actions>
          <div v-if="evaluationNotebook" class="ml-6">
            Notebook: <link-download :path="evaluationNotebook" />
          </div>
          <div>
            <v-expansion-panels accordion class="evaluation-answer-panels my-4">
              <v-expansion-panel v-for="(evaluationEntity, index) in evaluationResult" :key="index" class="evaluation-answer-panel">
                <v-expansion-panel-header>{{evaluationEntity.title}} ({{evaluationEntity.value.length}})</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div v-for="(evalEntityItem, index) in evaluationEntity.value" :key="index" class="evaluation-answer-container">
                    <div class="tags" v-if="evalEntityItem.tags">
                      <span class="tags-element" v-for="(tag, index) in evalEntityItem.tags" :key="index">{{ tag }}</span>
                    </div>
                    <AnswerRouter :payload="evalEntityItem" :type="evaluationEntity.title" />
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </div>
        <div v-if="skill.question" class="d-block pl-6">
          <v-chip>{{ skill.question }}</v-chip>
        </div>
        <v-card-actions>
          <v-select
            v-if="skillQuestionList && !skill.question"
            @change="resetActiveParameter()"
            :items="skillQuestionList"
            :disabled="initialFetching"
            v-model="questionText"
            label="Choose a question"
            item-text="title"
            filled
          ></v-select>
        </v-card-actions>
        <div v-if="!currentIntentParameters || initialFetching" class="loader-block-dialog">
          <v-progress-circular :width="3" size="25" color="primary" indeterminate></v-progress-circular>
        </div>
        <v-card-actions class="flex-column" v-if="currentIntentParameters">
          <div class="param-row" v-for="(param, index) in currentIntentParameters" :key="index">
            <div>
              <v-switch
                @change="chooseParameter(param.slot_type, param.active)"
                v-model="param.active"
                :label="param.title"
                class="param-switch"
              ></v-switch>
              <div class="param-settings" v-show="param.active">
                <v-select
                  v-if="param.slots && param.slots.length > 1"
                  :items="param.slots"
                  v-model="param.slotValue"
                  @change="chooseSlot(param.slotValue, param.slots)"
                  label="Choose a slot"
                  class="param-select mr-2"
                  dense
                  filled
                ></v-select>
                <v-text-field
                  v-if="param.slot_type == 'number'"
                  label="Enter amount"
                  v-model="param.exampleValue"
                  @change="chooseExample(param.exampleValue, param.title)"
                  class="param-select"
                  type="number"
                  filled
                ></v-text-field>
                <v-select
                  v-else-if="param.examples && param.examples.length >= 1"
                  :items="param.examples"
                  v-model="param.exampleValue"
                  @change="chooseExample(param.exampleValue, param.title)"
                  label="Choose an example"
                  class="param-select"
                  dense
                  filled
                  :multiple="!param.is_scalar"
                ></v-select>
                <v-progress-circular :width="3" size="25" color="primary" class="mb-5" indeterminate v-else></v-progress-circular>
              </div>
            </div>
          </div>
        </v-card-actions>
        <v-card-actions>
          <v-textarea
            filled
            class="evapluation-param-field"
            label="Skill parameters"
            :value="evaluationParams"
          ></v-textarea>
        </v-card-actions>
        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <v-progress-circular :width="3" size="25" color="primary" indeterminate v-if="evaluationProcess"></v-progress-circular>
          <v-btn
            color="green darken-3"
            @click="sendEvaluationMessage()"
            :disabled="!isReadyToEvaluate"
            text
          >Send</v-btn>
          <v-btn color="grey darken-4" text @click="closeDialogEvaluation()">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import linkDownload from '@/components/_Partials/linkDownload.vue'
import AnswerRouter from '@/components/Skills/Answers/Router.vue';

export default {
  name: "EvaluationSkillDialog",
  components: {
    linkDownload,
    AnswerRouter,
  },

  provide: {
   is_materialized: false,
  },

  props: ["store"],

  data() {
    return {
      isOpen: false,
      skill: null,
      questionText: "",
      evaluationProcess: false,
      initialFetching: false,
    };
  },
  
  mounted() {
    this.$root.$on("openSkillEvaluationDialog", (data) => {
      this.isOpen = true;
      this.skill = data;
      if (this.skill.metadata) {
        this.initialFetching = true;
        this.getSkillDetails({ 
          intents: this.skill.metadata.Skill.Intents,
          bundleId: this.skill.id
        }).then(() => {
          this.initialFetching = false;
        });
        return false;
      }
      this.getSkillDetails({ bundleId: this.skill.id });
    });
  },

  beforeDestroy () {
    this.clearEvaluation();
    this.$root.$off('openSkillEvaluationDialog')
  },

  computed: {
    ...mapState({
      skillQuestionList (state, getters) {
        return getters[`${this.store}/skillQuestionList`]
      },
      skillParameterList (state, getters) {
        return getters[`${this.store}/skillParameterList`]
      },
      evaluationParams (state, getters) {
        return getters[`${this.store}/evaluationParams`]
      },
      evaluationResult (state, getters) {
        return getters[`${this.store}/evaluationResult`]
      },
      evaluationNotebook (state, getters) {
        return getters[`${this.store}/evaluationNotebook`]
      }
    }),

    currentIntentParameters() {
      const intent = this.questionText || this.skill.question
      if (intent && this.skillParameterList) {
        if (this.skillParameterList[intent]) {
          return Object.values(this.skillParameterList[intent])
        }
      }

      return [];
    },

    evaluationSkillQuestionId() {
      if (this.skill.answer_id)
        return this.skill.answer_id;
      const currentQuestion = this.skillQuestionList
        ? this.skillQuestionList.filter(
            (question) => question.title === this.questionText
          )
        : [];
      return currentQuestion.length ? currentQuestion[0].id : null;
    },

    isReadyToEvaluate() {
      if (!this.currentIntentParameters || this.evaluationProcess) return false;
      const isParameterNotConfigured = this.currentIntentParameters.some(
        (param) => param.active && !param.exampleValue
      );
      return this.evaluationSkillQuestionId && !isParameterNotConfigured;
    },
  },

  methods: {
    ...mapActions({
      getSkillDetails(dispatch, payload) {
        return dispatch(`${this.store}/getSkillDetails`, payload)
      },
      getSlotById(dispatch, payload) {
        return dispatch(`${this.store}/getSlotById`, payload)
      },
      evaluateSkillById(dispatch, payload) {
        return dispatch(`${this.store}/evaluateSkillById`, payload)
      },
    }),

    ...mapMutations({
      setParameterValue (commit, payload) {
        return commit(`${this.store}/SET_PARAM_VALUE`, payload)
      },
      clearEvaluation (commit, payload) {
        return commit(`${this.store}/CLEAR_EVALUATION`, payload)
      },
    }),

    chooseParameter(slot_type, status) {
      const intent = this.questionText || this.skill.question
      if (status) this.getSlotById({id: slot_type, intent: intent});
    },

    chooseSlot(slot_type, slotList) {
      const intent = this.questionText || this.skill.question
      if (slotList && slotList.length > 1) this.getSlotById({id: slot_type, intent: intent});
    },

    chooseExample(value, parameter) {
      const intent = this.questionText || this.skill.question
      this.setParameterValue({ value, parameter, intent: intent });
    },

    resetActiveParameter() {
      if (this.skillParameterList) {
        for (const intent in this.skillParameterList) {
          const intentParameters = this.skillParameterList[intent];
          for (const param in intentParameters) {
            intentParameters[param].active = false;
          }
        }
      }
    },

    sendEvaluationMessage() {
      this.evaluationProcess = true;
      const entry = JSON.parse(this.evaluationParams);
      const skillName = this.skill.name ? this.skill.name : this.skill.id ? this.skill.id : null;
      entry.question_id = this.evaluationSkillQuestionId;
      this.$root.$emit("openAsyncLogsConsole");
      this.evaluateSkillById({
        id: skillName,
        entry: JSON.stringify(entry),
      }).then(() => { this.evaluationProcess = false });
    },

    closeDialogEvaluation() {
      this.clearEvaluation();
      this.isOpen = false;
    },
  },
};
</script>