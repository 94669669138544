<template>
  <v-dialog v-model="isOpen" persistent max-width="460px">
    <v-card v-if="user">
      <v-card-title>
        <h4 class="font-weight-regular">
          Demote user <b>{{ user.user_id }}</b>?
        </h4>
      </v-card-title>
      <v-card-text>This action is irreversible!</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text 
          color="red" 
          :loading="isLoading" 
          @click="removeOwner()"
        >Demote</v-btn>
        <v-btn text 
          color="grey darken-4" 
          @click="$emit('close')">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: 'RemoveOwnerDialog',

  props: ['isOpen', 'user', 'name'],

  data() {
    return {
      isLoading: false,
    }
  },

  methods: {
    ...mapActions("groups", ["removeGroupOwner"]),

    removeOwner() {
      this.isLoading = true;
      this.removeGroupOwner({
        group_name: this.name,
        channel: this.user.channel,
        identity: this.user.identity,
      }).then(() => {
        this.isLoading = false;
        this.$emit('close');
      })
    },
  }
}
</script>
