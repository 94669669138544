import Vue from 'vue';
import API from '@/services/api/base';
import baseStore from '@/store/modules/_base';

const state = {
  ...baseStore.state,
  url: 'skillauthorization/group',
  skillAuthUrl: 'skillauthorization',
};

const getters = {
  ...baseStore.getters,
  groupList: state =>  state.entity && state.entity.map(group => group.name),
};

const mutations = {
  ...baseStore.mutations,

  UPDATE_GROUP_INFO: (state, data) => {
    state.entity && state.entity.map((group, index) => {
      if (group.name === data.name) {
        Vue.set(state.entity, index, data);
      }
    })
  },
};

const actions = {
  ...baseStore.actions,

  /**
   * @param {Object} Vuex
   * @param {String} name - group name ;
   */
  createGroup: ({ commit, state }, name) => {
    return new Promise((resolve) => {
      API
        .postWithEntry(state.url, JSON.stringify({group_name: name}))
        .then(res => {
          if (res.data.error_message) {
            commit('notifications/SHOW_FAIL', res.data.error_message, { root: true })
            return false
          }
          commit('notifications/SHOW_SUCCESS', 'Group created!', { root: true })
        })
        .catch(err => {
          console.log(err)
          commit('notifications/SHOW_FAIL', null, { root: true })
        })
        .finally(() => resolve())
    });
  },

    /**
   * @param {Object} Vuex
   * @param {String} name - group name ;
   */
  removeGroup: ({ commit, state }, name) => {
    return new Promise((resolve) => {
      API
        .delete(state.url, { group_name: name })
        .then(res => {
          if (res.data.error_message) {
            commit('notifications/SHOW_FAIL', res.data.error_message, { root: true })
            return false
          }
          commit('notifications/SHOW_SUCCESS', 'Group removed!', { root: true })
        })
        .catch(err => {
          console.log(err)
          commit('notifications/SHOW_FAIL', null, { root: true })
        })
        .finally(() => resolve())
    });
  },

    /**
   * @param {Object} Vuex
   * @param {String} name - group name ;
   */
  getGroupByName: ({ commit, state }, name) => {
    return new Promise((resolve) => {
      API
        .getByName(state.url, name)
        .then(res => {
          if (res.data.error_message) {
            commit('notifications/SHOW_FAIL', res.data.error_message, { root: true })
            return false
          }
          const { data } = res.data;
          commit('UPDATE_GROUP_INFO', data);
          resolve(data);
        })
        .catch(err => {
          console.log(err)
          commit('notifications/SHOW_FAIL', null, { root: true })
        })
        .finally(() => resolve())
    });
  },

  /**
   * @param {Object} Vuex
   * @param {Object} entry - user info 
   * @param {String} entry.group_name 
   * @param {String} entry.channel - type of messanger
   * @param {String} entry.identity
   * @param {String} entry.ux_identity
   */
  addUserToGroup: ({ commit, state }, entry) => {
    return new Promise((resolve) => {
      API
        .postWithEntry(`${state.skillAuthUrl}/user`, entry)
        .then(res => {
          if (res.data.error_message) {
            commit('notifications/SHOW_FAIL', res.data.error_message, { root: true })
            return false
          }
          const { data } = res.data;
          commit('UPDATE_GROUP_INFO', data);
          commit('notifications/SHOW_SUCCESS', 'User was added to group!', { root: true })
        })
        .catch(err => {
          console.log(err)
          commit('notifications/SHOW_FAIL', null, { root: true })
        })
        .finally(() => resolve())
    });
  },

  /**
   * @param {Object} Vuex
   * @param {Object} entry - user info 
   * @param {String} entry.group_name 
   * @param {String} entry.channel - type of messanger
   * @param {String} entry.identity
   */
  removeUserFromGroup: ({ commit, state }, entry) => {
    return new Promise((resolve) => {
      API
        .delete(`${state.skillAuthUrl}/user`, entry)
        .then(res => {
          if (res.data.error_message) {
            commit('notifications/SHOW_FAIL', res.data.error_message, { root: true })
            return false
          }
          const { data } = res.data;
          commit('UPDATE_GROUP_INFO', data);
          commit('notifications/SHOW_SUCCESS', 'User was removed from group!', { root: true })
        })
        .catch(err => {
          console.log(err)
          commit('notifications/SHOW_FAIL', null, { root: true })
        })
        .finally(() => resolve())
    });
  },

  /**
   * @param {Object} Vuex
   * @param {Object} entry - user info 
   * @param {String} entry.group_name 
   * @param {String} entry.channel - type of messanger
   * @param {String} entry.identity
  */
  setGroupOwner: ({ commit, state }, entry) => {
    return new Promise((resolve) => {
      API
        .postWithEntry(`${state.skillAuthUrl}/owner`, entry)
        .then(res => {
          if (res.data.error_message) {
            commit('notifications/SHOW_FAIL', res.data.error_message, { root: true })
            return false
          }
          const { data } = res.data;
          commit('UPDATE_GROUP_INFO', data);
          commit('notifications/SHOW_SUCCESS', 'User was assigned as owner!', { root: true })
        })
        .catch(err => {
          console.log(err)
          commit('notifications/SHOW_FAIL', null, { root: true })
        })
        .finally(() => resolve())
    });
  },

  /**
   * @param {Object} Vuex
   * @param {Object} entry - user info 
   * @param {String} entry.group_name 
   * @param {String} entry.channel - type of messanger
   * @param {String} entry.identity
  */
  removeGroupOwner: ({ commit, state }, entry) => {
    return new Promise((resolve) => {
      API
        .delete(`${state.skillAuthUrl}/owner`, entry)
        .then(res => {
          if (res.data.error_message) {
            commit('notifications/SHOW_FAIL', res.data.error_message, { root: true })
            return false
          }
          const { data } = res.data;
          commit('UPDATE_GROUP_INFO', data);
          commit('notifications/SHOW_SUCCESS', 'Owner was demoted!', { root: true })
        })
        .catch(err => {
          console.log(err)
          commit('notifications/SHOW_FAIL', null, { root: true })
        })
        .finally(() => resolve())
    });
  },

      /**
   * @param {Object} Vuex
   * @param {Object} entry - user info 
   * @param {String} entry.group_name 
   * @param {String} entry.id - id of skill
  */
  attachUserGroupToSkill: ({ commit, state }, entry) => {
    return new Promise((resolve) => {
      API
        .postWithEntry(`${state.skillAuthUrl}/attach/skill`, entry)
        .then(res => {
          if (res.data.error_message) {
            commit('notifications/SHOW_FAIL', res.data.error_message, { root: true })
            return false
          }
          commit('notifications/SHOW_SUCCESS', 'Access group was attached to the skill!', { root: true })
        })
        .catch(err => {
          console.log(err)
          commit('notifications/SHOW_FAIL', null, { root: true })
        })
        .finally(() => resolve())
    });
  },

    /**
   * @param {Object} Vuex
   * @param {Object} entry - skill info 
   * @param {String} entry.id - id of skill
  */
  detachUserGroupFromSkill: ({ commit, state }, entry) => {
    return new Promise((resolve) => {
      API
        .delete(`${state.skillAuthUrl}/detach/skill`, entry)
        .then(res => {
          if (res.data.error_message) {
            commit('notifications/SHOW_FAIL', res.data.error_message, { root: true })
            return false
          }
          commit('notifications/SHOW_SUCCESS', 'Access group was detached from the skill!', { root: true })
        })
        .catch(err => {
          console.log(err)
          commit('notifications/SHOW_FAIL', null, { root: true })
        })
        .finally(() => resolve())
    });
  },

        /**
   * @param {Object} Vuex
   * @param {Object} entry - user info 
   * @param {String} entry.group_name 
   * @param {String} entry.id - id of bundle
  */
 attachUserGroupToBundle: ({ commit, state }, entry) => {
  return new Promise((resolve) => {
    API
      .postWithEntry(`${state.skillAuthUrl}/attach/bundle`, entry)
      .then(res => {
        if (res.data.error_message) {
          commit('notifications/SHOW_FAIL', res.data.error_message, { root: true })
          return false
        }
        commit('notifications/SHOW_SUCCESS', 'Access group was attached to the bundle!', { root: true })
      })
      .catch(err => {
        console.log(err)
        commit('notifications/SHOW_FAIL', null, { root: true })
      })
      .finally(() => resolve())
  });
},

    /**
   * @param {Object} Vuex
   * @param {Object} entry - skill info 
   * @param {String} entry.id - id of skill
  */
 detachhUserGroupFromBundle: ({ commit, state }, entry) => {
  return new Promise((resolve) => {
    API
      .delete(`${state.skillAuthUrl}/detach/bundle`, entry)
      .then(res => {
        if (res.data.error_message) {
          commit('notifications/SHOW_FAIL', res.data.error_message, { root: true })
          return false
        }
        commit('notifications/SHOW_SUCCESS', 'Access group was detached from the bundle!', { root: true })
      })
      .catch(err => {
        console.log(err)
        commit('notifications/SHOW_FAIL', null, { root: true })
      })
      .finally(() => resolve())
  });
},

};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
