<template>
  <div class="expansion-panel" :class="{ open: isWrapped }" :id="user.id">
    <div class="expansion-panel--header">
      <v-row class="align-center">
        <v-col cols="12" sm="12" md="10">
          <div class="row-body">
            <div class="d-flex">
              <v-tooltip top v-if="isTooLong">
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on"><span class="row-body--name">Name:</span> {{ userName | shortener }}</div>
                </template>
                <span>{{ userName }}</span>
              </v-tooltip>
              <div v-else><span class="row-body--name">Name:</span> {{ userName }}</div>
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="2" class="d-flex justify-end align-center">
          <v-btn 
            color="primary" 
            class="text-capitalize"
            :loading="fetchingProcess" 
            @click="toggle()"
            outlined 
            small
          >{{ isWrapped ? 'close' : 'info' }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <transition name="slide">
      <div class="expansion-panel--content" v-show="isWrapped">
        <div class="mt-5 mb-0">
          <div class="info-wrapped-panel">
            <v-text-field
              hide-details
              v-model="searchQuery"
              placeholder="Search by key"
              prepend-inner-icon="mdi-magnify"
              class="serach-json mb-2"
              solo
            ></v-text-field>
            <JsonTree
              :field-id="user.name"
              :editable-keys="editableKeys" 
              :json-data="user" 
              :filter-key="searchQuery"
            />
          </div>
        </div>
      </div>
    </transition>
  </div>
  
</template>

<script>
import JsonTree from "@/components/_Partials/JsonTree/JsonTree.vue";

export default {
  name: "UserProfile",
  components: {
    JsonTree,
  },

  filters: {
    shortener(value) {
      return value.length > 50 ? `${value.slice(0, 50)}...` : value;
    }
  },

  props: ["user"],
  
  data() {
    return {
      isWrapped: false,
      fetchingProcess: false,
      searchQuery: '',
      editableKeys: ['locale', 'country_code', 'single_locale', 'time_zone'],
    };
  },

  computed: {
    userName() {
      if (this.user.contact_info && this.user.contact_info.name) {
        return this.user.contact_info.name;
      }
      if (this.user.contact_info && this.user.contact_info.first_name) {
        return this.user.contact_info.first_name;
      }
      if (this.user.contact_info && this.user.contact_info.email) {
        return this.user.contact_info.email;
      }
      if (this.user.name) {
        return this.user.name;
      }
      return 'NoName';
    },

    isTooLong() {
      return this.userName && this.userName.length > 50;
    }
  },

  methods: {
    toggle() {
      this.isWrapped = !this.isWrapped;
    },
  },
};
</script>