<template>
  <div class="expansion-panel" :class="{ open: isWrapped }" :id="skillBundle.id">
    <div class="expansion-panel--header">
      <v-row>
        <v-col cols="12" sm="12" md="7">
          <div class="row-body">
            <span :class="['panel-label', isCurrentVersionActive ? 'success' : (isCurrentVersionChanged ? 'warning' : 'danger')]">
               {{ isCurrentVersionActive ? 'Registered' : (isCurrentVersionChanged ? 'Metadata changed' : 'Unregistered') }}
            </span>
            <span v-if="isCurrentVersionFailed" class="panel-label danger ml-2">
              failed
            </span>
            <div class="d-flex ">
              <span class="row-body--name">Name:</span> {{ skillBundle.skill_name }}
            </div>
          </div>
        </v-col>

        <v-col cols="12" sm="12" md="5" class="d-flex justify-end align-center">
          <div class="row-manage">
            <v-btn
              color="primary"
              class="text-capitalize mr-2"
              @click="openEvaluateSkillDialog"
              depressed
              small
              >Evaluate
            </v-btn>
            <v-btn
              color="primary"
              class="text-capitalize mr-2"
              @click="showInfo()"
              outlined
              small
              >{{ isWrapped ? "close" : "info" }}
            </v-btn>
            <v-btn
              color="error"
              class="theme--remove-btn"
              @click="openDeleteSkillDialog"
              depressed
              small
              ><v-icon>mdi-delete</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </div>
    <transition name="slide">
      <div class="expansion-panel--content" v-if="isWrapped">
        <v-col
          v-if="bundleVersions.length > 1"
          class="d-flex py-3 px-0"
          cols="12"
          sm="5"
        >
          <v-select
            v-model="chosenVersion"
            :items="bundleVersions"
            label="Choose bundle version"
            hide-details
            dense
            outlined
          ></v-select>
        </v-col>
        <div class="info-block mt-2">
          <h3 class="info-block-title">Details</h3>
          <div class="info-block-row">
            <div class="info-block-key">Id</div>
            <div class="info-block-value">{{ currentSkillVersion.id }}</div>
          </div>
          <div class="info-block-row">
            <div class="info-block-key">Queue</div>
            <div class="info-block-value">{{ currentSkillVersion.queue }}</div>
          </div>
          <div class="info-block-row">
            <div class="info-block-key">Setting size</div>
            <div class="info-block-value">{{ skillSettingSize }}</div>
          </div>
          <div class="info-block-row" v-if="currentSkillVersion.path">
            <div class="info-block-key">Bundle file</div>
            <div class="info-block-value">
              <link-download :path="currentSkillVersion.path" label="download" />
            </div>
          </div>
          <AccessGroup base="Bundle" :id="skillBundle.id" :group-name="accessGroupName" />

          <div class="info-block-row flex-column">
            <div>
              <v-btn
                color="primary"
                class="text-capitalize"
                @click="toggleMetadata()"
                outlined
                small
                >{{ isMetadataShowed ? "hide" : "show more" }}
              </v-btn>
            </div>
            <transition name="slide">
              <div class="metadata-block" v-if="isMetadataShowed">
                <JsonTree :json-data="currentSkillVersion.metadata" />
              </div>
            </transition>
          </div>
        </div>

        <!-- Failure info block -->
        <div class="info-block" v-show="isCurrentVersionFailed">
          <h3 class="info-block-title red--text">Failure information</h3>
          <div class="info-block-row" v-for="(failureItem, index) in failureList" :key="index">
            <div class="info-block-key">Exception text</div>
            <div class="info-block-value red--text">{{ failureItem }}</div>
          </div>
        </div>

        <!-- Deployment info block -->
        <div class="info-block" v-show="isDeployedFromGit || isDeployedFromFile || isDeployedFromS3">
          <h3 class="info-block-title">Deployment info</h3>
          <div class="info-block-row" v-if="isDeployedFromFile">
            <div class="info-block-key">Source type</div>
            <div class="info-block-value">{{ skillSource.type }}</div>
          </div>
          <template v-if="isDeployedFromGit">
            <div class="info-block-row">
              <div class="info-block-key">Source type</div>
              <div class="info-block-value">{{ skillSource.type }}</div>
            </div>
            <div class="info-block-row" v-if="skillSource.provider">
              <div class="info-block-key">Provider</div>
              <div class="info-block-value">{{ skillSource.provider }}</div>
            </div>
            <div class="info-block-row" v-if="skillSource.project_name">
              <div class="info-block-key">Project Name</div>
              <div class="info-block-value">{{ skillSource.project_name }}</div>
            </div>
            <div class="info-block-row">
              <div class="info-block-key">Relative path</div>
              <div class="info-block-value" >
                {{ skillSource.relative_path_to_file }}
              </div>
            </div>
            <div class="info-block-row">
              <div class="info-block-key">Repository name</div>
              <div class="info-block-value">
                {{ skillSource.repository_name }}
              </div>
            </div>
            <div class="info-block-row">
              <div class="info-block-key">Branch</div>
              <div class="info-block-value">{{ skillSource.branch }}</div>
            </div>
            <div class="info-block-row">
              <div class="info-block-key">Commit</div>
              <div class="info-block-value">{{ skillSource.commit }}</div>
            </div>
            <div class="info-block-row" v-if="skillSource.version">
              <div class="info-block-key">Version</div>
              <div class="info-block-value" v-if="skillSource.https_commit_link">
                <a :href="skillSource.https_commit_link" target="_blank">{{ skillSource.version }}</a>
              </div>
              <div class="info-block-value" v-else>{{ skillSource.version }}</div>
            </div>
          </template>
          <template v-if="isDeployedFromS3">
            <div class="info-block-row">
              <div class="info-block-key">Source type</div>
              <div class="info-block-value">{{ skillSource.type }}</div>
            </div>
            <div class="info-block-row">
              <div class="info-block-key">Path</div>
              <div class="info-block-value">{{ skillSource.path }}</div>
            </div>
          </template>
          <template v-if="currentSkillVersion.deployment_info">
            <div class="info-block-row" v-if="currentSkillVersion.deployment_info.email">
              <div class="info-block-key">Deployed by</div>
              <div class="info-block-value">{{ currentSkillVersion.deployment_info.email }}</div>
            </div>
            <div class="info-block-row" v-if="currentSkillVersion.deployment_info.timestamp">
              <div class="info-block-key">Deployment date</div>
              <div class="info-block-value">{{ currentSkillVersion.deployment_info.timestamp | timeFormat }}</div>
            </div>
          </template>
        </div>

        <!-- Registration info block -->
        <div class="info-block" v-if="currentSkillVersion.registration_info">
          <h3 class="info-block-title">Registration info</h3>
          <div class="info-block-row" v-if="currentSkillVersion.registration_info.email">
            <div class="info-block-key">Registered by</div>
            <div class="info-block-value">{{ currentSkillVersion.registration_info.email }}</div>
          </div>
          <div class="info-block-row" v-if="currentSkillVersion.registration_info.timestamp">
            <div class="info-block-key">Registration date</div>
            <div class="info-block-value">{{ currentSkillVersion.registration_info.timestamp | timeFormat }}</div>
          </div>
        </div>

        <!-- Intents block -->
        <div class="info-block" v-if="skillVersionIntents">
          <h3 class="info-block-title">Intents</h3>
          <div class="info-block-row" v-for="(value, index) in skillVersionIntents" :key="index">
            <div class="info-block-key">
              <router-link v-if="(isCurrentVersionActive || isCurrentVersionChanged) && value.platform_name"
                :to="{ name: 'chatbot-intents', query: { id: value.platform_name }}">
                {{ value.name }}
              </router-link>
              <span v-else>{{ value.name }}</span>
            </div>
          </div>
        </div>
        <div class="info-block mb-2">
          <v-btn
            color="primary"
            class="text-capitalize mr-2"
            :loading="processes.fetching"
            @click="updateSkill()"
            depressed
            outlined
            small
            >Refresh
          </v-btn>
          <v-btn
            v-if="isDeployedFromGit"
            color="primary"
            class="mr-2 text-capitalize"
            :loading="processes.redeploy"
            @click="redeploy()"
            depressed
            small
            >Redeploy
          </v-btn>
          <v-btn 
            v-if="isCurrentVersionActive"
            color="primary"
            class="text-capitalize mr-2"
            :loading="processes.registration"
            @click="unregister()"
            depressed
            small
            >Unregister
          </v-btn>
          <v-btn 
            v-else
            color="primary"
            class="text-capitalize mr-2"
            :loading="processes.registration"
            @click="register()"
            depressed
            small
            >{{ isCurrentVersionChanged ? 'Re-register' : 'Register' }}
          </v-btn>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from 'moment';
import linkDownload from '@/components/_Partials/linkDownload.vue';
import JsonTree from "@/components/_Partials/JsonTree/JsonTree.vue";
import AccessGroup from './AccessGroup.vue'

export default {
  name: "SkillBundle",
  
  components: {
    linkDownload,
    AccessGroup,
    JsonTree,
  },

  props: ["skillBundle"],

  filters: {
    timeFormat: (value) => {
      return moment.unix(value).format("YYYY-MM-DD, H:mm:ss");
    },
  },

  data() {
    return {
      chosenVersion: null,
      isWrapped: false,
      isMetadataShowed: false,
      processes: {
        fetching: false,
        registration: false,
        redeploy: false
      },
    };
  },

  computed: {
    currentSkillVersion() {
       if (typeof this.skillBundle === 'object' && this.skillBundle !== null) {
        return this.skillBundle;
      }
      const { bundle } = this.skillBundle;
     
      if (this.chosenVersion) {
        return bundle.find(skill => 
          skill.version === this.chosenVersion
        );
      }
      return bundle.find(skill => 
        skill.status === 'active' || 
        bundle[bundle.length - 1].version === skill.version
      );
    },

    bundleVersions() {
      const versionList = [];
      this.skillBundle.bundle && this.skillBundle.bundle.map(bundle => versionList.push(bundle.version))
      return versionList;
    },

    isCurrentVersionCandidate() {
      return this.currentSkillVersion.status === 'candidate';
    },

    isCurrentVersionDeployed() {
      return this.currentSkillVersion.status === 'deployed';
    },

    isCurrentVersionActive() {
      return this.currentSkillVersion.status === 'active';
    },

    isCurrentVersionChanged() {
      return this.currentSkillVersion.status === 'changed';
    },

    isCurrentVersionFailed() {
      return Boolean(this.currentSkillVersion.failure);
    },

    failureList () {
      return this.currentSkillVersion.failure;
    },

    skillVersionIntents() {
      if (this.currentSkillVersion.metadata.Intents) {
        return this.currentSkillVersion.metadata.Intents
      }
      return this.currentSkillVersion.metadata.Skill.Intents;
    },

    skillSource() {
      return this.currentSkillVersion && this.currentSkillVersion.source;
    },

    isDeployedFromFile() {
      return this.skillSource && this.skillSource.type === "file";
    },

    isDeployedFromGit() {
      return this.skillSource && this.skillSource.type === "git";
    },

    isDeployedFromS3() {
      return this.skillSource && this.skillSource.type === "s3";
    },

    skillSettingSize() {
      if (this.currentSkillVersion.metadata.Skill.settings && this.currentSkillVersion.metadata.Skill.settings.size) {
        return this.currentSkillVersion.metadata.Skill.settings.size;
      }
      return 'S';
    },
    accessGroupName() {
      if (this.currentSkillVersion.metadata.Skill.settings && this.currentSkillVersion.metadata.Skill.settings.authorization_group_name) {
        return this.currentSkillVersion.metadata.Skill.settings.authorization_group_name;
      }
      return null;
    },
  },

  methods: {
    ...mapActions("skillBundles", ["getSkillById", "activate", "deactivate", "deploySkillFromGit"]),

    openDeleteSkillDialog() {
      this.$root.$emit("openDeleteDialog", this.skillBundle.id);
    },

    openEvaluateSkillDialog() {
      this.$root.$emit("openSkillEvaluationDialog", this.skillBundle);
    },

    redeploy() {
      this.processes.redeploy = true;
      this.deploySkillFromGit(this.skillSource).then((stream) => {
        this.$router.push({name: "monitoring-service", query: { service: 'deployment', stream }});
        this.processes.redeploy = false;
      });
    },

    register() {
      this.processes.registration = true;
      this.$root.$emit("openAsyncLogsConsole");
      this.activate(this.skillBundle.id).then(() => {
        this.processes.registration = false;
      })
    },
    unregister() {
      this.processes.registration = true;
      this.$root.$emit("openAsyncLogsConsole");
      this.deactivate(this.skillBundle.id).then(() => {
        this.processes.registration = false;
      })
    },

    updateSkill() {
      this.processes.fetching = true;
      this.getSkillById(this.skillBundle.id).then(() => {
        this.processes.fetching = false;
      });
    },

    showInfo() {
      this.isWrapped = !this.isWrapped;
    },

    toggleMetadata() {
      this.isMetadataShowed = !this.isMetadataShowed;
    }
  }
}
</script>