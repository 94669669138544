import service from '@/plugins/axios';
import baseAPI from './base'

export default {
  ...baseAPI,
  /**
 * @param {String} url
 * @param {String} date report date
 * @returns {Array}
 */
  getReport(url, date) {
    return service.get(`report`, { params: { date } });
  },

  getReportForPeriod(url, date, period) {
    return service.get(`report`, { params: { date, period } });
  },

  getAnalyticsOverview(url, date, period) {
    return service.get(url, { params: { date, period } });
  },

    /**
   * @param {String} url
   * @param {String} data report data
   * @returns {Array}
   */
  getImage(path) {
    return service.get('url/presign', { params: { path } });
  },

  getUser(id) {
    return service.get(`report/user?user_id=${id}`);
  },

  /**
   * @param {String} url
   * @param {String} date report date
   * @returns {Array}
   */
  generateReports(dates) {
    return service.post(`report/generate`, dates);
  },

}
