<template>
  <span :class="{ 'red--text': isError }" v-html="answer"></span>
</template>
<script>
export default {
  name: "AnswerText",

  props: ["entityName","entity"],

  computed: {

    isError() {
      return this.entityName === "error";
    },

    answer() {
      const { text } = this.entity.payload;
      if (!text) {
        return this.entity.payload;
      }
      return text;
    }
  }
}
</script>