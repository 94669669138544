<template>
  <div class="expansion-panel" 
    v-if="!isHidden"
    :class="{ 
      open: isWrapped, 
      disciple: isDisciple,
      leader: isLead,
      ungrouped: isUngrouped,
    }"
  >
    <span class="group-indicator"></span>
    <div class="expansion-panel--header" @click="WrapHandling()">
      <v-row>
        <v-col cols="12" sm="12" md="2" class="report-col">
          <div class="row-header">Request time</div>
          <div class="row-content">
            {{ row.request_timestamp | timeFormat }}
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="4" class="report-col">
          <div class="row-header">Utterance</div>
          <div class="row-content">
            {{ row.utterance }}
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="3" class="report-col">
          <div class="row-header">Intent</div>
          <div class="row-content">
            <template v-if="row.intent_name">{{ row.intent_name }}</template>
            <template v-else><span class="red--text">Not detected</span></template>
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="2" class="report-col">
          <div v-if="row.status">
            <div class="row-header">Status</div>
            <div class="row-content">
              <span :class="[statuses[row.status].class]">
                {{ statuses[row.status].title }}
              </span>
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="1" class="report-col d-flex align-center justify-end">
          <div v-if="isLead" 
            class="grouped-requests" 
            @click.stop="WrapHandling(true)"
            :class="{'ungrouped-items': !isGrouped }"
          >
            <span v-for="n in 3" :key="n" class="group-item" :class="[`item-${n}`]"></span>
          </div>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <img v-bind="attrs" v-on="on" v-show="isOperatorReq" 
                class="mr-1 ml-3" 
                width="20" 
                src="@/assets/images/operator.svg" alt="operator icon"
              >
            </template>
            <span>Operator request</span>
          </v-tooltip>
          <v-icon v-if="!this.isWrapped">mdi-chevron-down</v-icon>
          <v-icon v-else>mdi-chevron-up</v-icon>
        </v-col>
      </v-row>
    </div>
    <transition name="slide">
      <div class="expansion-panel--content" v-if="isWrapped">
        <div class="mt-5 mb-0">
          <div class="info-wrapped-panel">

            <!-- Details block -->
            <div class="info-block">
              <h3 class="info-block-title">Details</h3>
              <div class="info-block-row" v-if="row.fulfilment_id">
                <div class="info-block-key">Fulfillment id</div>
                <div class="info-block-value">
                  {{ row.fulfilment_id }}
                </div>
              </div>
              <div class="info-block-row">
                <div class="info-block-key">Request id</div>
                <div class="info-block-value">
                  {{ row.request_id }}
                </div>
              </div>
              <div class="info-block-row">
                <div class="info-block-key">Session id</div>
                <div class="info-block-value">
                  {{ row.session_id }}
                </div>
              </div>
              <div class="info-block-row" v-if="row.user_id">
                <div class="info-block-key">User id</div>
                <div class="info-block-value">
                  {{ row.user_id }}
                </div>
              </div>
              <div class="info-block-row" v-if="row.impersonated_session_id">
                <div class="info-block-key">Impersonated session id</div>
                <div class="info-block-value">
                  {{ row.impersonated_session_id }}
                </div>
              </div>
              <div class="info-block-row">
                <div class="info-block-key">Activity type</div>
                <div class="info-block-value">
                  {{ row.activity_type }}
                </div>
              </div>
              <div class="info-block-row" v-if="row.deskription">
                <div class="info-block-key">Status deskription</div>
                <div class="info-block-value">
                  <span class="red--text">[{{row.status}}] {{ row.deskription }}</span>
                </div>
              </div>
              <div class="info-block-row" v-if="row.error">
                <div class="info-block-key">Error</div>
                <div class="info-block-value">
                  <span class="red--text">{{ row.error }}</span>
                </div>
              </div>
              <div class="info-block-row" v-if="row.intent_detector">
                <div class="info-block-key">Intent detector</div>
                <div class="info-block-value">
                  {{ row.intent_detector }}
                </div>
              </div>

              <RequestUserInfo v-if="row.user_id"
                :request-id="row.request_id" 
                :user-id="row.user_id"
                :user="row.user"
              />
            </div>

            
            <!-- Response time block -->
            <div class="info-block">
              <h3 class="info-block-title">Response time</h3>
              <div class="info-block-row">
                <div>
                  <time-request-chart :height="250" :width="320" :data="row.response_times" />
                </div>
                <div class="d-flex align-center">
                  <ul>
                    <li v-if="row.response_times.UserInputProcessed">
                      User input processing: {{ row.response_times.UserInputProcessed.toFixed(2) }}s
                    </li>
                    <li v-if="row.response_times.IntentDetected">
                      Intent detection: {{ row.response_times.IntentDetected.toFixed(2) }}s
                    </li>
                    <li v-if="row.response_times.SkillStarted">
                      Skill starting: {{ row.response_times.SkillStarted.toFixed(2) }}s
                    </li>
                    <li v-if="row.response_times.SkillCompleted">
                      Skill execution: {{ row.response_times.SkillCompleted.toFixed(2) }}s
                    </li>
                    <li v-if="row.response_times.SkillFromCache">
                      Skill execution from cache: {{ row.response_times.SkillFromCache.toFixed(2) }}s
                    </li>
                    <li v-if="row.response_times.RequestProcessingTime">
                      <b>Request processing time: {{ row.response_times.RequestProcessingTime.toFixed(2) }}s</b>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <!-- Parameters block -->
            <div class="info-block pb-0" v-if="areParametersExist">
              <h3 class="info-block-title">Parameters</h3>
              <div class="parameters-row">
                <div
                  v-for="param in row.parameters"
                  :key="param.name"
                  class="parameter-block mr-4"
                >
                  <div>
                    Name: <span class="info-block-value">{{ param.name }}</span>
                  </div>
                  <div>
                    Slot type:
                    <span class="info-block-value">{{ param.slot_type }}</span>
                  </div>
                  <div>
                    System type:
                    <span class="info-block-value">{{
                      param.system_type
                    }}</span>
                  </div>
                  Value:
                  <span class="info-block-value">
                    <template v-for="value in param.value">{{ value }}</template>
                  </span>
                </div>
              </div>
            </div>

            <!-- Results block -->
            <div class="info-block" v-if="row.result">
              <h3 class="info-block-title">Results</h3>
              <v-btn
                v-if="!isResultsShown"
                color="primary"
                class="text-capitalize"
                @click="isResultsShown = true"
                depressed
                outlined
                small
              >
                Show Results
              </v-btn>
              <transition name="slide">
                <div v-if="isResultsShown" class="results-wrapper">
                  <div class="info-block-row" v-if="row.result.status">
                    <div class="info-block-key">Status</div>
                    <div class="info-block-value">
                      <span :class="[`status-${row.result.status.toLowerCase()}`]">
                        {{ row.result.status }}
                      </span>
                    </div>
                  </div>
                  <div v-for="(resultEnities, key, index) in row.result" :key="index">
                    <div v-if="Array.isArray(resultEnities) && resultEnities.length" class="mb-4">
                      <RequestEntity v-for="(entity, index) in resultEnities"
                        :key="`result_${key}_${index}`"
                        :entity-name="key"
                        :entity="entity"
                      />
                    </div>
                  </div>
                  <div class="info-block-row" v-if="row.result.notebook">
                    <div class="info-block-key">Notebook</div>
                    <div class="info-block-value">
                      <link-download :path="row.result.notebook" />
                    </div>
                  </div>
                  <div class="info-block-row" v-if="row.result.service_error">
                    <div class="info-block-key">Service Error</div>
                    <div class="info-block-value">
                      <span class="status-failure">
                        {{ row.result.service_error }}
                      </span>
                    </div>
                  </div>
                </div>
              </transition>
            </div>

            <!-- Dialog block -->
            <div class="info-block" v-if="row.json_dialogs">
              <h3 class="info-block-title">Dialog</h3>
              <div v-for="(dialog, index) in row.json_dialogs" :key="index" class="mb-4">
                <!-- todo #refactor outputs -->
                <template v-if="dialog[0].local">
                   <RequestEntity v-for="(local, index) in dialog[0].local"
                    entity-name="local"
                    :key="`dialog_local_${index}`"
                    :entity="local"
                  />
                </template>
                <template v-if="dialog[0].answer">
                   <RequestEntity v-for="(answer, index) in dialog[0].answer"
                    entity-name="answer"
                    :key="`dialog_answer_${index}`"
                    :entity="answer"
                  />
                </template>
              </div>
            </div>

          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import moment from "moment";
import TimeRequestChart from "./TimeRequestChart.js";
import RequestEntity from "./RequestEntity.vue";
import linkDownload from '@/components/_Partials/linkDownload.vue'
import RequestUserInfo from "./RequestUserInfo.vue"
export default {
  name: "ReportRow",

  components: {
    RequestEntity,
    TimeRequestChart,
    linkDownload,
    RequestUserInfo,
  },

  filters: {
    timeFormat: (value) => {
      return moment(value.split('.')[0]).format("YYYY-MM-DD, H:mm:ss");
    },
  },

  props: {
    row: {
      type: Object,
      required: true
    },

    inGroupe: {
      type: Boolean,
      default: false
    },

    isLead: {
      type: Boolean,
      default: false
    },

    isDisciple: {
      type: Boolean,
      default: false
    },

    isGrouped: {
      type: Boolean,
      default: true,
    },

    operators: {
      default: null
    }
  },

  data() {
    return {
      statuses: {
        SUCCESS: { title: 'Success', class: 'green--text' },
        FAILURE: { title: 'Failure', class: 'red--text' },
        NOT_DETECTED: { title: 'Not Detected', class: 'red--text' },
        SYSTEM_ERROR: { title: 'System Error', class: 'red--text' },
        UNAUTHORIZED: { title: 'Unauthorized', class: 'red--text' },
        ELICITATION: { title: 'Elicitation', class: 'green--text' },
        ELICITATION_FAILED: { title: 'Elicitation Failed', class: 'red--text' },
      },
      isWrapped: false,
      isResultsShown: false,
    };
  },

  computed: {
    areParametersExist() {
      const { parameters } = this.row;
      return parameters && parameters.constructor === Object &&
        Object.keys(parameters).length !== 0;
    },

    isHidden() {
      return this.inGroupe && this.isGrouped && !this.isLead;
    }, 

    isUngrouped() {
      if (!this.inGroupe) return false;
      return !this.isGrouped;
    },

    isOperatorReq() {
      return this.operators && this.operators.some(oper => oper.user_id === this.row.user_id);
    }
  },

  methods: {
    toggle() {
      this.isWrapped = !this.isWrapped;
    },

    toggleGroup() {
      this.$emit('toggleGroup');
    },

    WrapHandling(isUngrouped) {
      if ((this.inGroupe && this.isGrouped) || isUngrouped) {
        this.toggleGroup();
        return false;
      }
      this.toggle();
    }
  },

};
</script>