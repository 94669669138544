<template>
  <div class="expansion-panel" :class="{ open: isWrapped }" :id="slotItem.id">
    <div class="expansion-panel--header">
      <div class="name-block">{{ slotItem.name }}</div>
      <div class="manage-block">
        <v-btn 
          color="primary" 
          class="text-capitalize"
          :loading="fetchingProcess" 
          @click="showInfo()"
          outlined 
          small
        >{{ isWrapped ? 'close' : 'info' }}
        </v-btn>
        <v-btn
          color="error"
          class="theme--remove-btn ml-3"
          @click="deleteSlot()"
          depressed
          small
        ><v-icon>mdi-delete</v-icon>
        </v-btn>
      </div>
    </div>
    <transition name="slide">
      <div class="expansion-panel--content" v-show="isWrapped">
        <div class="mt-5 mb-0" v-show="slotItem.info">
          <div class="info-wrapped-panel" v-if="slotItem.info">
            <div class="info-block">
              <div class="info-block-row">
                <div class="info-block-key">Name</div>
                <div class="info-block-value">{{ slotItem.info.name }}</div>
              </div>
              <div class="info-block-row">
                <div class="info-block-key">Nature</div>
                <div class="info-block-value">{{ slotItem.info.nature }}</div>
              </div>
              <div class="info-block-row">
                <div class="info-block-key">System type</div>
                <div class="info-block-value">{{ slotItem.info.system_type }}</div>
              </div>
            </div>
            <div class="info-block">
              <h3 class="info-block-title">Clarifications</h3>
              <div class="info-block-list">
                <p
                  class="info-block-list-item"
                  v-for="(clarification, index) in slotItem.info.clarifications"
                  :key="index"
                >
                  {{ clarification }}
                </p>
              </div>
            </div>
            <SlotExamples
              v-if="slotItem.info.examples && slotItem.info.examples.length"
              :examples="slotItem.info.examples"
              :synonyms="slotItem.synonyms"
              :translations="slotItem.translations"
            />
            <div class="info-block" v-else>
              <h3 class="info-block-title">Mappings</h3>
              <div class="info-block-list">
                <p
                  class="info-block-list-item"
                  v-for="(slot, index) in slotItem.info.mapping"
                  :key="index"
                >
                <router-link
                  :to="{
                    name: 'chatbot-slots',
                    query: { id: slot },
                  }"
                  >{{ slot }}
                </router-link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
  
</template>

<script>
import { mapActions } from "vuex";
import SlotExamples from "./SlotExamples.vue";
import { has } from '@/utils'

export default {
  name: "SlotItem",
  components: {
    SlotExamples,
  },

  props: ["slotItem"],
  
  data() {
    return {
      isWrapped: false,
      fetchingProcess: false,
      menu: false,
    };
  },

  mounted() {
    this.$route.query.id === this.slotId && this.reveal()
  },

  computed: {
    queryId() {
      return this.$route.query.id;
    },

    slotId() {
      return this.slotItem.id;
    }
  },

  methods: {
    ...mapActions("slots", {
      getSlotById: "getSlotById",
    }),

    reveal() {
      this.$vuetify.goTo(`#${this.slotId}`);
      this.showInfo();
    },

    toggle() {
      this.isWrapped = !this.isWrapped;
    },

    deleteSlot() {
      this.$root.$emit("openDeleteDialog", this.slotId);
    },

    showInfo() {
      if (!has.call(this.slotItem, "info")) {
        this.fetchingProcess = true;
        this.getSlotById(this.slotId).then(() => {
          this.fetchingProcess = false;
        });
      }
      this.toggle();
    },
  },

  watch: {
    queryId() {
      this.$route.query.id === this.slotId && this.reveal()
    }
  }
};
</script>