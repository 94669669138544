
import baseStore from '@/store/modules/_base';
import API from '@/services/api/product-analytics';

const state = {
  ...baseStore.state,
  url: 'analytics',
  weeklyOverviewReport: null,
  monthlyOverviewReport: null,
  dateLastWeeklyAnalytics: null,
  dateLastMonthlyAnalytics: null,
};

const getters = {
  ...baseStore.getters,
  weeklyOverviewReport: state => state.weeklyOverviewReport,
  monthlyOverviewReport: state => state.monthlyOverviewReport,
  dateLastWeeklyAnalytics: state => state.dateLastWeeklyAnalytics,
  dateLastMonthlyAnalytics: state => state.dateLastMonthlyAnalytics,
};

const mutations = {
  ...baseStore.mutations,

  SET_ENTITY: (state, { data, period }) => {
    if (period === 'month') {
      state.monthlyOverviewReport = data;
      return false;
    }
    state.weeklyOverviewReport = data;
  },

  SET_LAST_ANALYTICS_DATE: (state, { date, period }) => {
    if (period === 'month') {
      state.dateLastMonthlyAnalytics = date;
      return false;
    }
    state.dateLastWeeklyAnalytics = date;
  },

  CLEAR_LISTS: (state) => {
    state.weeklyOverviewReport = null;
    state.monthlyOverviewReport = null;
    state.dateLastWeeklyAnalytics = null;
    state.dateLastMonthlyAnalytics = null;
  },
};


const actions = {
  ...baseStore.actions,

  getAllEntities: ({ state, commit }, { date, period }) => {
    return new Promise((resolve) => {
      API
      .getAnalyticsOverview(state.url, date, period)
      .then(res => {
        if (!res.data.data.url) {
          commit('notifications/SHOW_FAIL', `Presigned URL hasn't been gotten for ${date}`, { root: true })
          return false;
        }
        fetch(res.data.data.url).then(res => res.json())
        .then(data => {
          commit("SET_ENTITY", { data, period });
          commit("SET_LAST_ANALYTICS_DATE", { date, period });
        })
        .finally(() => {
          resolve()
        })
      })
      .catch(err => {
        console.log(err)
        commit('notifications/SHOW_FAIL', "No analytics for this period.", { root: true })
      })
    })
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}