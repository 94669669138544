
import Vue from 'vue';

import API from '@/services/api/base';
import baseStore from '@/store/modules/_base';


const state = {
  ...baseStore.state,
  url: 'profile',
};

const getters = {
  ...baseStore.getters,
};

const mutations = {
  ...baseStore.mutations,

  UPDATE_PROFILE_INFO: (state, { data, name }) => {
    state.entity.map((user, index) => {
      if (user.name === name) {
        Vue.set(state.entity, index, data);
      }
    })
  },

};

const actions = {
  ...baseStore.actions,
   /**
    * @param {Object} Vuex
    */
   getAllEntities: ({ commit, state }) => {
    return new Promise((resolve) => {
      API
        .getInitialEntities(state.url)
        .then(res => {
          resolve();
          if (res.data.error_message) {
            commit('notifications/SHOW_FAIL', res.data.error_message, { root: true })
            return false
          }
          commit('SET_ENTITY', res.data.data);
        })
        .catch(err => {
          console.log(err)
          commit('notifications/SHOW_FAIL', null, { root: true })
        });
    });
  },

  /**
   * @param {Object} Vuex
   * @param {Number} id entityId;
   */
  getProfileById: ({ commit, state }, id) => {
    return new Promise((resolve) => {
      API
        .getById(state.url, encodeURIComponent(id))
        .then(res => {
          if (res.data.error_message) {
            commit('notifications/SHOW_FAIL', res.data.error_message, { root: true })
            return false
          }
          commit('SET_PROFILE_INFO', { data: res.data.data, id})
        })
        .catch(err => {
          console.log(err)
          commit('notifications/SHOW_FAIL', null, { root: true })
        })
        .finally(() => resolve())
    });
  },

  setNewValue: ({ commit, state }, {entry, name}) => {
    return new Promise((resolve) => {
      API
        .postWithEntry(`${state.url}/update`, JSON.stringify(entry))
        .then(res => {
          if (res.data.error_message) {
            commit('notifications/SHOW_FAIL', res.data.error_message, { root: true })
            return false
          }
          const { data } = res.data;
          commit('UPDATE_PROFILE_INFO', { data, name })
        })
        .catch(err => {
          console.log(err)
          commit('notifications/SHOW_FAIL', null, { root: true })
        })
        .finally(() => resolve())
    });
  },





};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
