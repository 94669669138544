<template>
  <div class="empty-block" v-if="!list.length">
    <div class="empty-block--msg">{{ currentMsg }}</div>
    <div class="empty-block--reset" @click="resetSearch()" v-if="search">
      Reset search terms!
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      required: true
    },

    search: {
      type: String,
      default: ''
    },

    initialEmptyMsg: {
      type: String,
      default: 'There are no entities yet!'
    },
    
    searchEmptyMsg: {
      type: String,
      default: "Your search didn't match any entities!"
    },
  },

  computed: {
    currentMsg() {
      return this.search ? this.searchEmptyMsg : this.initialEmptyMsg;
    }
  },

  methods: {
    resetSearch () {
      this.$emit('reset')
    }
  }
}
</script>