<template>
  <div class="bo-app" id="app">
    <BuildInfo />
    <ThemeWrapper>
      <router-view/>
    </ThemeWrapper>
  </div>
</template>

<script>
import ThemeWrapper from '@/components/ThemeWrapper.vue'
import BuildInfo from '@/components/_Partials/BuildInfoBlock.vue'
import auth from '@/mixins/_auth'

export default {
  components: {
    ThemeWrapper,
    BuildInfo,
  },
  mixins: [auth],
}
</script>

