<template>
  <div class="page-container slots-container">
    <v-row>
      <v-col cols="12" sm="5" class="d-flex align-center">
        <h2 class="hovered-el" @click="refreshList">
          Chatbot Slots
          <v-icon color="primary" class="hidden-icon pb-1">mdi-reload</v-icon>
        </h2>
      </v-col>
      <v-col cols="12" sm="7">
        <v-text-field
          hide-details
          v-model="searchParam"
          placeholder="Search"
          prepend-inner-icon="mdi-magnify"
          solo
        ></v-text-field>
      </v-col>
    </v-row>
    <div class="expansion-panels-group mt-3" v-if="filteredListPerPage">
      <SlotItem v-for="slot in filteredListPerPage" :key="slot.id" :slot-item="slot" />
      <v-pagination
        v-show="isShowPagination"
        v-model="page"
        total-visible="7"
        :length="paginationLength"
      ></v-pagination>
      <EmptyBlockStub :list="filteredList" :search="searchParam" @reset="resetSearch()" />
    </div>
    <div v-else class="mt-3 loader-block">
      <v-progress-circular :width="3" size="60" color="primary" indeterminate></v-progress-circular>
    </div>
    <DeleteDialog :store="store" />
  </div>
</template>

<script>

import SlotItem from "@/components/Chatbot/Slots/Slot.vue";
import EmptyBlockStub from "@/components/_Partials/EmptyBlockStub.vue";
import DeleteDialog from "@/components/Dialogs/DeleteDialog.vue";
import baseEntity from "@/mixins/_baseEntity";

export default {
  name: "Slots",
  mixins: [baseEntity],
  components: {
    SlotItem,
    EmptyBlockStub,
    DeleteDialog,
  },

  data() {
    return {
      store: "slots",
    };
  },
};
</script>