<template>
  <div>
    <div v-text="entity.payload.description"></div>
    <div>File: <link-download :label="fileName" :path="entity.payload.path" /></div>
    <div>File size: <span>{{ entity.payload.size | bytesToSize }}</span></div>
  </div>
</template>
<script>
import LinkDownload from '@/components/_Partials/linkDownload.vue'
export default {
  name: "AnswerCsv",

  props: ["entity"],

  components: {
    LinkDownload,
  },

  computed: {
    fileName() {
      const { payload } = this.entity;
      if (payload && payload.filename) {
        return payload.filename;
      }
      return 'Undefined';
    }
  },

  filters: {
    bytesToSize(bytes) {
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      if (bytes == 0) return '0 Byte';
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    }, 
  },
}
</script>