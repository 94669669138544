<template>
  <div class="repository-panel">
    <div class="repository-panel--header">
      <div class="d-flex flex-column">
        <span class='panel-label success'>
          {{ repository.provider }}
        </span>
        <a
          target="_blank"
          :href="repository.https_link"
          >{{ repository.repository_name }}</a
        >
      </div>
      <div>
        <v-btn
          color="primary"
          class="text-capitalize"
          :loading="updateProcess"
          @click="updateRepository()"
          depressed
          small
          >Update
        </v-btn>
        <v-btn
          color="error"
          class="theme--remove-btn ml-3"
          :loading="deleteProcess"
          @click="deleteRepository()"
          depressed
          small
        ><v-icon>mdi-delete</v-icon>
        </v-btn>
      </div>
    </div>
    <div class="repository-panel--body">
       <v-expansion-panels  flat v-if="repository.branches.length > 3">
          <v-expansion-panel>
            <v-expansion-panel-header class="px-0">Branches: ...</v-expansion-panel-header>
            <v-expansion-panel-content>
              <ul class="pl-0">
                <li v-for="branch in repository.branches" :key="branch">{{ branch }}</li>
              </ul>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <template v-else>
          <div>Branches:</div>
          <ul>
            <li v-for="branch in repository.branches" :key="branch">{{ branch }}</li>
          </ul>
        </template>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Repository",
  
  props: ["repository"],

  data() {
    return {
      updateProcess: false,
      deleteProcess: false,
    };
  },

  methods: {
    ...mapActions("repositories", ["update", "delete"]),

    updateRepository() {
      const { repository_name, account_name, provider } = this.repository;
      this.updateProcess = true;
      this.update({ repository: repository_name, account: account_name, provider }).then(() => {
        this.updateProcess = false;
      })
    },

    deleteRepository() {
      const { repository_name, provider } = this.repository;
      this.deleteProcess = true;
      this.delete({ repository: repository_name, provider }).then(() => {
        this.deleteProcess = false;
      })
    },
  },
};
</script>
