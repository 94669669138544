import axios from 'axios';
import { Auth } from 'aws-amplify';
import { getBaseUrl, isAzure, getCloudConfigs } from '@/api-configs';
import { PublicClientApplication } from '@azure/msal-browser';
import authStore from "@/store/modules/_auth.js";
import router from '@/router';

const service = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
});

service.interceptors.request.use(async (config) => {
  config.baseURL = await getBaseUrl();

  if (await isAzure()) {
    const conf = await getCloudConfigs();
    const msalInstance = new PublicClientApplication(conf);
    const accounts = await msalInstance.getAllAccounts();
    if (accounts.length == 0) {
      return false;
    }
    const account = accounts[0];
    
    const accessTokenRequest = {
      scopes: ["https://graph.microsoft.com/openid", "openid"],
      account
    }
    let tokenResponse = null;
    try {
      tokenResponse = await msalInstance.acquireTokenSilent(accessTokenRequest);
    }
    catch(error){
      console.error("authorization tocken expired!");
      authStore.state.signedIn = false;
      authStore.state.user = null;
      router.push({path:"/"});
    }
    if (tokenResponse) {
      config.headers.authorization = `${tokenResponse.tokenType} ${tokenResponse.idToken}`;
    }
    return config;
  }

  const user = await Auth.currentAuthenticatedUser();
  const token = user.getSignInUserSession()
    .getIdToken()
    .getJwtToken();


  config.headers.authorization = token ? token : '';
  return config;
});

export default service;