<template>
  <v-dialog v-model="isOpen" persistent max-width="460px">
    <v-card>
      <v-card-title>
        <h4 class="font-weight-regular text-center">
          Adding a new user to the <b>{{ name }}</b>
        </h4>
      </v-card-title>
      <v-card-actions>
        <div class="w-100">
          <v-card-text class="px-0 py-0">Enter user channel</v-card-text>
          <v-select 
            v-model="channel" 
            :loading="!integrationsList" 
            :items="integrationNames" 
            label="Select channel" 
            hide-details 
            outlined
            dense>
          </v-select>
          <br>
          <v-card-text class="px-0 py-0">Enter user identity</v-card-text>
          <v-text-field hide-details label="Identity" v-model="identity" dense outlined></v-text-field>
        </div>
      </v-card-actions>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn 
          color="primary" 
          :disabled="!channel || !identity"
          :loading="isLoading" 
          @click="addNewUser()"
        >Save</v-btn>
        <v-btn color="grey darken-4" text @click="$emit('close')">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: 'AddUserDialog',

  props: ['isOpen', 'name'],

  data() {
    return {
      isLoading: false,
      channel: '',
      identity: '',
    }
  },
  
  computed: {
    ...mapGetters({
      integrationsList: "integrations/entityList",
    }),

    integrations() {
      return this.integrationsList ? this.integrationsList : [];
    },

    integrationNames() {
      return this.integrations.map(interg => interg.integration_type)
    },
  },

  methods: {
    ...mapActions("groups", ["addUserToGroup"]),
    ...mapActions("integrations", { getIntegrations: "getAllEntities" }),

    addNewUser() {
      this.isLoading = true;
      this.addUserToGroup({
        group_name: this.name, 
        channel: this.channel,
        identity: this.identity
      }).then(() => {
        this.isLoading = false;
        this.clearTemp();
        this.$emit('close');
      })
    },

    clearTemp() {
      this.channel = '';
      this.identity = '';
    }
  },

  watch: {
    isOpen() {
      if (!this.integrationsList) {
        this.getIntegrations();
      }
    }
  }
    
}
</script>
