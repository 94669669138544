import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store/index'
import Amplify, * as AmplifyModules from 'aws-amplify';
import { getCloudConfigs, isAWS } from '@/api-configs';
import { AmplifyPlugin } from 'aws-amplify-vue';
import vuetify from '@/plugins/vuetify';
import '@/assets/scss/app.scss'

(async () => {
  if (await isAWS) {
    Amplify.configure(await getCloudConfigs());
    Vue.use(AmplifyPlugin, AmplifyModules);
  }

  Vue.config.productionTip = false
  
  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
  
})();

