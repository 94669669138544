<template>
  <div class="d-flex pt-6">
    <v-card min-width="236" class="total-statistics--panel mr-6">
      <h2 class="statistics-title">Total Delivered Intents</h2>
      <div v-if="data">
        <h1>{{ Number(data.total_delivered_intents).toLocaleString() }}</h1>
        <div> 
          <span v-if="data.growth_intents" :class="[(data.growth_intents >= 1) ? 'green--text' : 'red--text']">
            {{ data.growth_intents | percentageHandler }}
          </span> 
          <span v-else class="red--text">N/A</span>
          {{ isPeriodWeek ? 'WoW' : 'MoM' }} 
        </div>
      </div>
      <v-progress-circular v-else :width="3" size="80" color="primary" indeterminate></v-progress-circular>
    </v-card>

    <v-card min-width="236" class="total-statistics--panel">
      <h2 class="statistics-title">Total Active Users</h2>
      <div v-if="data">
        <h1>{{ Number(data.total_active_users).toLocaleString() }}</h1>
        <div> 
          <span v-if="data.growth_users" :class="[(data.growth_users >= 1) ? 'green--text' : 'red--text']">
            {{ data.growth_users | percentageHandler }}
          </span> 
          <span v-else class="red--text">N/A</span>
         {{ isPeriodWeek ? 'WoW' : 'MoM' }} </div>
      </div>
      <v-progress-circular v-else :width="3" size="80" color="primary" indeterminate></v-progress-circular>
    </v-card>
  </div>
</template>

<script>

export default {
  name: "TotalStatistics",

  filters: {
    percentageHandler(num) {
      if (num >= 1) {
        let positiveNum = Number((num - 1) * 100)
        return `+${(Math.round((positiveNum + Number.EPSILON) * 10) / 10).toLocaleString()}%`;
      } else {
        let negativeNum = Number((1 - num) * 100)
        return `-${(Math.round((negativeNum + Number.EPSILON) * 10) / 10).toLocaleString()}%`;
      }
    },
  },

  props: {
    data: {
      default: null,
      type: Object
    },
    period: {
      default: 'month',
      type: String
    }
  },

  computed: {
    isPeriodWeek() {
      return this.period === 'week';
    },
  }
};
</script>
