<template>
  <div class="service-block">
    <div v-if="!focusedSkill">
      <h2 class="hovered-el" @click="refreshList">
        Skill Monitoring
        <v-icon color="primary" class="hidden-icon pb-1">mdi-reload</v-icon>
      </h2>
      <div class="expansion-panels-group mt-5" v-if="filteredListPerPage">
        <v-list-item v-for="skill in filteredListPerPage" :key="skill.id" class="pl-0">
          <v-list-item-content>
            <v-list-item-title>
              <span class="stream-name" @click="chooseSkill(skill.id)"
                >skill/{{ skill.id }}</span
              >
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-pagination
          v-show="isShowPagination"
          v-model="page"
          total-visible="7"
          :length="paginationLength"
        ></v-pagination>
      </div>
      <div v-else class="mt-3 loader-block">
        <v-progress-circular
          :width="3"
          size="60"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
    </div>

    <div v-if="focusedSkill && !focusedStream">
      <h3 class="pb-4">
        <v-btn class="mr-2" fab small color="primary" @click="backToSkills()">
          <v-icon dark> mdi-chevron-left </v-icon>
        </v-btn>
        Skill {{ focusedSkill }}
      </h3>
      <div v-if="streams">
        <v-list-item v-for="stream in streams" :key="stream.id">
          <v-list-item-content>
            <v-list-item-title>
              <span class="stream-name" @click="choseStream(stream.id)"
                >skill/{{ focusedSkill }}/{{ stream.id }}</span
              >
            </v-list-item-title>
            <v-list-item-subtitle v-if="stream.timestamp">
              {{ stream.timestamp | timeFormat }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <EmptyBlockStub :list="streams" initial-empty-msg="There are no skills yet!" />
      </div>
      <div v-else class="mt-3 loader-block">
        <v-progress-circular
          :width="3"
          size="60"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
    </div>

    <div v-if="focusedStream">
      <h3 class="pb-4">
        <v-btn class="mr-2" fab small color="primary" @click="backToStreams()">
          <v-icon dark> mdi-chevron-left </v-icon>
        </v-btn>
        Stream {{ focusedStream }}
      </h3>
      <v-card class="px-6 py-4">
        <Logs :stream="focusedStream" />
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import Logs from "@/components/Monitoring/Partials/Logs.vue";

import baseEntity from "@/mixins/_baseEntity";
import monitoring from "@/mixins/_monitoring";
export default {
  name: "SkillsMonitoring",
  mixins: [baseEntity, monitoring],

  components: {
    Logs,
  },

  data() {
    return {
      focusedSkill: null,
      store: 'skillBundles'
    };
  },

  mounted() {
    if (this.$route.query.skill && this.$route.query.stream) {
      this.chooseSkill(this.$route.query.skill);
      this.choseStream(this.$route.query.stream);
    }
  },

  computed: {
    ...mapGetters({
      streams: "monitoring/skillStreams",
    }),

    isShowPagination() {
      return this.entityList && (this.entityList.length > this.entityPerPage);
    },

    paginationLength() {
      return Math.ceil(this.entityList.length / this.entityPerPage);
    },
  },

  methods: {
    ...mapActions({
      getStreams: "monitoring/getStreams",
    }),

    ...mapMutations({
      setType: "monitoring/SET_STREAMS_TYPE",
      clearStreams: "monitoring/CLEAR_SKILL_STREAMS",
    }),

    backToSkills() {
      this.clearStreams();
      this.focusedSkill = null;
    },

    chooseSkill(id) {
      this.focusedSkill = id;
      this.setType(this.focusedSkill);
      this.getStreams();
    },
  },
};
</script>
