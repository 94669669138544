import API from '@/services/api/monitoring';
import baseStore from '@/store/modules/_base'
import { BlindObserver, has } from '@/utils'

const state = {
  ...baseStore.state,
  url: 'logs',
  streams: {
    deployment: null,
    dialog: null,
    rendering: null,
    parser: null,
    redis: null,
    facebook_duckling: null,
    'cfn-service': null,
  },
  type: 'deployment',
  nextBackwardToken: '',
  nextForwardToken: '',
  skillStreams: null,
  events: null
};

const getters = {
  ...baseStore.getters,

  streams: store => {
    return store.streams[store.type];
  },

  skillStreams: store => {
    return store.skillStreams;
  },

  events: store => {
    return store.events;
  },

};

const mutations = {
  ...baseStore.mutations,
  SET_STREAMS_TYPE: (state, type) => {
    if (state.type !== type)
      state.type = type;
  },

  SET_STREAMS: (state, streams) => {
    if (!streams) return false;
    streams.__ob__ = new BlindObserver({});
    if (!has.call(state.streams, state.type)) {
      state.skillStreams = streams;
      return false;
    }
    state.streams[state.type] = streams;
  },

  SET_TOKENS: (state, tokens) => {
    if (tokens && (tokens.nextBackwardToken || tokens.nextForwardToken)) {
      const { nextBackwardToken, nextForwardToken } = tokens;
      nextBackwardToken && (state.nextBackwardToken = nextBackwardToken);
      nextForwardToken && (state.nextForwardToken = nextForwardToken);
      return false;
    }
    state.nextBackwardToken = '';
    state.nextForwardToken = '';
  },

  SET_EVENTS: (state, { events, before, after }) => {
    !state.events && (state.events = []);
    events.__ob__ = new BlindObserver({});
    if (before) {
      state.events.unshift(...events);
      return false;
    }
    if (after) {
      state.events.push(...events);
      return false;
    }

    state.events = events;
  },

  CLEAR_STREAM_INFO: (state) => {
    state.events = null;
    state.nextBackwardToken = '';
    state.nextForwardToken = '';
  },

  CLEAR_SKILL_STREAMS: (state) => {
    state.skillStreams = null;
  },

  CLEAR_SERVICE_STREAMS: (state) => {
    state.streams[state.type] = null;
  },

};

const actions = {
  ...baseStore.actions,
  getStreams: ({ commit, state }) => {
    return new Promise((resolve) => {
      API
        .getStreams(state.url, { service: state.type })
        .then(result => {
          if (result.data.error_message) {
            commit('notifications/SHOW_FAIL', result.data.error_message, { root: true })
            return false;
          }
          commit('SET_STREAMS', result.data.data)
          resolve();
        })
        .catch(err => {
          console.log(err)
          commit('notifications/SHOW_FAIL', null, { root: true })
        })
    })
  },

  getEventsByStream: ({ commit, state }, { container_id, before = false, after = false }) => {
    if (!before && !after) commit('CLEAR_STREAM_INFO')

    const token = (before && state.nextBackwardToken) ? state.nextBackwardToken : (after && state.nextForwardToken) ? state.nextForwardToken : null;

    return new Promise((resolve) => {
      API
      .getEvents(state.url,
        {
          service: state.type,
          container_id,
          token
        }
      )
      .then(result => {
        if (result.data.error_message) {
          commit('notifications/SHOW_FAIL', result.data.error_message, { root: true })
          return false;
        }
        commit('SET_TOKENS', result.data.data.tokens);
        commit('SET_EVENTS', { events: result.data.data.events, before, after })
        resolve()
      })
      .catch(err => {
        console.log(err)
        commit('notifications/SHOW_FAIL', null, { root: true })
      })
    });
  },

};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}