<template>
  <div class="page-container usage-overview-container">
    <v-row>
      <v-col cols="12" sm="5" class="d-flex align-center">
        <h2 class="hovered-el" @click="refreshList">
          Usage Overview
          <v-icon color="primary" class="hidden-icon pb-1">mdi-reload</v-icon>
        </h2>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col cols="12" sm="5" class="d-flex align-center">
        <v-select
          v-model="usageOverviewRange"
          @change="getReportsByPeriod()"
          :items="[{period: 'month', value: 'Last Month'}, {period: 'week', value: 'Last Week'}]"
          item-text="value"
          item-value="period"
          label="Usage Overview Range"
          hide-details
          solo
        ></v-select>
      </v-col>
    </v-row>
    <TotalStatistics :data="totalStatistics" :period="usageOverviewRange" />
    <div class="mt-5">
      <EngagementOverview :data="engagementOverview" :period="usageOverviewRange" />
    </div>
    <div class="mt-5">
      <TotalActiveUsers :data="totalActiveUsers" />
    </div>
    <div class="mt-5">
      <UsageFrequency :data="usageFrequency" :period="usageOverviewRange" />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters, mapMutations, mapActions } from "vuex";

import TotalStatistics from "@/components/ProductAnalytics/UsageOverview/TotalStatistics.vue";
import EngagementOverview from "@/components/ProductAnalytics/UsageOverview/EngagementOverview.vue";
import TotalActiveUsers from "@/components/ProductAnalytics/UsageOverview/TotalActiveUsers.vue";
import UsageFrequency from "@/components/ProductAnalytics/UsageOverview/UsageFrequency.vue";

export default {
  name: "UsageOverview",

  components: {
    TotalStatistics,
    EngagementOverview,
    TotalActiveUsers,
    UsageFrequency,
  },

  data() {
    return {
      store: "usageOverview",
      currentOverviewDate: moment().format("YYYY-MM-DD"),
      usageOverviewRange: 'month',
      defaultUsageOverviewRange: 'month'
    }
  },

  mounted() {
    this.initEntity();
  },

  computed: {
    ...mapGetters("usageOverview", 
      ["weeklyOverviewReport", "monthlyOverviewReport", "dateLastWeeklyAnalytics", "dateLastMonthlyAnalytics"]
    ),

    entityList() {
      return this.isWeekRange ? this.weeklyOverviewReport : this.monthlyOverviewReport;
    },

    isWeekRange() {
      return this.usageOverviewRange === 'week';
    },

    isMonthRange() {
      return this.usageOverviewRange === 'month';
    },

    totalStatistics() {
      if (this.entityList && this.entityList.total_statistics) {
        return this.entityList.total_statistics;
      }
      return null;
    },
    engagementOverview() {
      if (this.entityList && this.entityList.engagement_overview) {
        return this.entityList.engagement_overview;
      }
      return null;
    },
    totalActiveUsers() {
      if (this.entityList && this.entityList.total_active_users) {
        return this.entityList.total_active_users;
      }
      return null;
    },
    usageFrequency() {
      if (this.entityList && this.entityList.usage_frequency) {
        return this.entityList.usage_frequency;
      }
      return null;
    },
  },

  methods: {
    ...mapActions("usageOverview", ["getAllEntities"]),
    ...mapMutations('usageOverview', {
      clearLists: 'CLEAR_LISTS'
    }),

    getReportsByPeriod() {
      const { currentOverviewDate } = this;
      if (
        (this.isWeekRange && (this.dateLastWeeklyAnalytics !== currentOverviewDate)) || 
        (this.isMonthRange && (this.dateLastMonthlyAnalytics !== currentOverviewDate))
       ) {
         this.getAllEntities({ date: currentOverviewDate, period: this.usageOverviewRange });
       }
    },

    initEntity() {
      setTimeout(() => !this.entityList && this.getAllEntities(
        { date: this.currentOverviewDate, period: this.defaultUsageOverviewRange }
      ), 0);
    },

    refreshList() {
      this.clearLists();
      this.usageOverviewRange = 'month';
      this.getAllEntities({ date: moment().format("YYYY-MM-DD"), period: this.defaultUsageOverviewRange });
    }
  },
};
</script>
