<template>
  <div class="graf-panel d-flex mb-4 align-center">
    <h2>Total Active users</h2>
    <v-progress-circular v-if="!data" :width="3" size="100" color="primary" class="mt-6" indeterminate></v-progress-circular>
    <zingchart height="400px" v-else :data="chartData"></zingchart>
  </div>
</template>
    

<script>
import moment from "moment";
import "zingchart/es6";
import zingchartVue from "zingchart-vue";

export default {
  name: "TotalActiveUsers",

  components: {
    zingchart: zingchartVue
  },

  props: {
    data: {
      default: null,
      type: Array
    }
  },

  computed: {
    chartData() {
      return {
        type: "area",
        "background-color": "#fff",
        height: "400px",
        utc: true,
        plotarea: {
          "margin-top": "10%",
          "margin-right": "dynamic",
          "margin-bottom": "dynamic",
          "margin-left": "dynamic",
          "adjust-layout": true
        },
        legend: {
          "vertical-align": "top",
          "align": "center",
          "border-width": 0,
        },
        scaleX: {
          label: {
            text: "Dates",
            "font-size": "14px",
            "font-weight": "normal",
            "offset-x": "10%",
            "font-angle": 360
          },
          item: {
            "text-align": "center",
            "font-color": "#05636c"
          },
          "max-labels": 20,
          labels: this.xAxisLabels,
          "max-items": 20,
          "items-overlap": true,
          guide: {
            "line-width": "0px"
          },
          tick: {
            "line-width": "2px"
          }
        },
        crosshairX: {
          "line-color": "#fff",
          "line-width": 1,
          "plot-label": {
            visible: false
          }
        },
        scaleY: {
          values: `0:${this.maxScaleValue}`,
          item: {
            "font-color": "#05636c",
            "font-weight": "normal"
          },
          label: {
            text: "# of Active Users",
            "font-size": "14px"
          },
          guide: {
            "line-width": "0px",
            alpha: 0.2,
            "line-style": "dashed"
          }
        },
        plot: {
          "line-width": 2,
          "stacked": true,
          marker: {
            size: 3,
            visible: true,
            "background-color": "#c9ddad",
          },
          tooltip: {
            "font-family": "Roboto",
            "font-size": "15px",
            text: "There were %v %t on %data-days",
            "text-align": "left",
            "border-radius": 3,
            padding: 7
          }
        },

        series: [
          {
            values: this.newUserValues,
            "data-days": this.dataLabels,
            "line-color": "#bbd395",
            aspect: "spline",
            "background-color": "#c9ddad",
            "alpha-area": ".5",
            "font-family": "Roboto",
            "font-size": "14px",
            text: "New users"
          },
          {
            values: this.totalUserValues,
            "data-days": this.dataLabels,
            "line-color": "#d0e6b1",
            "background-color": "#eef7e2",
            "alpha-area": ".3",
            text: "Returning users",
            aspect: "spline",
            "font-family": "Roboto",
            "font-size": "14px"
          },
        ]
      };
    },

    maxScaleValue() {
      if (!this.data) {
        return 0;
      }
      const values = this.data.map(item => item.total_users + item.new_users);
      return Math.max(...values);
    },

    xAxisLabels() {
      if (!this.data) {
        return [];
      }
      return this.data.map(item => `${moment(item.date).format("MMM")}<br>${moment(item.date).format("DD")}`);
    },

    dataLabels() {
      if (!this.data) {
        return [];
      }
      return this.data.map(item => `${moment(item.date).format("MMM")} ${moment(item.date).format("DD")}`);
    },

    newUserValues() {
      if (!this.data) {
        return [];
      }
      return this.data.map(item => item.new_users);
    },

    totalUserValues() {
      if (!this.data) {
        return [];
      }
       return this.data.map(item => item.total_users);
    }
  },

};
</script>
