<script>
import { Bar } from "vue-chartjs";
export default {
  extends: Bar,
    props: {
    labels: {
      default: null,
      type: Array
    },
    data: {
      default: null,
      type: Array
    }
  },
  data() {
    return {
      choosenIndex: null,

      options: {
        onClick: (e, elems) => {
          if (this.choosenIndex || (this.choosenIndex) == 0) {
            this.choosenIndex = null
            this.$emit('toggleBar', null);
          } else {
            this.choosenIndex = elems[0]._index;
            console.log(this.labels[this.choosenIndex]);
            this.$emit('toggleBar', this.labels[this.choosenIndex]);
          }
          this.renderChart(this.datacollection, this.options);
        },
        animation: {
          duration: 0
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                stepSize: 1,
              },
              scaleLabel: {
                display: true,
                labelString: '# of Users'
              }
            }
          ],
          xAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: {
                beginAtZero: true,
              },
              scaleLabel: {
                display: true,
                labelString: 'Active Days'
              }
            }
          ]
        },
        legend: {
          display: false
        },
        responsive: true,
        maintainAspectRatio: false,
      }
    };
  },

  computed: {
    colors() {
      const arr = [];
      if (!this.choosenIndex && this.choosenIndex != 0) {
        this.labels.forEach(() => arr.push('#5a89e4'));
        return arr;
      } 
      this.labels.forEach((item, index) => index === this.choosenIndex ? arr.push('#134298') : arr.push('#5a89e4'));
      return arr;
    },
    datacollection() {
      return {
        labels: this.labels,
        datasets: [
          {
            label: "Users",
            backgroundColor: this.colors,
            pointBackgroundColor: "white",
            borderWidth: 1,
            pointBorderColor: "#249EBF",
            data: this.data
          }
        ]
      }
    },
  },

  mounted() {
    this.renderChart(this.datacollection, this.options);
  },

  watch: {
    data() {
      setTimeout(()=> {
        this.renderChart(this.datacollection, this.options);
      }, 0)
    },
  }

};
</script>
