<template>
  <img v-if="isUrlPresigned" :src="this.entity.payload" alt="img">
  <PayloadImage v-else :payload="this.entity.payload" :img-type="this.entity.format" />
</template>
<script>
import PayloadImage from '@/components/_Partials/PayloadImage.vue'
export default {
  name: "AnswerImage",

  props: ["entity"],

  inject: ["is_materialized"],

  components: {
    PayloadImage,
  },

  computed: {
    isUrlPresigned() {
      return this.is_materialized;
    }
  }
}
</script>