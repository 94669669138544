import API from '@/services/api/base';
import baseStore from '@/store/modules/_base';

const state = {
  ...baseStore.state,
  url: 'chatbot/integration',
};

const getters = {
  ...baseStore.getters,
};

const mutations = {
  ...baseStore.mutations,
};

const actions = {
  ...baseStore.actions,

  save: ({ commit, state, dispatch }, entry) => {
    return new Promise((resolve) => {
      API
        .postWithEntry(state.url, entry)
        .then((res) => {
          dispatch('getAsyncQueueStatus', res.data.data).then((result) => {
            resolve()
            if (result.error_message) {
              commit('notifications/SHOW_FAIL', res.error_message, { root: true })
              return false
            }
            commit('notifications/SHOW_SUCCESS', 'Integration has been saved', { root: true })
          })
          .catch(err => {
            console.log(err)
          })
        })
        .catch(err => {
          console.log(err)
          commit('notifications/SHOW_FAIL', null, { root: true })
        })
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}