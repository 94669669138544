<template>
  <v-app id="inspire">
    <v-navigation-drawer
      v-if="signedIn"
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      app
    >
      <v-list dense>
        <template v-for="item in items">
          <v-row v-if="item.heading" :key="item.heading" align="center">
            <v-col cols="6">
              <v-subheader v-if="item.heading">{{ item.heading }}</v-subheader>
            </v-col>
            <v-col cols="6" class="text-center">
              <a href="#!" class="body-2 black--text">EDIT</a>
            </v-col>
          </v-row>
          <v-list-group
            v-else-if="item.children"
            :key="item.text"
            v-model="item.model"
            :prepend-icon="item.model ? item.icon : item['icon-alt']"
            append-icon
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="(child, i) in item.children"
              :key="i"
              :to="child.link"
              link
              :class="{'d-none': child.isAWSOnly && !isAWSEnv }"
            >
              <v-list-item-action v-if="child.icon">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ child.text }}
                  <span v-if="child.isNew" class="tag tag-teal tag-small float-right">new</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item
            v-else
            :key="`${item.text}_${i}`"
            :to="item.link"
            color="primary"
            link
          >
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      v-if="signedIn"
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="primary"
      dark
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>
        <v-btn
          light 
          color="white" 
          target="_blank" 
          :to="{ name: 'jupyter-hub' }"
          class="mr-4"
        >
          jupyter-hub
        </v-btn>
        <v-btn text color="white" :to="{ name: 'environments' }">Aiola</v-btn>
        <span class="pl-4">{{ envName }}</span>
        <span v-show="timezone" class="timezone-item">(tz: {{timezone}})</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn color="white" class="mr-4"
        @click="openConsole()"
        ><v-icon color="black">mdi-console</v-icon></v-btn
      >
      <template v-if="isAWSEnv">
        <amplify-sign-out class="sign-out"></amplify-sign-out>
      </template>
      <template v-else>
        <v-btn color="white" light class="mr-4"
          @click="AzureSignOut()"
          >sign-out</v-btn
        >
      </template>
    </v-app-bar>
    <v-main>
      <v-container fluid>
        <div :class="`dashboard-page ${$route.name}-page`">
          <v-row>
            <slot></slot>
          </v-row>
        </div>
      </v-container>
    </v-main>

    <v-snackbar
      v-model="isNotificationShown"
      :timeout="timeout"
      :color="color"
      top
      right
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="isNotificationShown = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <Console />
  </v-app>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import Console from "@/components/_Partials/Console.vue";
import { getEnv, getTz, isAWS, getCloudConfigs } from '@/api-configs';
import { PublicClientApplication } from '@azure/msal-browser';

export default {
  components: {
    Console,
  },
  data() {
    return {
      envName: '',
      timezone: '',
      isShowedConsole: false,
      drawer: null,
      isAWSEnv: false,
      items: [
        {
          icon: "mdi-chevron-up",
          "icon-alt": "mdi-chevron-down",
          text: "Configurations",
          model: false,
          children: [
            { text: "Base", link: "/configurations/base" },
            { text: "Skills", link: "/configurations/skills" },
          ],
        },
        {
          icon: "mdi-chevron-up",
          "icon-alt": "mdi-chevron-down",
          text: "Skills",
          model: false,
          children: [
            { text: "Skill Bundles", link: "/skill-bundles" },
            { text: "Repositories", link: "/repositories" },
          ],
        },
        {
          icon: "mdi-chevron-up",
          "icon-alt": "mdi-chevron-down",
          text: "Chatbot",
          model: false,
          children: [
            { text: "Base", link: "/chatbot/base" },
            { text: "Integrations", link: "/chatbot/integrations" },
            { text: "Intents", link: "/chatbot/intents" },
            { text: "Slots", link: "/chatbot/slots" },
            { text: "Whitelists", link: "/chatbot/whitelists" },
          ],
        },
        {
          icon: "mdi-chevron-up",
          "icon-alt": "mdi-chevron-down",
          text: "Monitoring",
          model: false,
          children: [
            { text: "Skills", link: "/logs/skills" },
            { text: "Services", link: "/logs/services" },
          ],
        },
        {
          icon: "mdi-chevron-up",
          "icon-alt": "mdi-chevron-down",
          text: "Product Analytics",
          model: false,
          children: [
            { text: "Usage Overview", link: "/usage/overview" },
            { text: "Usage Logs", link: "/usage/logs" },
          ],
        },
        {
          icon: "mdi-chevron-up",
          "icon-alt": "mdi-chevron-down",
          text: " User Management",
          model: false,
          children: [
            { text: "Profiles", link: "/profiles" },
            { text: "Groups", link: "/groups" },
            { text: "Access Control", link: "/access-control" },
          ],
        },
      ],
    };
  },

  async created() {
    this.isAWSEnv = await isAWS();
    this.timezone = await getTz();
    this.applyActiveParentMenuItem();
    this.getEnvName();
  },

  methods: {
    ...mapMutations("environments", {
      toggleAsyncLogsConsole: "TOGGLE_CONSOLE",
    }),
    ...mapMutations('auth', {
      signOut: 'SIGN_OUT'
    }),

    applyActiveParentMenuItem() {
      this.items.forEach((item) => {
        item.model =
          item.children &&
          item.children.some((child) => child.link === this.$route.path)
            ? true
            : false;
      });
    },

    async getEnvName() {
      if (process.env.VUE_APP_IS_LOCAL) {
        this.envName = process.env.VUE_APP_ENVIRONMENT_NAME;
        return false;
      }
      this.envName = await getEnv();
    },

    openConsole() {
      this.$root.$emit("openAsyncLogsConsole");
    },

    async AzureSignOut() {
      const msalInstance = new PublicClientApplication(await getCloudConfigs());
      await msalInstance.logout({})
      .then(() => {
        this.signOut();
        this.$router.push("/");
      })
      .catch(error => {
        console.error(error);
      });
    },
  },
  computed: {
    ...mapGetters('notifications', ['text', 'color', 'timeout']),
    ...mapGetters('auth', ['signedIn']),

    isNotificationShown: {
      get() {
        return this.$store.getters["notifications/isShown"];
      },
      set(value) {
        this.$store.state.notifications.isShown = value;
      },
    },
  },
};
</script>