import service from '@/plugins/axios';

export default {
  /**
 * @param {String} url
 * @param {Object} params (optional)
 * @returns {Array} collection of items
 */
  getInitialEntities(url, params = null) {
    return service.get(`${url}/list`, { params });
  },

  getFile(path) {
    return service.get('url/presign', { params: { path } });
  },

  /**
   * @param {String} url
   * @param {Number|String} id
   * @param {Object} params (optional)
   * @returns {Array} collection of items
   */
  getById(url, id, params = null) {
    return service.get(`${url}?id=${id}`, { params });
  },

  /**
   * @param {String} url
   * @param {Number|String} name
   * @param {Object} params (optional)
   * @returns {Array} collection of items
   */
  getByName(url, name, params = null) {
    return service.get(`${url}?name=${name}`, { params });
  },

  /**
   * @param {String} request_id id of async query;
   * @param {String} token only for azure env
   */
  getAsyncReaderStatus(request_id, token = null) {
    return service.get(`async?request_id=${request_id}&token=${token}`);
  },

  /**
   * @param {String} url
   * @param {Number} id
   */
  deleteById(url, id) {
    return service.delete(`${url}?id=${id}`);
  },

  /**
   * @param {String} url
   * @param {Object} data
   */
  delete(url, params = null) {
    return service.delete(`${url}`, { params });
  },

  /**
  * @param {String} url
  * @param {Object} params (optional)
  */
  uploadFile(file) {
    return service.post(`upload/bundle`, file, {
      headers: {
        'Content-Type': 'application/zip',
      }
    });
  },

  /**
   * @param {String} url
   * @param {Object} entry
   */
  postWithEntry(url, entry) {
    return service.post(url, entry);
  }
}
