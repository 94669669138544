<template>
  <div class="page-container registration-container">
    <h2>Access Control</h2>
    <v-card class="mt-3 px-3 py-3">
      <div v-if="isAllowListInited">
        <h2>Backoffice sign up rules</h2>
        <div v-for="(allowItem, key) in allowList" :key="key"  class="pb-3">
          <RuleRow :rule-name="key" :rule-list="allowItem" />
        </div>
      </div>
      <div v-else class="mt-3 loader-block">
        <v-progress-circular
          :width="3"
          size="60"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
    </v-card>

    <h2 class="mt-4 red--text hovered-el" @click="refreshDeniedList">
      Denied logs
      <v-icon color="primary" class="hidden-icon pb-1">mdi-reload</v-icon>
    </h2>
    <div class="expansion-panel" v-for="(user, key) in deniedList" :key="key">
      <div class="expansion-panel--header">
        <v-row>
          <v-col cols="12" sm="12" md="3" class="report-col">
            <div class="row-header">Time</div>
            <div class="row-content">
              {{ user.timestamp | timeFormat }}
            </div>
          </v-col>
          <v-col cols="12" sm="12" md="3" class="report-col">
            <div class="row-header">Email</div>
            <div class="row-content">
              {{ user.email }}
            </div>
          </v-col>
        </v-row>
      </div>
    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import RuleRow from "@/components/UserManagement/AccessControl/RuleRow.vue";
import moment from "moment";

export default {
  name: "AccessControl",

  components: {
    RuleRow,
  },

  filters: {
    timeFormat: (value) => {
      return moment.unix(value).format("YYYY-MM-DD, H:mm:ss");
    },
  },

  computed: {
    ...mapGetters('accessControl', ['allowList', 'deniedList', 'isAllowListInited']),
  },

  mounted() {
    !this.isAllowListInited && this.getAllowList()
    this.getDeniedList()
  },

  methods: {
    ...mapActions("accessControl", ['getAllowList', 'getDeniedList', 'clearDeniedList']),
    ...mapMutations("accessControl", {
      clearDeniedList: "RESET_DENIED_LIST",
    }),

    refreshDeniedList() {
      this.clearDeniedList();
      this.getDeniedList()
    },
  },
};
</script>
