<template>
  <div class="graf-panel d-flex mb-4 align-center">
    <h2>Daily Engagement</h2>
    <v-progress-circular v-if="!data"  :width="3" size="100" color="primary" class="mt-6" indeterminate></v-progress-circular>
    <zingchart v-else ref="dailyEngagement" height="400px" :data="chartData"  @node_click="handleNodeClick" @click="handleClick"></zingchart>
    <div class="daily-engagement-users-block">
      <span @click="isWrapped =! isWrapped" class="link-style" v-show="data">
        Usage by User 
        <v-icon color="primary" v-if="!isWrapped">mdi-chevron-down</v-icon>
        <v-icon color="primary" v-else>mdi-chevron-up</v-icon>
      </span>
      <transition name="slide-up">
        <div v-if="isWrapped">
          <span>{{ usageByUserDateRange }}</span>
          <v-data-table
            :headers="headers"
            :items="filteredTable"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :items-per-page="5"
            class="elevation-1 mt-2"
          >
            <template v-slot:item.userName="{ item }">
              <span class="hovered-el" @click="goToUsageLogs(item)">
                {{ item.userName }}
                <v-icon color="primary" class="hidden-icon pb-1">mdi-chart-bar</v-icon>
              </span>
            </template>
          </v-data-table>
        </div>
      </transition>
    </div>
  </div>
</template>
    

<script>
import moment from "moment";
import "zingchart/es6";
import zingchartVue from "zingchart-vue";

export default {
  name: "EngagementOverview",

  components: {
    zingchart: zingchartVue
  },

  props: {
    data: {
      default: null,
      type: Array
    },
    period: {
      default: 'month',
      type: String
    }
  },

  data() {
    return {
      isWrapped: false,
      markedPlotIndex: null,
      sortBy: 'totalIntents',
      sortDesc: true,
      headers: [
        { text: 'User', align: 'start', value: 'userName' },
        { text: 'Total Intents', value: 'totalIntents' },
        { text: 'Days Used', value: 'usedDays' },
      ],
    };
  },

  computed: {
    chartData() {
      return {
        type: "mixed",
        "background-color": "#fff",
        height: "400",
        title: {},
        plotarea: {
          "margin-top": "12%",
          "margin-right": "dynamic",
          "margin-bottom": "dynamic",
          "margin-left": "dynamic",
          "adjust-layout": true
        },
        legend: {
          "vertical-align": "top",
          "align": "center",
          "border-width": 0,
          width: 360,
        },
        
        scaleX: {
          "max-labels": 20,
          "max-items": 20,
          "items-overlap": true,
          labels: this.xAxisLabels,
          label: {
            text: "Dates",
            "font-size": "14px",
            "font-weight": "normal",
            "offset-x": "10%",
            "font-angle": 360
          },
          item: {
            "text-align": "center",
            "font-color": "#05636c"
          },
          guide: {
            "line-width": "0px",
          },
          tick: {
            "line-width": "2px"
          }
        },
        scaleY: {
          values: `0:${this.maxScaleValueY}`,
          label: {
            text: "# of intents",
            "font-size": "14px"
          },
          guide: {
            "line-style": "dashed"
          },
          item: {
            "font-color": "#05636c",
            "font-weight": "normal"
          },
        },
        "scale-y-2": {
          values: `0:${this.maxScaleValueY}`,
          label: {
            text: "# of Active Users",
            "font-size": "14px"
          },
          item: {
            "font-color": "#05636c",
            "font-weight": "normal"
          },
        },

        plot: {
          "line-width": 2,
          marker: {
            size: 2,
            visible: true
          },
          tooltip: {
            "font-family": "Roboto",
            "font-size": "15px",
            text: "%v %t on %data-days",
            "text-align": "left",
            "border-radius": 3,
            padding: 7
          }
        },

        series: [
          {
            type: "line",
            text: "Active Users",
            scales: "scale-x,scale-y-2",
            values: this.activeUsers,
            "data-days": this.dataLabels,
            zIndex: 3,
            legendItem: {
              order : 1
            },
            marker: {
              size: 3,
              "background-color": "#5a89e4",
              "border-color": "#5a89e4"
            },
            "line-color": "#5a89e4"
          },
          {
            type: "bar",
            text: "Delivered Intents",
            scales: "scale-x,scale-y",
            values: this.successIntents,
            "data-days": this.dataLabels,
            stacked: true,
            index: 2,
            stack: 1,
            legendItem: {
              order : 3
            },
            "background-color": "rgb(165,192,139)"
          },
          {
            type: "bar",
            text: "Failed Intents",
            scales: "scale-x,scale-y",
            values: this.failedIntents,
            "data-days": this.dataLabels,
            stacked: true,
            index: 1,
            stack: 1,
            "background-color": "#ca5a48",
            legendItem: {
              order : 2
            },
          },
        ]
      };
    },

    xAxisLabels() {
      if (!this.data) {
        return [];
      }
      return this.data.map(item => `${moment(item.date).format("MMM")}<br>${moment(item.date).format("DD")}`);
    },

    dataLabels() {
      if (!this.data) {
        return [];
      }
      return this.data.map(item => `${moment(item.date).format("MMM")} ${moment(item.date).format("DD")}`);
    },

    maxScaleValueY() {
      if (!this.data) {
        return 0;
      }
      const values = this.data.map(item => item.success_intents + item.failed_intents);
      return Math.max(...values);
    },

    failedIntents() {
      if (!this.data) {
        return [];
      }
      return this.data.map(item => item.failed_intents);
    },

    successIntents() {
      if (!this.data) {
        return [];
      }
      return this.data.map(item => item.success_intents);
    },

    activeUsers() {
      if (!this.data) {
        return [];
      }
      return this.data.map(item => item.active_users);
    },

    usageByUserDateRange() {
      const { data, markedPlotIndex } = this;
      if (!data) return false;
      if (markedPlotIndex) {
        const date = data[markedPlotIndex].date;
        return moment(date).format("MMM DD, YY")
      }
      const firstRangeDate = data[0].date;
      const lastRangeDate = data[data.length - 1].date;
      return `${moment(firstRangeDate).format("MMM DD, YY")} - ${moment(lastRangeDate).format("MMM DD, YY")}`;
    },

    groupedUsers() {
      if (!this.data) return false;
      return this.data.reduce((result, currentValue) => {
        if (currentValue.user_statistics.length) {
          currentValue.user_statistics.forEach(item => {
            if (result.length && result.some(resItem => resItem.userId === item.user_id)) {
              const index = result.findIndex(resItem => resItem.userId === item.user_id)
              result[index].totalIntents += item.total_intents;
              result[index].usedDays += 1;
              return false;
            }
            result.push({
              userId: item.user_id,
              userName: item.user_name,
              totalIntents: item.total_intents,
              usedDays: 1
            });
          });
        }
        return result;
      }, []);
    },

    filteredTable() {
      if (!this.data) return [];
      if (this.markedPlotIndex !== null) {
        const date = this.data[this.markedPlotIndex]
        if (date.active_users) {
          return date.user_statistics.map(item => {
            return {
              userId: item.user_id,
              userName: item.user_name,
              totalIntents: item.total_intents,
              usedDays: 1
            }
          })
        }
        return [];
      }
      return this.groupedUsers;
    }
  },

  methods: {
    handleNodeClick(e) { 
      this.$refs.dailyEngagement.modify({
        data: {
          scaleX: {
            markers:[{ 
              type: "line",
              range: [e.nodeindex],
              placement: 'top',
              lineWidth: 2,
              lineColor: "#5a89e4"
            }],
          }
        }
      });
      this.markedPlotIndex = e.nodeindex;
    },

    handleClick(e) {
      if (e.target != 'node') {
        this.$refs.dailyEngagement.modify({
          data: {
            scaleX: { markers:[] }
          }
        });
        this.markedPlotIndex = null;
        return false;
      }
    },

    goToUsageLogs(item) {
      const routeData = this.$router.resolve({name: "usage-logs", query: { period: this.period, userId: item.userId }});
      window.open(routeData.href, '_blank');
    }
  }
};
</script>
