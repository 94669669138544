<template>
  <div class="page-container repository-container">
    <v-row>
      <v-col cols="12" sm="5" class="d-flex align-center">
        <h2 class="hovered-el" @click="refreshList">
          List of Repositories
          <v-icon color="primary" class="hidden-icon pb-1">mdi-reload</v-icon>
        </h2>
      </v-col>
      <v-col cols="12" sm="7">
        <v-text-field
          hide-details
          v-model="searchParam"
          placeholder="Search"
          prepend-inner-icon="mdi-magnify"
          solo
        ></v-text-field>
      </v-col>
    </v-row>
    <div class="mt-3">
      <v-btn
        color="green darken-1"
        class="text-capitalize white--text"
        @click="openAddingRepositoryDialog()"
        depressed
      >
        <v-icon left>mdi-content-copy</v-icon>Clone new repository
      </v-btn>
    </div>
    <v-card class="mt-5 px-4 py-4">
      <div class="d-flex justify-space-between align-center">
        <input ref="ssh" type="hidden" :value="publicKey">
        <div>SSH public key</div>
        <div>
          <v-btn color="primary" class="text-capitalize mr-4" outlined small @click="togglePanel()">
            {{ openedPanel ? 'close' : 'show' }}
          </v-btn>
          <v-btn class="text-capitalize" color="primary" small @click="copyText()">Copy to buffer</v-btn>
        </div>
      </div>
      <transition name="slide">
        <div class="public-key-block" v-show="openedPanel">
          <br /><span>{{ publicKey }}</span>
        </div>
      </transition>
    </v-card>
    <div class="expansion-panels-group mt-5" v-if="filteredListPerPage">
      <Repository
        v-for="repository in filteredListPerPage"
        :key="repository.repository_name"
        :repository="repository"
      />

      <EmptyBlockStub :list="filteredList" :search="searchParam" @reset="resetSearch()" />
      <v-pagination
        v-show="isShowPagination"
        v-model="page"
        total-visible="7"
        :length="paginationLength"
      ></v-pagination>
    </div>
    <div v-else class="mt-3 loader-block">
      <v-progress-circular
        :width="3"
        size="60"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <AddRepositoryDialog />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import Repository from "@/components/Skills/Repository.vue";
import AddRepositoryDialog from "@/components/Dialogs/AddRepositoryDialog.vue";
import EmptyBlockStub from "@/components/_Partials/EmptyBlockStub.vue";

import baseEntity from "@/mixins/_baseEntity";

export default {
  name: "Repositories",
  mixins: [baseEntity],
  components: {
    Repository,
    AddRepositoryDialog,
    EmptyBlockStub,
  },

  data() {
    return {
      store: "repositories",
      openedPanel: false,
    };
  },

  computed: {
    ...mapGetters('repositories', ['publicKey']),

    filteredList() {
      const search = this.searchParam.toLowerCase().trim();
      if (!search) return this.entityList;
      return this.entityList.filter((entity) =>
        entity.repository_name.toLowerCase().includes(search)
      );
    },
  },

  methods: {
    ...mapMutations('notifications', {
      notify: 'SHOW_SUCCESS',
    }),

    openAddingRepositoryDialog() {
      this.$root.$emit("AddRepositoryDialog");
    },
    copyText() {
      const sshKey = this.$refs.ssh;
      sshKey.type = 'text';
      sshKey.select();
      document.execCommand("copy");
      sshKey.type = 'hidden';
      this.notify('Copied');
    },
    togglePanel() {
      this.openedPanel = !this.openedPanel;
    },
  },
};
</script>
