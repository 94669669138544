import { mapGetters, mapMutations } from "vuex";

export default {
  data() {
    return {
      command: '',
      commandHistory: [],
      commadCursor: 0,
      logsListLength: 100,
    }
  },

  mounted() {
    this.$root.$on("openAsyncLogsConsole", () => {
      this.openConsole();
    });
    this.setLogsFromLocalStorage();
    this.setConfigsFromLocalStorage();
  },

  beforeDestroy() {
    this.$root.$off("openAsyncLogsConsole");
  },

  methods: {
    ...mapMutations('logs', {
      openConsole: 'OPEN',
      closeConsole: 'CLOSE',
      setLogsData: 'SET_LOGS_DATA',
      setLogsFromLocalStorage: 'SET_LOGS_DATA_FROM_LOCAL_STORAGE',
      clearConsole: 'CLEAR_CONSOLE',
    }),

    handleInput(keyboardEvent) {
      switch(keyboardEvent.code) {
        case 'Enter': this.executeCommand(keyboardEvent);
          break;
        case 'ArrowUp': this.showPreviousComand();
          break;
        case 'ArrowDown': this.showNextComand();
          break;
      }
    },

    executeCommand(e) {
      let { command } = this;

      if (!command) {
        e.preventDefault()
        return false;
      }
      const cmdExpresion = command.trim().split(' ');
      const cmdName = cmdExpresion.slice(0, 1)[0];
      const cmdParams = cmdExpresion.slice(1);
      this.updateCommandHistory();
      if (typeof this[`cmd_${cmdName}`] === 'function') {
        this[`cmd_${cmdName}`](cmdParams);
      } else this.showNotFound();

      this.clearCommandLine();
    },

    showPreviousComand() {
      if (this.commadCursor < this.commandHistory.length) {
        this.command = this.commandHistory[this.commadCursor];
        this.commadCursor++;
      }
    },

    showNextComand() {
      if (this.commadCursor) {
        this.commadCursor--;
        this.command = this.commandHistory[this.commadCursor];
      }
    },

    updateCommandHistory() {
      this.commandHistory.unshift(this.command);
      this.commadCursor = 0;
    },

    cmd_clear() {
      this.clearConsole();
    },

    cmd_length(params) {
      const len = parseInt(params[0]);
      if (!len || len < 10 || len > 2000) {
        this.setLogsData([{ 
          message: `<b>Length must be between 10 and 2000</b>` 
        }]);
        return false;
      }

      localStorage.setItem('consoleLogsLength', len);
      this.logsListLength = len;
      this.setLogsData([{ 
        message: `<b>New logs length has been saved!</b>` 
      }]);
    },

    setConfigsFromLocalStorage() {
      const logsLength = localStorage.getItem('consoleLogsLength');
      if (!logsLength) {
        localStorage.setItem('consoleLogsLength', this.logsListLength);
        return false;
      }
      this.logsListLength = logsLength;
    },

    scrollToBottom() {
      this.$nextTick(() => {
        const container = this.$refs.container;
        container.scrollTop = container.scrollHeight;
       });
    },

    clearCommandLine() {
      this.$nextTick(() => { this.command = '' });
    },

    showNotFound() {
      this.setLogsData([{ 
        message: `<i>${this.command}</i> - <b>command not found</b>` 
      }]);
    },

  },

  computed: {
    ...mapGetters('logs', ['asyncLogsData', 'isOpen']),

    currentStatus() {
      return this.asyncLogsData.status;
    },

    consoleMessages() {
      const { logsListLength, asyncLogsData } = this;
      return asyncLogsData.messages.slice(
        Math.max(asyncLogsData.messages.length - logsListLength, 0)
      );
    }
  },

  watch: {
    asyncLogsData: {
      deep: true,
      handler() {
        this.scrollToBottom();
        localStorage.setItem('consoleMessages', JSON.stringify(this.consoleMessages));
      },
    },
    isOpen() {
      if (this.isOpen) {
        this.scrollToBottom();
      }
    }
  },
}