import API from '@/services/api/base';
import baseStore from '@/store/modules/_base'

const state = {
  ...baseStore.state,
  url: 'register',
  isAllowListInited: false, 
  allowList: {
    domain: [],
    regex: [],
    email: [],
  },
  deniedList: null,
};

const getters = {
  ...baseStore.getters,
  allowList: state => state.allowList,
  deniedList: state => state.deniedList,
  isAllowListInited: state => state.isAllowListInited,
};

const mutations = {
  ...baseStore.mutations,

  SET_ALLOW: (state, list) => {
    for (const [key] of Object.entries(state.allowList)) {
      state.allowList[key] = list[key] ?  list[key] : [] ;
    }
    state.isAllowListInited = true;
  },

  SET_DENIED: (state, list) => {
    state.deniedList = list;
  },

  RESET_ALLOW_LIST: (state) => {
    state.allowList.domain = [];
    state.allowList.regex = [];
    state.allowList.email = [];
  },

  RESET_DENIED_LIST: (state) => {
    state.deniedList = null;
  }

};

const actions = {
  ...baseStore.actions,
  getAllowList: ({ commit, state }) => {
    return new Promise((resolve) => {
      API
        .getInitialEntities(`${state.url}/allow`)
        .then(result => {
          if (result.data.error_message) {
            commit('notifications/SHOW_FAIL', result.data.error_message, { root: true })
            return false;
          }
          commit('SET_ALLOW', result.data.data);
          resolve();
        })
        .catch(err => {
          console.log(err)
          commit('notifications/SHOW_FAIL', null, { root: true })
        })
    })
  },

  getDeniedList: ({ commit, state }) => {
    return new Promise((resolve) => {
      API
        .getInitialEntities(`${state.url}/denied`)
        .then(result => {
          if (result.data.error_message) {
            commit('notifications/SHOW_FAIL', result.data.error_message, { root: true })
            return false;
          }
          commit('SET_DENIED', result.data.data);
          resolve();
        })
        .catch(err => {
          console.log(err)
          commit('notifications/SHOW_FAIL', null, { root: true })
        })
    })
  },

  setRule: ({ commit, state }, data) => {
    return new Promise((resolve) => {
      API
        .postWithEntry(`${state.url}/allow`, JSON.stringify(data))
        .then(result => {
          if (result.data.error_message) {
            commit('notifications/SHOW_FAIL', result.data.error_message, { root: true })
            return false;
          }
          commit('notifications/SHOW_SUCCESS', 'Saved', { root: true })
          resolve();
        })
        .catch(err => {
          console.log(err)
          commit('notifications/SHOW_FAIL', null, { root: true })
        })
    })
  },

  removeRule: ({ commit, state }, data) => {
    return new Promise((resolve) => {
      API
        .delete(`${state.url}/allow`, data)
        .then(result => {
          if (result.data.error_message) {
            commit('notifications/SHOW_FAIL', result.data.error_message, { root: true })
            return false;
          }
          commit('notifications/SHOW_SUCCESS', 'Deleted', { root: true })
          resolve();
        })
        .catch(err => {
          console.log(err)
          commit('notifications/SHOW_FAIL', null, { root: true })
        })
    })
  },

};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}