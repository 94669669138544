import Vue from 'vue';
import {
  consolePaintingRools
} from '@/console-painting-rules';

/**
 * @param {Array<Object>} arr obj
 * @param {String} key
 * @param {String} chainName - new field
 * @returns {Array<Object>} chained by key
 */
export const chainBy = (arr, key, chainName) => {
  return arr.reduce((acc, item) => {
    let indexR = 0;
    const isChainPart = acc.length && acc.some((el, index) => {
      (el[key] === item[key] && item[key] !== null) && (indexR = index);
      return (el[key] === item[key] && item[key] !== null)
    });

    if (isChainPart) {
      (acc[indexR][chainName]) ? acc[indexR][chainName].unshift(item): (acc[indexR][chainName] = [item]);
    } else acc.push(item);

    return acc;
  }, []);
}

export const shuffle = (a) => {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

export const isObjEmpty = (obj) => {
  return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
}

export const decorateLogMessage = (msg) => {
  let result = '';
  if (Array.isArray(msg)) {
    result = [];
    msg.map(msgItem => {
      for (const [colorClass, keyWords] of Object.entries(consolePaintingRools)) {
        keyWords.map(keyWord => {
          if (msgItem && (typeof msgItem === "string") && msgItem.includes(keyWord)) {
            msgItem = msgItem.replace(keyWord, `<span class="${colorClass}--text">${keyWord}</span>`);
          }
        })
      }
      result.push(msgItem);
    })
  } else {
    result = msg;
    for (const [colorClass, keyWords] of Object.entries(consolePaintingRools)) {
      keyWords.map(keyWord => {
        if (msg && (typeof msg === "string") && msg.includes(keyWord)) {
          result = result.replace(keyWord, `<span class="${colorClass}--text">${keyWord}</span>`);
        }
      })
    }
  }
  return result;
}

export const has = Object.prototype.hasOwnProperty;

/* Custom empty observer for removing Vue reactivity */
export const BlindObserver = new Vue()
  .$data
  .__ob__
  .constructor;

export const validateRegex = (pattern, str) => {
  const regex = new RegExp(pattern);

  return regex.test(str);
}