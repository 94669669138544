import Vue from 'vue'
import Router from 'vue-router'
import { isAWS, isAzure } from '@/api-configs';

import Environments from "@/views/Environments.vue";
import Auth from '@/components/Auth.vue';

/* Configurations */
import BaseConfiguretions from './views/Configurations/Base.vue'
import SkillsConfiguretions from './views/Configurations/Skills.vue'

/* Skills */
import SkillBundles from './views/Skills/SkillBundles.vue'
import Repositories from './views/Skills/Repositories.vue'

/* Chatbot */
import ChatbotBase from './views/Chatbot/Base.vue'
import ChatbotIntegrations from './views/Chatbot/Integrations.vue'
import ChatbotIntents from './views/Chatbot/Intents.vue'
import ChatbotSlots from './views/Chatbot/Slots.vue'
import ChatbotWhitelists from './views/Chatbot/Whitelists.vue'

/* Monitoring */
import MonitoringService from './views/Monitoring/Services.vue'
import MonitoringSkills from './views/Monitoring/Skills.vue'

/* Product Analytics */
import UsageOverview from './views/ProductAnalytics/UsageOverview.vue'
import UsageLogs from './views/ProductAnalytics/UsageLogs.vue'

/*  User Management */
import AccessControl from './views/UserManagement/AccessControl.vue'
import Groups from './views/UserManagement/Groups.vue'
import Profiles from './views/UserManagement/Profiles.vue'

import authStore from "@/store/modules/_auth.js";


Vue.use(Router)

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/aiola',
      name: 'environments',
      component: Environments,
      meta: { requiresAuth: true}
    },
    {
      path: '/jupyter/hub',
      name: 'jupyter-hub',
      meta: { requiresAuth: true}
    },

    {
      path: '/',
      name: 'login',
      component: Auth,
    },

    /* Configuretions */
    {
      path: '/configurations/base',
      name: 'base-configurations',
      component: BaseConfiguretions,
      meta: { requiresAuth: true}
    },
    {
      path: '/configurations/skills',
      name: 'skills-configurations',
      component: SkillsConfiguretions,
      meta: { requiresAuth: true}
    },

    /* Skills */
    {
      path: '/repositories',
      name: 'repositories',
      component: Repositories,
      meta: { requiresAuth: true}
    },
    {
      path: '/skill-bundles',
      name: 'skillBundles',
      component: SkillBundles,
      meta: { requiresAuth: true}
    },

    /* Chatbot */
    {
      path: '/chatbot/base',
      name: 'chatbot-base',
      component: ChatbotBase,
      meta: { requiresAuth: true}
    },
    {
      path: '/chatbot/integrations',
      name: 'chatbot-integrations',
      component: ChatbotIntegrations,
      meta: { requiresAuth: true}
    },
    {
      path: '/chatbot/intents',
      name: 'chatbot-intents',
      component: ChatbotIntents,
      meta: { requiresAuth: true}
    },
    {
      path: '/chatbot/slots',
      name: 'chatbot-slots',
      component: ChatbotSlots,
      meta: { requiresAuth: true}
    },
    {
      path: '/chatbot/whitelists',
      name: 'chatbot-whitelists',
      component: ChatbotWhitelists,
      meta: { requiresAuth: true}
    },

    /* Monitoring */
    {
      path: '/logs/services',
      name: 'monitoring-service',
      component: MonitoringService,
      meta: { requiresAuth: true}
    },
    {
      path: '/logs/skills',
      name: 'monitoring-skills',
      component: MonitoringSkills,
      meta: { requiresAuth: true}
    },

    /* Product Analytics */
    {
      path: '/usage/overview',
      name: 'usage-overview',
      component: UsageOverview,
      meta: { requiresAuth: true}
    },
    {
      path: '/usage/logs',
      name: 'usage-logs',
      component: UsageLogs,
      meta: { requiresAuth: true}
    },

    /* User Management */
    {
      path: '/access-control',
      name: 'access-control',
      component: AccessControl,
      meta: { requiresAuth: true}
    },
    {
      path: '/groups',
      name: 'groups',
      component: Groups,
      meta: { requiresAuth: true}
    },
    {
      path: '/profiles',
      name: 'profiles',
      component: Profiles,
      meta: { requiresAuth: true}
    },
  ]
});

router.beforeResolve(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth) && await isAWS()) {
    Vue.prototype.$Amplify.Auth.currentAuthenticatedUser().then(() => {
      next()
    }).catch(() => {
      next({
        path: '/',
      });
    });
  }
  if (to.matched.some(record => record.meta.requiresAuth) && await isAzure()) {
    setTimeout(()=> {
      if (!authStore.state.signedIn) {
        next({ path: '/' });
      }
    }, 0)
  }
  next();
})

export default router;