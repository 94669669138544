import Vue from 'vue'
import Vuex from 'vuex'

import auth from './modules/_auth';
import logs from './modules/Subsidiary/_logs';
import notifications from './modules/Subsidiary/_notifications';

/* Aiola */
import environments from './modules/_environments';

/* Configurations */
import configurations from './modules/_configurations';

/* Skills */
import repositories from './modules/Skills/_repositories';
import skillBundles from './modules/Skills/_skillBundles';

/* Chatbot */
import chatbot from './modules/Chatbot/_chatbot';
import integrations from './modules/Chatbot/_integrations';
import intents from './modules/Chatbot/_intents';
import slots from './modules/Chatbot/_slots';
import whitelist from './modules/Chatbot/_whitelist';

/* Monitoring */
import monitoring from './modules/_monitoring';

/* ProductAnalytics */
import usageOverview from './modules/ProductAnalytics/_usageOverview';
import usageLogs from './modules/ProductAnalytics/_usageLogs';

/* UserManagement */
import accessControl from './modules/UserManagement/_accessControl';
import profiles from './modules/UserManagement/_profiles';
import groups from './modules/UserManagement/_groups';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    logs,
    notifications,
    
    environments,
    configurations,
    /* Skills */
    repositories,
    skillBundles,
    /* Chatbot */
    chatbot,
    integrations,
    intents,
    slots,
    whitelist,
    /* Monitoring */
    monitoring,
    /* ProductAnalytics */
    usageOverview,
    usageLogs,
    /* UserManagement */
    accessControl,
    profiles,
    groups
  },
});