import service from '@/plugins/axios';

import baseAPI from './base'

export default {
  ...baseAPI,
  
  /**
     * @param {String} url
     * @param {Number} id
     * @param {Object} entry
     */
  evaluateById(url, id, entry) {
    return service.post(`${url}/evaluate?id=${id}`, entry);
  },

  /**
 * @param {String} url
 * @param {Number} id
 */
  stop(url, id, params = null) {
    return service.post(`${url}/stop?id=${id}`, { params });
  },

  /**
   * @param {String} url
   * @param {Number} id
   */
  start(url, id, params = null) {
    return service.post(`${url}/start?id=${id}`, { params });
  },

  /**
   * @param {String} url
   * @param {Number} id
   */
  unregister(url, id, params = null) {
    return service.post(`${url}/unregister?id=${id}`, { params });
  },

  /**
 * @param {String} url
 * @param {Number} id
 */
  register(url, id, entry) {
    return service.post(`${url}/register?id=${id}`, entry);
  },

      /**
   * @param {String} url
   * @param {String} data report data
   * @returns {Array}
   */
  getImage(url, data) {
    return service.get(`${url}/${data}`);
  },

}