<template>
  <vue-draggable-resizable
    v-show="isOpen"
    :w="consoleWidth"
    :h="consoleHeight"
    :x="xPosition"
    :y="yPosition"
    :z="999"
    @click="toggleDraggable"
    :draggable="isDraggable"
    :class="{ fixed: !isDraggable }"
  >
    <div class="console-header">
      <div class="console-info">
        Status: <b>{{ currentStatus }}</b> ({{ asyncLogsData.process }})
      </div>
      <div class="console-mange">
        <v-icon x-small color="green" class="mr-1" @click="toggleExpand()"
          >mdi-checkbox-blank-circle</v-icon
        >
        <v-icon x-small color="error" @click="closeConsole()"
          >mdi-checkbox-blank-circle</v-icon
        >
      </div>
    </div>
    <div class="console-container" ref="container" @dblclick="toggleDraggable">
      <template v-if="consoleMessages.length">
        <p v-for="(msg, index) in consoleMessages" :key="index" v-html="msg" class="console-msg mb-2"></p>
      </template>
      <template v-else>Logs list is empty</template>
    </div>
    <transition name="slide-up">
      <div class="console-manage-panel" v-if="!isDraggable">
        <label for="console-input" class="console-manage-panel--label">Your command:</label>
        <input 
          type="text" 
          ref="consoleInput"
          id="console-input" 
          autocomplete="off"
          class="console-manage-panel--input" 
          @keydown="handleInput"
          v-model="command"
        >
      </div>
    </transition>
    
  </vue-draggable-resizable>
</template>

<script>
import VueDraggableResizable from "vue-draggable-resizable";
import consoleLogic from "@/mixins/_console";

export default {
  name: "BackOfficeConsole",
  mixins: [consoleLogic],
  components: {
    VueDraggableResizable,
  },

  data() {
    return {
      isDraggable: true,
      consoleWidth: 500,
      consoleHeight: 250,
      xPosition: 5,
      yPosition: 63,
      isExpanded: false,
    };
  },

  methods: {

    toggleDraggable() {
      this.isDraggable = !this.isDraggable;
    },

    toggleExpand() {
      if (this.isExpanded) {
        this.consoleWidth = 500;
        this.consoleHeight = 250;
        this.yPosition = 65;
        this.xPosition = 5;
        this.isExpanded = false;
        return false;
      }
        this.consoleWidth = (window.innerWidth / 2);
        this.consoleHeight = window.innerHeight - 65;
        this.yPosition = 64;
        this.xPosition = (window.innerWidth / 2) - 15;
        this.isExpanded = true;
    },
  },
};
</script>