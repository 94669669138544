<template>
  <div class="graf-panel d-flex mb-4">
    <h2>Usage Frequency</h2>
    <div class="d-flex justify-space-between" v-if="data">
      <BarChart :data="users" :labels="xAxisLabels" :width="600" :height="400" @toggleBar="setTableFilter" />
      <v-data-table
        :headers="headers"
        :items="tableData"
        :items-per-page="7"
        class="elevation-1 usage-frequency-table mt-2"
      >
        <template v-slot:item.name="{ item }">
          <span class="hovered-el" @click="goToUsageLogs(item)">
            {{ item.name }}
            <v-icon color="primary" class="hidden-icon pb-1">mdi-chart-bar</v-icon>
          </span>
        </template>
      </v-data-table>
    </div>
  </div>
</template>
    

<script>
import BarChart from "./UsageFrequencyChart.vue";

export default {
  name: "UsageFrequency",

  components: {
    BarChart,
  },

  props: {
    data: {
      default: null,
      type: Array
    },
    period: {
      default: 'month',
      type: String
    }
  },

  data() {
    return {
      activeDayFilter: null,
      headers: [
        {
          text: 'User Names',
          align: 'start',
          value: 'name',
        },
        { text: 'Active Days', value: 'frequency' },
      ],
      isLoading: true,
    };
  },

  computed: {

    periodLength() {
      return this.period === 'month' ? 30 : 7;
    },

    groupedUsers() {
      if (!this.data) {
        return 0;
      }
      const initialList = [];
      for (let i = 0; i <= this.periodLength-1; i++) {
        initialList.push({ activeDays: (i + 1), users: []});
      }

      const groupUsers = this.data.reduce((result, currentValue) => {
        (result[currentValue['frequency']] = result[currentValue['frequency']] || []).push(
          currentValue
        );
        return result;
      }, {});

      for (const [key, value] of Object.entries(groupUsers)) {
        initialList[Number(key) - 1].users = value;
      }

      return initialList;
    },


    xAxisLabels() {
      if (!this.groupedUsers) {
        return [];
      }
      return this.groupedUsers.map(item => item.activeDays);
    },


    users() {
      if (!this.groupedUsers) {
        return [];
      }
      return this.groupedUsers.map(item => item.users.length);
    },

    tableData() {
      if (!this.activeDayFilter) return this.data;
      return this.data.filter(item => item.frequency == this.activeDayFilter)
    },
  },

  methods: {
    setTableFilter(activeDays) {
      this.activeDayFilter = activeDays;
    },
    goToUsageLogs(item) {
      const routeData = this.$router.resolve({name: "usage-logs", query: { period: this.period, userId: item.user_id }});
      window.open(routeData.href, '_blank');
    }
  }
};
</script>
