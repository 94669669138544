<template>
  <v-dialog v-model="isOpen" persistent max-width="460px">
    <v-card>
      <v-card-title>
        <h4 class="font-weight-regular">
            Delete group <b>{{ group.name }}</b>?
        </h4>
      </v-card-title>
      <v-card-text>
        <span v-if="noReference">This group has't been assigned to any skill or bundle yet.</span>
        <span v-else>After deleting the group, it will detach from all skills and bundles!</span>
      </v-card-text>
      <v-card-actions>
        <v-expansion-panels v-if="!initialLoading">
          <v-expansion-panel v-if="referencedBundles && referencedBundles.length">
            <v-expansion-panel-header>Referenced Bundles ({{referencedBundles.length}})</v-expansion-panel-header>
            <v-expansion-panel-content>
              <span v-for="(bundle, index) in referencedBundles" :key="index">
                {{ referencedBundles.length === (index+1) ? bundle : `${bundle}, `}}
              </span>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <div class="group-progress-block" v-else>
          <v-progress-circular :width="3" size="35" color="primary" indeterminate></v-progress-circular>
        </div>
      </v-card-actions>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text 
          color="red" 
          :loading="isLoading" 
          :disabled="initialLoading"
          @click="deleteGroup()"
        >Delete</v-btn>
        <v-btn text 
          color="grey darken-4" 
          @click="$emit('close')">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations, mapActions } from "vuex";
export default {
  name: 'DeleteGroupDialog',

  props: ['isOpen', 'group'],

  data() {
    return {
      isLoading: false,
      initialLoading: false,
    }
  },

  computed: {
    referencedBundles() {
      return this.group.referenced_bundles;
    },

    noReference() {
      const { referencedBundles } = this;
      return (referencedBundles && !referencedBundles.length);
    }
  },

  methods: {
    ...mapActions("groups", [
      "getGroupByName",
      "getAllEntities",
      "removeGroup"
    ]),
    ...mapMutations("groups", { clearList: "CLEAR_LIST" }),

    deleteGroup() {
      this.isLoading = true;
      this.removeGroup(this.group.name).then(() => {
        this.isLoading = false;
        this.refreshList();
        this.$emit('close');
      })
    },

    refreshList() {
      this.clearList();
      this.getAllEntities();
    },
  },

  watch: {
    isOpen(val) {
      if (val) {
        this.initialLoading = true;
        this.getGroupByName(this.group.name).then(() => { this.initialLoading = false; })
      }
    }
  }
}
</script>
